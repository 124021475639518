import React, { useState } from "react";
import { useHistory } from "react-router";
import SupplyTable from "./SupplyTable";
import AddEditModal from "./AddEditModal";

const Supply = () => {
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [id, setId] = useState("");

  const history = useHistory();

  const toggleModal = () => {
    setOpenModal(!openModal);
    setId(null);
    setEditData(null);
  };

  const handleEdit = (id) => {
    if (!AddEditModal && id) {
      // history.push(`/hr/edit/employeeProfile/${id}`);
      history.push(`/kyh/edit/supply/test`);
    } else {
      setId(id);
    }
  };

  const handleCreateUpdateSupply = (id) => {
    history.push(`/kyh/edit/supply/test`);
  };
  return (
    // localStorage.getItem("modules")?.includes("Employee Profile") && (
    <div>
      <SupplyTable
        setId={setId}
        id={id}
        openModal={() => setOpenModal(!openModal)}
        // data={tableData}
        // count={count}
        handleEdit={handleEdit}
        handleCreateUpdateSupply={handleCreateUpdateSupply}
        // refetch={refetch}
      />{" "}
      <AddEditModal
        open={openModal}
        editData={editData}
        handleToggle={toggleModal}
        // refetch={refetch}
        setId={setId}
      />
    </div>
    // )
  );
};

export default Supply;
