import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
// import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import Select from "react-select";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import AttachmentComponent from "../AttachmentComponent";
// import { tableDataHandler } from "@store/Cms/cms.action";
// import { dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";
import { indianStateList } from "../../../../utility/Utils";

import {
  LeaseDeedTypeList,
  LicensesObtainanceOrRenewalResponsibilityTypeList,
  LicensesOfficialFeesResponsibilityTypeList,
  ValidTypeList,
} from "../selectOptionsList";

const DealBasics = () => {
  //   const { id } = useParams();
  //   const dispatch = useDispatch();

  const [floorPlans, setFloorPlans] = useState([]);

  const [leaseDeedType, setLeaseDeedType] = useState("");

  const [leaseDeed, setLeaseDeed] = useState([]);
  const [legalJurisdictionCity, setLegalJurisdictionCity] = useState("");
  const [legalJurisdictionState, setLegalJurisdictionState] = useState("");
  const [leaseDeedBearer, setLeaseDeedBearer] = useState("");
  const [lesseeStampDuty, setLesseeStampDuty] = useState("");
  const [stampDutyAndRegistrationCharges, setStampDutyAndRegistrationCharges] =
    useState("");
  const [registeredLicensesEntity, setRegisteredLicensesEntity] = useState("");
  const [licensesResponsibility, setLicensesResponsibility] = useState("");
  const [licensesFeesResponsibility, setLicensesFeesResponsibility] =
    useState("");
  const [municipalityTradeValid, setMunicipalityTradeValid] = useState("");

  //!Images state
  const [floorPlansImageDummyState] = useState(null);
  const [leaseDeedImageDummyState] = useState(null);

  const uploadFloorPlansImageType = [
    { label: "imageURL", value: "floorPlansImage" },
  ];
  const uploadLeaseDeedImageType = [
    { label: "imageURL", value: "leaseDeedImage" },
  ];

  return (
    <form className="mt-2">
      <Row>
        {/* //! Lease deed type */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="leaseDeedType">
                Lease deed type*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="leaseDeedType"
              name="leaseDeedType"
              options={LeaseDeedTypeList}
              value={leaseDeedType}
              onChange={(value) => setLeaseDeedType(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>

        {/*//! Upload lease deed */}
        <Col sm="3">
          <strong>
            {" "}
            <Label className="form-label" for="llpRepresentativeAadharCard">
              Upload lease deed*
            </Label>
          </strong>
          <AttachmentComponent
            imageList={leaseDeed}
            setImageList={setLeaseDeed}
            editData={leaseDeedImageDummyState}
            uploadType={uploadLeaseDeedImageType}
          />
        </Col>

        {/* //! Legal jurisdiction city */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Legal jurisdiction city*</Label>
            </strong>
            <Input
              type="text"
              value={legalJurisdictionCity}
              id="legalJurisdictionCity"
              name="legalJurisdictionCity"
              //placeholder="Add legal jurisdiction city"
              onChange={(e) => setLegalJurisdictionCity(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //! Legal jurisdiction state */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Legal jurisdiction state*</Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="legalJurisdictionState"
              name="legalJurisdictionState"
              options={indianStateList}
              value={legalJurisdictionState}
              onChange={(value) => setLegalJurisdictionState(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>

        {/* //! Original lease deed bearer */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Original lease deed bearer*</Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="leaseDeedBearer"
              name="leaseDeedBearer"
              options={LicensesOfficialFeesResponsibilityTypeList}
              value={leaseDeedBearer}
              onChange={(value) => setLeaseDeedBearer(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>

        {/* //! Lessee's stamp duty % */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Lessee's stamp duty %*</Label>
            </strong>
            <Input
              type="text"
              value={lesseeStampDuty}
              id="lesseeStampDuty"
              name="lesseeStampDuty"
              //placeholder="Add lessee's stamp duty %"
              onChange={(e) => setLesseeStampDuty(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //! Stamp duty & registration charges */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Stamp duty & registration charges*
              </Label>
            </strong>
            <Input
              type="text"
              value={stampDutyAndRegistrationCharges}
              id="stampDutyAndRegistrationCharges"
              name="stampDutyAndRegistrationCharges"
              //placeholder="Add stamp duty & registration charges"
              onChange={(e) =>
                setStampDutyAndRegistrationCharges(e.target.value)
              }
            />
          </FormGroup>
        </Col>

        {/* //!  Registered licenses entity */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="registeredLicensesEntity">
                Registered licenses entity*
              </Label>
            </strong>
            <Input
              type="text"
              value={registeredLicensesEntity}
              id="registeredLicensesEntity"
              name="registeredLicensesEntity"
              //placeholder="Add registered licenses entity"
              onChange={(e) => setRegisteredLicensesEntity(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //! Licenses obtainance/renewal responsibility */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesResponsibility">
                Licenses obtainance/renewal responsibility*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="licensesResponsibility"
              name="licensesResponsibility"
              options={LicensesObtainanceOrRenewalResponsibilityTypeList}
              value={licensesResponsibility}
              onChange={(value) => setLicensesResponsibility(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>

        {/* //! Licenses official fees responsibility */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesFeesResponsibility">
                Licenses official fees responsibility*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="licensesFeesResponsibility"
              name="licensesFeesResponsibility"
              options={LicensesOfficialFeesResponsibilityTypeList}
              value={licensesFeesResponsibility}
              onChange={(value) => setLicensesFeesResponsibility(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="municipalityTradeValid ">
                Municipality trade license*
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="municipalityTradeValid ">
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="municipalityTradeValid "
                    name="municipalityTradeValid "
                    options={ValidTypeList}
                    value={municipalityTradeValid}
                    onChange={(value) => setMunicipalityTradeValid(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="municipalityTradeValid ">
                Health / Sanitory license*
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="municipalityTradeValid ">
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="municipalityTradeValid "
                    name="municipalityTradeValid "
                    options={ValidTypeList}
                    value={municipalityTradeValid}
                    onChange={(value) => setMunicipalityTradeValid(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="municipalityTradeValid ">
                Labour License*
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="municipalityTradeValid ">
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="municipalityTradeValid "
                    name="municipalityTradeValid "
                    options={ValidTypeList}
                    value={municipalityTradeValid}
                    onChange={(value) => setMunicipalityTradeValid(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesFeesResponsibility">
                PCB CTO certificate*
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label
                    className="form-label"
                    for="licensesFeesResponsibility"
                  >
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="licensesFeesResponsibility"
                    name="licensesFeesResponsibility"
                    options={ValidTypeList}
                    value={licensesFeesResponsibility}
                    onChange={(value) => setLicensesFeesResponsibility(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesFeesResponsibility">
                Fire NOC*
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label
                    className="form-label"
                    for="licensesFeesResponsibility"
                  >
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="licensesFeesResponsibility"
                    name="licensesFeesResponsibility"
                    options={ValidTypeList}
                    value={licensesFeesResponsibility}
                    onChange={(value) => setLicensesFeesResponsibility(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesFeesResponsibility">
                Tourism*
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label
                    className="form-label"
                    for="licensesFeesResponsibility"
                  >
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="licensesFeesResponsibility"
                    name="licensesFeesResponsibility"
                    options={ValidTypeList}
                    value={licensesFeesResponsibility}
                    onChange={(value) => setLicensesFeesResponsibility(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesFeesResponsibility">
                PCC NOC*
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label
                    className="form-label"
                    for="licensesFeesResponsibility"
                  >
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="licensesFeesResponsibility"
                    name="licensesFeesResponsibility"
                    options={ValidTypeList}
                    value={licensesFeesResponsibility}
                    onChange={(value) => setLicensesFeesResponsibility(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesFeesResponsibility">
                Excise & bar*
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label
                    className="form-label"
                    for="licensesFeesResponsibility"
                  >
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="licensesFeesResponsibility"
                    name="licensesFeesResponsibility"
                    options={ValidTypeList}
                    value={licensesFeesResponsibility}
                    onChange={(value) => setLicensesFeesResponsibility(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesFeesResponsibility">
                Shop & establishment *
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label
                    className="form-label"
                    for="licensesFeesResponsibility"
                  >
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="licensesFeesResponsibility"
                    name="licensesFeesResponsibility"
                    options={ValidTypeList}
                    value={licensesFeesResponsibility}
                    onChange={(value) => setLicensesFeesResponsibility(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesFeesResponsibility">
                FSSAI *
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label
                    className="form-label"
                    for="licensesFeesResponsibility"
                  >
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="licensesFeesResponsibility"
                    name="licensesFeesResponsibility"
                    options={ValidTypeList}
                    value={licensesFeesResponsibility}
                    onChange={(value) => setLicensesFeesResponsibility(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesFeesResponsibility">
                GST certificate *
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label
                    className="form-label"
                    for="licensesFeesResponsibility"
                  >
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="licensesFeesResponsibility"
                    name="licensesFeesResponsibility"
                    options={ValidTypeList}
                    value={licensesFeesResponsibility}
                    onChange={(value) => setLicensesFeesResponsibility(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesFeesResponsibility">
                Lift clearance *
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label
                    className="form-label"
                    for="licensesFeesResponsibility"
                  >
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="licensesFeesResponsibility"
                    name="licensesFeesResponsibility"
                    options={ValidTypeList}
                    value={licensesFeesResponsibility}
                    onChange={(value) => setLicensesFeesResponsibility(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesFeesResponsibility">
                Music NOC *
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label
                    className="form-label"
                    for="licensesFeesResponsibility"
                  >
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="licensesFeesResponsibility"
                    name="licensesFeesResponsibility"
                    options={ValidTypeList}
                    value={licensesFeesResponsibility}
                    onChange={(value) => setLicensesFeesResponsibility(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="licensesFeesResponsibility">
                Signage NOC*
              </Label>
            </strong>

            <Row>
              <Col sm="12">
                {" "}
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label
                    className="form-label"
                    for="licensesFeesResponsibility"
                  >
                    Valid*
                  </Label>
                  {/* </strong> */}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    id="licensesFeesResponsibility"
                    name="licensesFeesResponsibility"
                    options={ValidTypeList}
                    value={licensesFeesResponsibility}
                    onChange={(value) => setLicensesFeesResponsibility(value)}
                    classNamePrefix="select"
                    placeholder=""
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormGroup className="w-100">
                  {/* <strong> */}
                  <Label className="form-label" for="expiry date">
                    Valid upto*
                  </Label>
                  {/* </strong> */}
                  <Flatpickr
                    className="form-control bg-white border-dark text-dark"
                    // value={expirationDate}
                    // onChange={(date) =>
                    //   setExpirationDate(new Date(dateFormatter(new Date(date))))
                    // }
                    id="expirationDate"
                    // placeholder="Add Expiry Date"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm="3">
                {/* <strong> */}{" "}
                <Label className="form-label" for="floorPlans">
                  Upload*
                </Label>
                {/* </strong> */}
                <AttachmentComponent
                  imageList={floorPlans}
                  setImageList={setFloorPlans}
                  editData={floorPlansImageDummyState}
                  uploadType={uploadFloorPlansImageType}
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default DealBasics;
