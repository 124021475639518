// Function to calculate total inventory based on number of beds and days in month
const calculateTotalInventory = (noOfBeds, month, year) => {
  const beds = noOfBeds || 0;
  const daysInMonth = getDaysInMonth(month, year);
  return parseFloat((beds * daysInMonth).toFixed(2));
};

// Function to get the number of days in a given month/year
const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

// Function to calculate Total Revenue based on Bed Revenue, F&B Revenue, and Ancillary Revenue
const calculateTotalRevenue = (
  bedRevenue = 0,
  fbRevenue = 0,
  ancillaryRevenue = 0
) => {
  return (bedRevenue || 0) + (fbRevenue || 0) + (ancillaryRevenue || 0);
};

// Function to calculate Occupancy Percentage
const calculateOccupancyPercentage = (tsbn, tabn) => {
  const sold = tsbn || 0;
  const available = tabn || 1;
  return parseFloat(((sold / available) * 100).toFixed(2));
};

// Function to calculate F&B AOV (in INR)
const calculateFBAOV = (fnbRevenue, fnbOrders) => {
  const revenue = fnbRevenue || 0;
  const orders = fnbOrders || 1;
  return parseFloat((revenue / orders).toFixed(2));
};

// Function to calculate F&B Orders Per Bed Night Sold
const calculateFBOrderPerBedNightSold = (fnbOrders, tsbn) => {
  const orders = fnbOrders || 0;
  const bedNights = tsbn || 1;
  return parseFloat((orders / bedNights).toFixed(2));
};

// Function to calculate Total OPEX
const calculateTotalOPEX = (
  rent,
  u1,
  u2,
  u3,
  u4,
  u5,
  rAndM,
  misc,
  ota,
  totalSalary,
  fbSalary,
  fbMaterial,
  ancillaryCost,
  franchisePayout
) => {
  const values = [
    rent || 0,
    u1 || 0,
    u2 || 0,
    u3 || 0,
    u4 || 0,
    u5 || 0,
    rAndM || 0,
    misc || 0,
    ota || 0,
    totalSalary || 0,
    fbSalary || 0,
    fbMaterial || 0,
    ancillaryCost || 0,
    franchisePayout || 0,
  ];
  return values.reduce((sum, value) => sum + parseFloat(value), 0);
};

// Function to calculate Rental Cost Per Bed
const calculateRentalCostPerBed = (rent, noOfBeds) => {
  const rentValue = rent || 0;
  const beds = noOfBeds || 1;
  return parseFloat((rentValue / beds).toFixed(2));
};

// Function to calculate Total salary
const calculateTotalSalary = (...values) => {
  const validValues = values.map((value) => value || 0);
  return validValues.reduce((sum, value) => sum + value, 0);
};

// Function to calculate Number of Hostel Staff
const calculateNoOfHostelStaff = (hostelMandays, days) => {
  const mandays = hostelMandays || 0;
  const totalDays = days || 1;
  return parseFloat((mandays / totalDays).toFixed(2));
};

// Function to calculate Hostel Salary Per Manday
const calculateHostelSalaryPerManday = (
  hostelSalaryOwn,
  hostelSalaryAgency,
  hostelWelfare,
  hostelMandays
) => {
  const totalSalary =
    (hostelSalaryOwn || 0) + (hostelSalaryAgency || 0) + (hostelWelfare || 0);
  const mandays = hostelMandays || 1;
  return parseFloat((totalSalary / mandays).toFixed(2));
};

// Function to calculate Number of Cafe Staff
const calculateNumberOfCafeStaff = (cafeMandays, days) => {
  const mandays = cafeMandays || 0;
  const totalDays = days || 1;
  return parseFloat((mandays / totalDays).toFixed(2));
};

// Function to calculate Cafe Salary Per Manday
const calculateCafeSalaryPerManday = (fbSalaryAndWelfare, cafeMandays) => {
  const salary = fbSalaryAndWelfare || 0;
  const mandays = cafeMandays || 1;
  return parseFloat((salary / mandays).toFixed(2));
};

// Function to calculate Other Payouts
const calculateOtherPayouts = (ipPayout, franchisePayout) => {
  const ip = ipPayout || 0;
  const franchise = franchisePayout || 0;
  return parseFloat((ip + franchise).toFixed(2));
};

// Function to calculate Overall Operation Margin
const calculateOverallOpMargin = (totalRevenue, totalOpex, otherPayout) => {
  const revenue = totalRevenue || 0;
  const opex = totalOpex || 0;
  const payout = otherPayout || 0;
  return parseFloat((revenue - opex - payout).toFixed(2));
};

// Function to calculate OTA
// const calculateOTA = (bedRevenue) => {
//   return parseFloat((bedRevenue * 0.1).toFixed(2));
// };

// Function to calculate Hostel Margin (in INR)
const calculateHostelMargin = (
  bedRevenue,
  rent,
  u1,
  u2,
  u3,
  u4,
  u5,
  rAndM,
  misc,
  ota,
  totalSalary,
  otherPayouts
) => {
  const totalExpenses =
    rent + u1 + u2 + u3 + u4 + u5 + rAndM + misc + ota + totalSalary;

  // Ensure the result is a Float and rounded to 2 decimal places
  return parseFloat(bedRevenue - totalExpenses - otherPayouts || 0).toFixed(2);
};

// Function to calculate Hostel Margin (in %)
const calculateHostelMarginPercentage = (hostelMarginInINR, bedRevenue) => {
  if (bedRevenue === 0 || bedRevenue === 1) {
    return hostelMarginInINR > 0 ? 100 : 0;
  }

  if (bedRevenue && hostelMarginInINR !== null) {
    return parseFloat(((hostelMarginInINR / bedRevenue) * 100).toFixed(2));
  }

  return 0;
};

// Function to calculate F&B margin (in INR)
const calculateFBMarginInINR = (
  fbRevenue,
  fbSalaryWelfare,
  cafeSalaryPerManday,
  fbMaterialCost
) => {
  const values = [
    fbSalaryWelfare || 0,
    cafeSalaryPerManday || 0,
    fbMaterialCost || 0,
  ];

  const totalCost = values.reduce((sum, value) => sum + parseFloat(value), 0);

  return fbRevenue ? parseFloat((fbRevenue - totalCost).toFixed(2)) : 0;
};

// Function to calculate F&B margin (in %)

const calculateFBMarginPercentage = (fbMarginInInr, fbRevenue) => {
  if (fbRevenue && fbRevenue !== 0) {
    return parseFloat(((fbMarginInInr / fbRevenue) * 100).toFixed(2));
  }
  return 0;
};

const calculateAncillaryMarginInInr = (ancillaryRevenue, ancillaryCost) => {
  const revenue = ancillaryRevenue || 0;
  const cost = ancillaryCost || 0;
  return parseFloat((revenue - cost).toFixed(2));
};

const calculateAncillaryMarginInPercentage = (
  ancillaryMarginInInr,
  ancillaryRevenue
) => {
  const margin = ancillaryMarginInInr || 0;
  const revenue = ancillaryRevenue || 0;

  return revenue !== 0 ? parseFloat(((margin / revenue) * 100).toFixed(2)) : 0;
};

const calculateAnalysisOperationalMarginPercentage = (
  overallOpMarginInInr,
  totalRevenue
) => {
  const margin = overallOpMarginInInr || 0;
  const revenue = totalRevenue || 0;

  return revenue !== 0 ? parseFloat(((margin / revenue) * 100).toFixed(2)) : 0;
};

const calculateRevRentalMultiple = (bedRevenue, rent) => {
  const revenue = bedRevenue || 0;
  const rentalCost = rent || 0;

  return rentalCost !== 0 ? parseFloat((revenue / rentalCost).toFixed(2)) : 0;
};

const calculateOTAPercentage = (ota, bedRevenue) => {
  const otaValue = ota || 0;
  const revenue = bedRevenue || 0;

  return revenue !== 0
    ? parseFloat(((otaValue / revenue) * 100).toFixed(2))
    : 0;
};

const calculateUtilitiesTotalRev = (u1, u2, u3, bedRevenue) => {
  const totalUtilities = (u1 || 0) + (u2 || 0) + (u3 || 0);
  return bedRevenue ? parseFloat((totalUtilities / bedRevenue).toFixed(4)) : 0;
};

const calculateRMTotalRev = (rAndM, bedRevenue) => {
  return bedRevenue ? parseFloat((rAndM / bedRevenue).toFixed(4)) : 0;
};

const calculateMiscTotalRev = (misc, bedRevenue) => {
  return bedRevenue ? parseFloat((misc / bedRevenue).toFixed(4)) : 0;
};

const calculateHostelStaffSalaryPerBedSales = (totalSalary, bedRevenue) => {
  return bedRevenue ? parseFloat((totalSalary / bedRevenue).toFixed(4)) : 0;
};

const calculateFNBStaffSalaryPerFNBSales = (fnbSalaryWelfare, fnbRevenue) => {
  return fnbRevenue
    ? parseFloat((fnbSalaryWelfare / fnbRevenue).toFixed(4))
    : 0;
};

const calculateFNBFoodCostPerFNBRevenue = (fnbMaterialCost, fnbRevenue) => {
  return fnbRevenue ? parseFloat((fnbMaterialCost / fnbRevenue).toFixed(4)) : 0;
};

const calculateHostelStaffCount = (hostelMandays, month, year) => {
  const daysInMonth = getDaysInMonth(month, year);
  return daysInMonth ? parseFloat(hostelMandays / daysInMonth) : 0;
};

const calculateCafeStaffCount = (cafeMandays, month, year) => {
  const daysInMonth = getDaysInMonth(month, year);
  return daysInMonth ? parseFloat(cafeMandays / daysInMonth) : 0;
};

export const formulas = {
  calculateTotalInventory,
  getDaysInMonth,
  calculateTotalRevenue,
  calculateOccupancyPercentage,
  calculateFBAOV,
  calculateFBOrderPerBedNightSold,
  calculateTotalOPEX,
  calculateRentalCostPerBed,
  calculateTotalSalary,
  calculateNoOfHostelStaff,
  calculateHostelSalaryPerManday,
  calculateNumberOfCafeStaff,
  calculateCafeSalaryPerManday,
  calculateOtherPayouts,
  calculateOverallOpMargin,
  // calculateOTA,
  calculateHostelMargin,
  calculateHostelMarginPercentage,
  calculateFBMarginInINR,
  calculateFBMarginPercentage,
  calculateAncillaryMarginInInr,
  calculateAncillaryMarginInPercentage,
  calculateAnalysisOperationalMarginPercentage,
  calculateRevRentalMultiple,
  calculateOTAPercentage,
  calculateUtilitiesTotalRev,
  calculateRMTotalRev,
  calculateMiscTotalRev,
  calculateHostelStaffSalaryPerBedSales,
  calculateFNBStaffSalaryPerFNBSales,
  calculateFNBFoodCostPerFNBRevenue,
  calculateHostelStaffCount,
  calculateCafeStaffCount,
};
