import React, { useEffect, useState } from "react";
import { Button, CardHeader, Col, Input, Row, Table } from "reactstrap";
import moment from "moment";
import { dateFormatterDDMMYYYY } from "../../../utility/Utils";
import { useGetCreateAndUpdate } from "../../../api/MeterTracker/Mutations";
import toaster from "../../../@core/components/common/Toaster";
import { useSkin } from "@hooks/useSkin";
import LogModal from "./LogModal";
import { FileMinus } from "react-feather";

export const generateDateRange = (fromDate, toDate) => {
  const dayArray = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thrusday",
    "Friday",
    "Saturday",
  ];
  const dateList = [];
  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);

  for (
    let currentDate = startDate;
    currentDate <= endDate;
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    const formattedDate = {
      //date: currentDate.toLocaleDateString("en-IN"),
      date: moment(currentDate, "D/M/YYYY").format("DD/MM/YYYY"),
      //date: currentDate.toISOString().split("T")[0],
      day: dayArray[currentDate.getDay()],
    };
    dateList.push(formattedDate);
  }

  return dateList;
};

const convertToDbFormat = (dateStr) => {
  const [day, month, year] = dateStr.split("/");

  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

const exceeds24Hrs = (master, date, meterTrackerArray) => {
  return (
    (new Date().getTime() -
      new Date(
        meterTrackerArray?.find(
          (data) =>
            data?.id ===
            `readingUnit_${master?._id}_${date?.date}_${master?.hostel?._id}`
        )?.createdAt
      )?.getTime()) /
      86400000 >=
    1
  );
};

const MeterTrackerTable = ({
  MasterData,
  trackerData,
  fromDate,
  toDate,
  overallUnits,
}) => {
  const userRole = localStorage.getItem("role") || null;
  const dateList = generateDateRange(fromDate, toDate);
  const [skin, setSkin] = useSkin();
  const [backgroundColor, setBackgroundColor] = useState("#f3f2f7");
  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedEmployee: null,
  });

  const [meterTable, setMeterTable] = useState([]);

  const [updateTrackerHandler, { data }] = useGetCreateAndUpdate();

  useEffect(() => {
    if (MasterData?.length > 0) {
      setMeterTable(MasterData);
    } else {
      setMeterTable([]);
    }
  }, [MasterData]);
  const [meterTrackerArray, setMeterTrackerArray] = useState([]);
  useEffect(() => {
    if (trackerData?.length > 0) {
      let newArr = [];
      trackerData
        ?.filter((eachDocument) => eachDocument?.readingDate >= fromDate)
        ?.forEach(({ _id, ...el }) => {
          const stringDate = dateFormatterDDMMYYYY(el?.readingDate);
          newArr.push({
            id: `readingUnit_${el?.meterMasterId}_${stringDate}_${el?.hostel}`,
            ...el,
          });
        });
      setMeterTrackerArray(newArr);
    } else {
      setMeterTrackerArray([]);
    }
  }, [trackerData, fromDate]);

  useEffect(() => {
    if (data?.createAndUpdateTracker?.data === true) {
      toaster("success", "Tracker data updated successfully");
    }
  }, [data?.createAndUpdateTracker]);

  useEffect(() => {
    if (skin === "dark") {
      setBackgroundColor("#343d55");
    } else {
      setBackgroundColor("#f3f2f7");
    }
  }, [skin]);

  const handelAddDate = (id, object) => {
    const existingIndex = meterTrackerArray?.findIndex(
      (data) => data?.id === id
    );

    if (existingIndex !== -1) {
      const updatedArray = [...meterTrackerArray];
      updatedArray[existingIndex] = {
        ...updatedArray[existingIndex],
        ...object,
      };
      setMeterTrackerArray(updatedArray);
    } else {
      setMeterTrackerArray((prev) => [...prev, object]);
    }
  };

  const handleSubmit = () => {
    meterTrackerArray?.forEach((trackerData) => {
      delete trackerData?.id;
      delete trackerData?.createdAt;
      delete trackerData?.logs;
    });
    updateTrackerHandler(meterTrackerArray);
  };

  const handleOpenLogs = (date) => {
    setModalState({
      isOpen: true,
      selectedDate: date,
    });
  };

  const handleCloseLogs = () => {
    setModalState({
      isOpen: false,
      selectedDate: null,
    });
  };

  const handelUpdateUnit = (value) => {
    const trimmedValue = value.trim();
    return trimmedValue === "" ? "" : Number(trimmedValue);
  };

  return (
    <div>
      <Row>
        <Col>
          <>
            <CardHeader style={{ minWidth: "full" }}>
              <h4 style={{}}>Meter Tracker</h4>
            </CardHeader>
            <div style={{ overflowX: "auto", maxHeight: "600px" }}>
              <Table
                bordered
                responsive
                style={{
                  minWidth: "250px",
                  borderCollapse: "collapse",
                  margin: 0,
                }}
                //className="table"
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 0,
                        backgroundColor: backgroundColor,
                      }}
                    >
                      Meter CA Number
                    </th>
                    {dateList?.map((date, i) => (
                      <th key={i}>
                        <span>{date?.date}</span>
                        <br />
                        <span>{date?.day}</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {MasterData?.map((el) => {
                    return (
                      <tr key={el?._id}>
                        <td
                          style={{
                            position: "sticky",
                            left: 0,
                            zIndex: 0,
                            backgroundColor: backgroundColor,
                          }}
                        >
                          {el?.meter_CA_Number}
                        </td>
                        {dateList?.map((date) => {
                          const value = meterTrackerArray?.find(
                            (data) =>
                              data?.id ===
                              `readingUnit_${el?._id}_${date?.date}_${el?.hostel?._id}`
                          )?.readingUnits;

                          return (
                            <React.Fragment key={date?.date}>
                              <td>
                                <Input
                                  type="number"
                                  value={value}
                                  id={`readingUnit_${el?._id}_${date?.date}_${el?.hostel?._id}`}
                                  name="readingUnit"
                                  placeholder="units"
                                  disabled={
                                    (userRole === "Ground Staff" && value) ||
                                    (userRole === "Area Manager" &&
                                      exceeds24Hrs(
                                        el,
                                        date,
                                        meterTrackerArray
                                      ) &&
                                      value)
                                  }
                                  onChange={(e) =>
                                    handelAddDate(
                                      `readingUnit_${el?._id}_${date?.date}_${el?.hostel?._id}`,
                                      //e.target.value,
                                      {
                                        id: `readingUnit_${el?._id}_${date?.date}_${el?.hostel?._id}`,
                                        hostel: el?.hostel?._id,
                                        meterMasterId: el?._id,
                                        meter_CA_Number: el?.meter_CA_Number,
                                        readingDate: convertToDbFormat(
                                          date?.date
                                        ),
                                        readingUnits: handelUpdateUnit(
                                          e.target.value
                                        ),
                                        //Number(e.target.value),
                                      }
                                    )
                                  }
                                  onKeyDown={(e) =>
                                    ["e", "E", "+", "-"].includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onWheel={(e) => e.target.blur()}
                                />
                              </td>
                            </React.Fragment>
                          );
                        })}
                      </tr>
                    );
                  })}
                  <tr>
                    <td
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 0,
                        backgroundColor: backgroundColor,
                      }}
                    >
                      Overall meter units
                    </td>
                    {dateList?.map((date) => {
                      const findUnits = overallUnits.find(
                        (obj) =>
                          dateFormatterDDMMYYYY(obj?.readingDates) ===
                          date?.date
                      );
                      return (
                        <React.Fragment key={date?.date}>
                          <td style={{ textAlign: "center" }}>
                            {findUnits?.totalUnits || ""}
                          </td>
                        </React.Fragment>
                      );
                    })}
                  </tr>

                  <tr>
                    <td
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 0,
                        backgroundColor: backgroundColor,
                      }}
                    >
                      Submit
                    </td>
                    {dateList?.map((el, i) => {
                      return (
                        <td key={i} style={{ textAlign: "center", zIndex: 0 }}>
                          <Button
                            type="button"
                            color="success"
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td
                      style={{
                        position: "sticky",
                        left: 0,
                        zIndex: 0,
                        backgroundColor: backgroundColor,
                      }}
                    >
                      Logs
                    </td>
                    {dateList?.map((date, i) => {
                      return (
                        <td key={i} style={{ textAlign: "center" }}>
                          {/* <Button.Ripple
                            type="button"
                            color="success"
                            onClick={() => handleOpenLogs(date)}
                          >
                            Logs
                          </Button.Ripple> */}
                          <FileMinus
                            type="button"
                            onClick={() => handleOpenLogs(date)}
                            size={14}
                          />
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </Table>
            </div>
          </>
        </Col>
      </Row>

      {modalState.isOpen && (
        <LogModal
          showModal={modalState.isOpen}
          onClose={handleCloseLogs}
          meterTrackerArray={meterTrackerArray}
          date={modalState?.selectedDate}
        />
      )}
    </div>
  );
};

export default MeterTrackerTable;
