import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_PAGINATED_METER_MASTER = gql`
   query GetPaginatedMeterMaster($filter:filter){
       getPaginatedMeterMaster(filter:$filter){
        data{
            _id
            hostel{
                _id
                name
            }
             electricityProviderName
             meter_CA_Number
             entityName
             sanctionedLoad
             perUnitRate
             mobileNumber
             email
             avgUnitPerRoomPerDay
             billingPeriodStartDate
             billingPeriodEndDate
            billPaymentDueDate
            logs
        },
        count,
        limit
        page
       }
   }
`


export const useGetPaginatedMeterMaster = (limit, page, filterFeilds) => {
    const { loading, data, error, refetch } = useQuery(GET_PAGINATED_METER_MASTER, {
        variables: {
            filter: { limit, page, filterFeilds }
        }
    })

    useLoadingHandler(loading)

    return { data, error, refetch }
}


export const GET_METER_MASTER_FROM_HOSTEL_ID = gql`
   query GetMeterMasterFromHostelId($hostelId:ID){
     getMeterMasterFromHostelId(hostelId:$hostelId){
          _id
            hostel{
                _id
                name
            }
             electricityProviderName
             meter_CA_Number
             entityName
             sanctionedLoad
             perUnitRate
             mobileNumber
             email
             avgUnitPerRoomPerDay
             billingPeriodStartDate
             billingPeriodEndDate
            billPaymentDueDate
     }
   }
`

export const useGetMeterMasterByHostelId = () => {
    const [getMeterMaster, { loading, error, data, refetch }] = useLazyQuery(GET_METER_MASTER_FROM_HOSTEL_ID)

    useLoadingHandler(loading)

    const fetchHandler = (hostelId) => {
        getMeterMaster({
            variables: {
                hostelId,
                fetchPolicy: 'network-only',
                //nextFetchPolicy: 'on-cache'
            }
        })
    }

    return [fetchHandler, { data, error, refetch }]
}