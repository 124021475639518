import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_LAUNDRY_DATA = gql`
    mutation createLaundryData($LaundryDataInput: LaundryDataInput) {
        createLaundryData(LaundryDataInput: $LaundryDataInput) {
            hostelId {
            _id
            name
        }
        laundryVendorName
        mobileNumber
        address
        billPaymentDueDay
        laundryCycle
        itemRates {
            itemName
            itemRate
        }
        laundryAmountPerBedNight
        logs
        }
    }
`;

export const useCreateLaundryData = () => {
    const [createLaundryData, { loading, error, data, refetch }] =
        useMutation(CREATE_LAUNDRY_DATA);

    useLoadingHandler(loading);

    const createLaundryDataHandler = (LaundryDataInput) => {
        createLaundryData({
            variables: { LaundryDataInput },
        });
    };

    return [createLaundryDataHandler, { loading, error, data, refetch }];
};

export const UPDATE_LAUNDRY_MASTER = gql`
  mutation updateLaundryData($id: ID! $LaundryDataUpdate: LaundryDataInput) {
    updateLaundryData(id: $id LaundryDataUpdate: $LaundryDataUpdate) {
        hostelId {
            _id
            name
        }
        laundryVendorName
        mobileNumber
        address
        billPaymentDueDay
        laundryCycle
        itemRates {
            itemName
            itemRate
        }
        laundryAmountPerBedNight
        logs
    }
  }
`;

export const useUpdateLaundryMaster = () => {
  const [updateLaundryData, { loading, error, data, refetch }] = useMutation(
    UPDATE_LAUNDRY_MASTER
  );

  useLoadingHandler(loading);

  const updateLaundryMasterHandler = async (id, LaundryDataUpdate) => {
    return await updateLaundryData({
      variables: { id, LaundryDataUpdate },
    });
  };

  return [updateLaundryMasterHandler, { loading, error, data, refetch }];
};
