import { Codesandbox, Circle } from "react-feather";
const modules = JSON.parse(localStorage.getItem("modules"));

export default modules?.includes("Operations")
  ? [
      // {
      //   id: "kyh",
      //   title: "KYH",
      //   icon: <Codesandbox size={20} />,
      //   badge: "light-warning",
      //   children: [
      //     {
      //       id: "supply",
      //       title: "Supply",
      //       icon: <Circle size={12} />,
      //       navLink: "/kyh/supply",
      //     },
      //   ],
      // },
    ]
  : [];
