import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import SlideModal from "./slide-modal";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { selectThemeColors, dateFormatter } from "@utils";
import makeAnimated from "react-select/animated";
import {
  useHostelList,
  useGetAllMessageTemplateList,
  useGetMessageTemplateByName,
  useAddMessageConfiguration,
  useUpdateMessageConfiguration,
  useGetAllMessageConfigurationRecievers,
} from "../../../api/dataSource";
import toaster from "@components/common/Toaster";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import {
  indianStateList,
  oneToFiftyCountForSelect,
  oneToThirtyForSelect,
} from "../../../utility/Utils";
import SwitchIcon from "@components/common/switch-icon";


const animatedComponents = makeAnimated();
const AddEditModal = ({ open, handleToggle, refetch, editData, setEditId }) => {
  const [template, setTemplate] = useState(null);
  const [hostel, setHostel] = useState([]);
  const [TemplateList, setTemplateList] = useState([]);
  const [HostelsList, setHostelsList] = useState([]);
  const [guestStatus, setGuestStatus] = useState(null);
  const [fromDate, setFromDate] = useState(dateFormatter(new Date()));
  const [toDate, setToDate] = useState(dateFormatter(new Date()));
  const [timeToSend, setTimeToSend] = useState(new Date());
  const [templateMessage, setTemplateMessage] = useState("");
  const [gender, setGender] = useState([]);
  const [groupSize, setGroupSize] = useState([]);
  const [residingCountry, setResidingCountry] = useState([]);
  const [residingState, setResidingState] = useState([]);
  const [stayedBefore, setStayedBefore] = useState("");
  const [bestDescribed, setBestDescribed] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [modeOfTransport, setModeOfTransport] = useState([]);
  const [minimumNoOfNights, setMinimumNoOfNights] = useState(null);
  const [bookingFrequency, setBookingFrequency] = useState(null);
  const [lastStayed, setLastStayed] = useState(null);
  const [bookingSource, setBookingSource] = useState(null);
  const [age, setAge] = useState([]);
  const [stayDuration, setStayDuration] = useState(null);;
  const [daysToSend, setDaysToSend] = useState([]);
  const [toDateOfCheckin, setToDateOfCheckin] = useState(null);
  const [fromDateOfCheckin, setFromDateOfCheckin] = useState(null)
  const [frequencyStartDate, setFrequencyStartDate] = useState(null)
  const [frequencyEndDate, setFrequencyEndDate] = useState(null)
  const [stayFrequency, setStayFrequency] = useState(null)
  const [membershipStatus, setMembershipStatus] = useState(null)
  const [lastStayedStartDate, setLastStayedStartDate] = useState(null)
  const [lastStayedEndDate, setLastStayedEndDate] = useState(null)
  const [notStayedAfterDate, setNotStayedAfterDate] = useState(null)
  const [notStayedAfterMonth, setNotStayedAfterMonth] = useState(null)
  const [showStayFrequency, setShowStayFrequency] = useState(true)
  const [addMessageConfigurationHandler, { data }] =
    useAddMessageConfiguration();

  const { data: allMessageTemplate } = useGetAllMessageTemplateList();
  const { data: allHostelsData } = useHostelList();
  const { data: messageTemplateMessageData } = useGetMessageTemplateByName(
    template?.label
  );

  let query = {};
  if (groupSize?.length > 0) {
    query.groupSize = groupSize.map((item) => item?.value);
  }
  if (gender?.length) {
    query.gender = gender?.map((item) => item?.value);
  }
  if (residingCountry) {
    query.residingCountry = residingCountry?.value;
  }
  if (stayedBefore) {
    query.stayedWithTheHostellerBefore = stayedBefore?.value;
  }
  if (residingState?.length > 0) {
    query.residingState = residingState.map((item) => item?.value);
  }
  if (modeOfTransport?.length > 0) {
    query.modeOfTransport = modeOfTransport.map((item) => item?.value);
  }
  if (bestDescribed?.length > 0) {
    query.whatWouldBestDescribeYou = bestDescribed.map((item) => item?.value);
  }
  if (minimumNoOfNights) {
    query.minimumNoOfNights = minimumNoOfNights?.value;
  }
  if (bookingFrequency) {
    query.bookingFrequency = bookingFrequency?.value;
  }
  if (hostel.length > 0) {
    query.hostels = hostel.map((item) => item?.value);
  }
  if (age) {
    query.age = age.map((item) => item?.value);
  }
  if (bookingSource) {
    query.bookingSource = bookingSource?.value;
  }
  if (stayDuration) {
    query.stayDuration = stayDuration?.value;
  }
  if (daysToSend) {
    query.daysToSend = daysToSend?.map((item) => item?.value);
  }
  if (guestStatus) {
    query.guestStatus = guestStatus?.value;
  }
  if (fromDateOfCheckin && toDateOfCheckin) {
    query.fromCheckinDate = fromDateOfCheckin;
    query.toCheckinDate = toDateOfCheckin;
  }
  if (frequencyStartDate && frequencyEndDate && showStayFrequency) {
    query.frequencyStartDate = frequencyStartDate;
    query.frequencyEndDate = frequencyEndDate;
  }
  if (stayFrequency && showStayFrequency) {
    query.stayFrequency = stayFrequency?.value;
  }
  if (membershipStatus) {
    query.membershipStatus = membershipStatus?.value;
  }
  if (lastStayedStartDate && lastStayedEndDate && !showStayFrequency) {
    query.lastStayedStartDate = lastStayedStartDate;
    query.lastStayedEndDate = lastStayedEndDate;
  }
  if (notStayedAfterDate && !showStayFrequency) {
    query.notStayedAfterDate = notStayedAfterDate;
  }


  // const { data: recieverCount } = useGetAllMessageConfigurationRecievers(query);
  const [getMessageRecieverCountHandler, { data: recieverCount }] = useGetAllMessageConfigurationRecievers()

  const [
    updateMessageConfigurationHandler,
    { data: updatedMessageConfigurationData },
  ] = useUpdateMessageConfiguration();

  const GuestStatusList = [
    // { label: "Confirmed Reservation", value: "Confirmed Reservation" },
    { label: "All", value: "All" },
    { label: "Arrived", value: "Arrived" },
    { label: "Pre-Arrival", value: "Pre-Arrival" },
    // { label: "Checked Out", value: "Checked Out" },
  ];
  const genderList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ];
  const groupSizeList = [
    { label: "Solo", value: "Solo" },
    { label: "2", value: "2" },
    { label: "3-4", value: "3-4" },
    { label: "5-6", value: "5-6" },
    { label: "6+", value: "6+" },
  ];

  const residingCountryList = [
    { label: "India", value: "India" },
    { label: "Foreign", value: "Foreign" },
  ];

  const stayedBeforeList = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  const bestDescribedList = [
    { label: "Student", value: "Student" },
    { label: "Business Professional", value: "Business Professional" },
    { label: "Working Professional", value: "Working Professional" },
    { label: "Freelancer", value: "Freelancer" },
    { label: "Other", value: "Other" },
  ];

  const transportOptions = [
    {
      value: "Public Transport",
      label: "Public Transport",
    },
    {
      value: "Private Transport",
      label: "Private Transport",
    },
    {
      value: "Rental Transport",
      label: "Rental Transport",
    },
  ];

  const whenYouLastStayed = [
    {
      value: "0-6 M",
      label: "0-6 M",
    },
    {
      value: "6-12 M",
      label: "6-12 M",
    },
    {
      value: "12+ M",
      label: "12+ M",
    },
  ];

  const bookingSourceOptions = [
    {
      value: "OTA",
      label: "OTA",
    },
    {
      value: "Website",
      label: "Website",
    },
    {
      value: "Others",
      label: "Others",
    },
  ];

  const ageOptions = [
    {
      value: "18-21",
      label: "18-21",
    },
    {
      value: "22-25",
      label: "22-25",
    },
    {
      value: "26-28",
      label: "26-28",
    },
    {
      value: "29-32",
      label: "29-32",
    },
    {
      value: "32+",
      label: "32+",
    },
  ];

  const daysList = [
    { label: "Sunday", value: 0 },
    { label: "Monday", value: 1 },
    { label: "Tuesday", value: 2 },
    { label: "Wednesday", value: 3 },
    { label: "Thursday", value: 4 },
    { label: "Friday", value: 5 },
    { label: "Saturday", value: 6 },
  ];

  const stayFrequencyList = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
  ]

  const membershipOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ]
  useEffect(() => {
    let TemplateList = [];
    if (allMessageTemplate?.getAllMessageTemplateList?.length) {
      allMessageTemplate?.getAllMessageTemplateList?.map((item) => {
        TemplateList.push(
          { label: item?.TemplateName, value: item?._id }
          // setTemplateMessage(item?.TemplateMessage)
        );

        setTemplateList(TemplateList);
      });
    }
  }, [allMessageTemplate?.getAllMessageTemplateList]);

  useEffect(() => {
    if (allHostelsData?.getHostelList?.length) {
      // eslint-disable-next-line array-callback-return
      allHostelsData?.getHostelList?.map((item) => {
        setHostelsList((prev) => [
          ...prev,
          { ...item, label: item?.name, value: item?._id },
        ]);
      });
    }
  }, [allHostelsData?.getHostelList]);

  const handleChange = function (HostelsList) {
    return setHostel(HostelsList);
  };

  useEffect(() => {
    if (
      data?.createMessageConfiguration?.template ||
      updatedMessageConfigurationData
    ) {
      refetch();
      handleToggle();

      toaster("success", "Scheduler Configuration added successfully");
    }
  }, [data, updatedMessageConfigurationData]);

  useEffect(() => {
    setTemplateMessage(
      messageTemplateMessageData?.getMessageTemplateByName?.TemplateMessage
    );
  }, [messageTemplateMessageData]);

  const submitDataHandler = () => {
    const hostelsArray = [];
    // eslint-disable-next-line array-callback-return
    if (guestStatus?.value === "Pre-Arrival") {
      if (!toDateOfCheckin || !fromDateOfCheckin) {
        toaster("error", "Please select checkin date range");
        return;
      }
    }
    hostel?.map((item) => {
      hostelsArray?.push(item?.value);
    });
    const groupSizeList = groupSize?.length
      ? groupSize?.map((item) => item?.value)
      : "";
    const residingStateList = residingState?.length
      ? residingState?.map((item) => item?.value)
      : [];
    const bestDescribedList = bestDescribed.length
      ? bestDescribed?.map((item) => item?.value)
      : "";
    const modeOfTransportList = modeOfTransport.length
      ? modeOfTransport?.map((item) => item?.value)
      : "";
    const selectedGenderList = gender.length
      ? gender?.map((item) => item?.value)
      : "";
    const lastStayedList = lastStayed?.length
      ? lastStayed?.map((item) => item?.value)
      : "";
    const ageList = age?.length ? age?.map((item) => item?.value) : "";
    const daysToSendList = daysToSend?.length ? daysToSend?.map((item) => item?.value) : ""
    // const bookingSourceList = bookingSource?.length ? bookingSource?.map((item)=> item?.value):"";
    let data = {
      template: template?.value,
      hostels: JSON.parse(JSON.stringify(hostelsArray)),
      guestStatus: guestStatus?.value,
      fromDate,
      toDate,
      timeToSend: new Date(timeToSend), //?.filter((i) => Boolean(i)),
      campaignName,
      gender: selectedGenderList.length > 0 ? selectedGenderList : [],
      groupSize: groupSizeList.length > 0 ? groupSizeList : [],
      residingCountry: residingCountry ? residingCountry?.value : "",
      residingState: residingStateList.length > 0 ? residingStateList : [],
      modeOfTransport:
        modeOfTransportList.length > 0 ? modeOfTransportList : [],
      stayedWithTheHostellerBefore: stayedBefore?.value,
      whatWouldBestDescribeYou:
        bestDescribedList.length > 0 ? bestDescribedList : [],
      minimumNoOfNights: minimumNoOfNights?.value,
      bookingFrequency: bookingFrequency?.value,
      lastStayed: lastStayedList.length > 0 ? lastStayedList : [],
      age: ageList.length > 0 ? ageList : [],
      bookingSource: bookingSource ? bookingSource?.value : null,
      stayDuration: stayDuration ? stayDuration?.value : null,
      daysToSend: daysToSend?.length > 0 ? daysToSendList : [],
      toCheckinDate: toDateOfCheckin ? toDateOfCheckin : null,
      fromCheckinDate: fromDateOfCheckin ? fromDateOfCheckin : null,
      frequencyStartDate: showStayFrequency && frequencyStartDate ? frequencyStartDate : null,
      frequencyEndDate: showStayFrequency && frequencyEndDate ? frequencyEndDate : null,
      stayFrequency: showStayFrequency && stayFrequency ? stayFrequency.value : null,
      membershipStatus: membershipStatus ? membershipStatus.value : null,
      lastStayedStartDate: !showStayFrequency && lastStayedStartDate ? lastStayedStartDate : null,
      lastStayedEndDate: !showStayFrequency && lastStayedEndDate ? lastStayedEndDate : null,
      notStayedAfterDate: !showStayFrequency && notStayedAfterDate ? notStayedAfterDate : null,
      notStayedAfterMonth: !showStayFrequency && notStayedAfterMonth ? notStayedAfterMonth.value : null,
    };

    if (data?.template && guestStatus) {
      if (editData?.length) {
        const { _id } = editData[0];
        updateMessageConfigurationHandler(_id, data);
      } else {
        addMessageConfigurationHandler(data);
      }
      setTemplate(null);
      setHostel([]);
      setGuestStatus(null);
      setFromDate(dateFormatter(new Date()));
      setToDate(dateFormatter(new Date()));
      setTimeToSend(new Date());
      setCampaignName(null);
      setStayDuration(null);
      setGender(null);
      setGroupSize(null);
      setResidingCountry(null);
      setResidingState(null);
      setStayedBefore(null);
      setBestDescribed(null);
      setModeOfTransport(null);
      setBookingSource(null);
      setAge(null);
      setDaysToSend([]);
      setToDateOfCheckin(null);
      setFromDateOfCheckin(null);
    } else {
      toaster("error", "Please fill all the required details");
    }
  };
  useEffect(() => {
    if (editData?.length) {
      const {
        template,
        hostels,
        guestStatus,
        fromDate,
        toDate,
        timeToSend,
        residingCountry,
        gender,
        stayedWithTheHostellerBefore,
        whatWouldBestDescribeYou,
        modeOfTransport,
        residingState,
        groupSize,
        campaignName,
        minimumNoOfNights,
        bookingFrequency,
        lastStayed,
        age,
        bookingSource,
        stayDuration,
        daysToSend,
        toCheckinDate,
        fromCheckinDate,
        frequencyStartDate,
        frequencyEndDate,
        stayFrequency,
        membershipStatus,
        lastStayedStartDate,
        lastStayedEndDate,
        notStayedAfterDate,
        notStayedAfterMonth,
      } = editData[0];

      let hotelSelectedData = [];
      editData[0]?.hostels?.map((hostel) => {
        allHostelsData?.getHostelList?.map((hostellist) => {
          if (hostel?._id === hostellist?._id) {
            hotelSelectedData?.push({
              label: hostel?.name,
              value: hostel?._id,
            });
          }
        });
      });

      let selectedGroupSize = [];
      groupSize?.map((item) => {
        selectedGroupSize.push({
          label: item,
          value: item,
        });
      });

      let selectedState = [];
      residingState?.length &&
        residingState?.map((item) => {
          selectedState.push({
            label: item,
            value: item,
          });
        });

      let selectedBestDescribed = [];
      whatWouldBestDescribeYou?.map((item) => {
        selectedBestDescribed.push({
          label: item,
          value: item,
        });
      });

      let selectedModeOfTransport = [];
      modeOfTransport?.map((item) => {
        selectedModeOfTransport.push({
          label: item,
          value: item,
        });
      });

      let selectedGender = [];
      gender?.map((item) => {
        selectedGender.push({
          label: item,
          value: item,
        });
      });

      setTemplate({
        label: template?.TemplateName,
        value: template?._id,
      });

      let selectedLastStayed = [];
      lastStayed?.map((item) => {
        selectedLastStayed.push({
          label: item,
          value: item,
        });
      });
      let selectedAge = [];
      age?.map((item) => {
        selectedAge.push({
          label: item,
          value: item,
        });
      });

      const selectedToSendDays = daysToSend?.map(index => daysList[index]);

      // let selectedBookingSource = [];
      // bookingSource?.map((item)=>{
      //   selectedBookingSource.push({
      //     label:item,
      //     value:item
      //   })
      // })

      setHostel(hotelSelectedData);
      setGuestStatus({ label: guestStatus, value: guestStatus });
      setFromDate(dateFormatter(fromDate));
      setToDate(dateFormatter(toDate));
      setTimeToSend(timeToSend);
      setGender(selectedGender);
      stayedWithTheHostellerBefore &&
        setStayedBefore({
          label: stayedWithTheHostellerBefore,
          value: stayedWithTheHostellerBefore,
        });
      residingCountry &&
        setResidingCountry({ label: residingCountry, value: residingCountry });
      setGroupSize(selectedGroupSize);
      setBestDescribed(selectedBestDescribed);
      setModeOfTransport(selectedModeOfTransport);
      setResidingState(selectedState);
      setCampaignName(campaignName);
      setMinimumNoOfNights({
        label: minimumNoOfNights,
        value: minimumNoOfNights,
      });
      setBookingFrequency({ label: bookingFrequency, value: bookingFrequency });
      setLastStayed(selectedLastStayed);
      setAge(selectedAge);
      setBookingSource({ label: bookingSource, value: bookingSource });
      stayDuration &&
        setStayDuration({ label: stayDuration, value: stayDuration });
      setDaysToSend(selectedToSendDays);
      fromCheckinDate && setFromDateOfCheckin(dateFormatter(fromCheckinDate))
      toCheckinDate && setToDateOfCheckin(dateFormatter(toCheckinDate))
      frequencyEndDate && setFrequencyEndDate(dateFormatter(frequencyEndDate));
      frequencyStartDate && setFrequencyStartDate(dateFormatter(frequencyStartDate));
      stayFrequency && setStayFrequency({ label: stayFrequency, value: stayFrequency });
      membershipStatus && setMembershipStatus({ label: membershipStatus, value: membershipStatus });
      lastStayedStartDate && setLastStayedStartDate(dateFormatter(lastStayedStartDate));
      lastStayedEndDate && setLastStayedEndDate(dateFormatter(lastStayedEndDate));
      notStayedAfterDate && setNotStayedAfterDate(dateFormatter(notStayedAfterDate));
      notStayedAfterMonth && setNotStayedAfterMonth({ label: notStayedAfterMonth, value: notStayedAfterMonth })
    } else {
      setTemplate(null);
      setHostel([]);
      setGuestStatus(null);
      setFromDate(dateFormatter(new Date()));
      setToDate(dateFormatter(new Date()));
      setTimeToSend(new Date());
      setGender("");
      setStayedBefore("");
      setResidingCountry("");
      setResidingState([]);
      setGroupSize([]);
      setBestDescribed([]);
      setModeOfTransport([]);
      setMinimumNoOfNights(null);
      setBookingFrequency(null);
      setCampaignName("");
      setLastStayed(null);
      setAge(null);
      setBookingSource(null);
      setStayDuration(null);
      setDaysToSend([])
      setFromDateOfCheckin(null);
      setToDateOfCheckin(null)
      setFrequencyStartDate(null)
      setFrequencyEndDate(null)
      setStayFrequency(null)
      setMembershipStatus(null)
      setLastStayedStartDate(null)
      setLastStayedEndDate(null)
      setNotStayedAfterDate(null)
      setNotStayedAfterMonth(null)
    }
  }, [editData]);

  const handleGender = (gender) => {
    return setGender(gender);
  };

  const handleGroupSize = (groupSize) => {
    return setGroupSize(groupSize);
  };

  const handleResidingCountry = (country) => {
    return setResidingCountry(country);
  };
  const handleResidingState = (residingState) => {
    return setResidingState(residingState);
  };

  const handleStayedBefore = (stayedBefore) => {
    return setStayedBefore(stayedBefore);
  };

  const handleBestDescribed = (bestDescribed) => {
    return setBestDescribed(bestDescribed);
  };

  const handleModeOfTransport = (modeOfTransport) => {
    return setModeOfTransport(modeOfTransport);
  };

  const handleMinNoOfNights = (noOfNight) => {
    return setMinimumNoOfNights(noOfNight);
  };

  const handleBookingFrequency = (bookingFrequency) => {
    return setBookingFrequency(bookingFrequency);
  };
  const handleLastStayed = (lastStayed) => {
    return setLastStayed(lastStayed);
  };

  const handleAge = (age) => {
    return setAge(age);
  };

  const handleBookingSource = (bookingSource) => {
    return setBookingSource(bookingSource);
  };

  const clearFields = () => {
    setToDateOfCheckin(null)
    setFromDateOfCheckin(null)
  }

  const handleGetCount = () => {
    getMessageRecieverCountHandler(query)
  }

  const setNotStayedAfterDateDate = (month) => {
    const today = new Date();
    today.setMonth(today.getMonth() - month.value);
    setNotStayedAfterDate(dateFormatter(today))
    setNotStayedAfterMonth(month)
  }

  return (
    <SlideModal
      open={open}
      handleToggle={handleToggle}
      submitDataHandler={submitDataHandler}
    >
      <form>
        <Row>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Campaign name
              </Label>
              <Input
                autoFocus
                type="text"
                value={campaignName}
                id="campaignName"
                name="campaignName"
                placeholder="Campaign name"
                onChange={(e) => setCampaignName(e.target.value)}
              />
            </FormGroup>
          </Col>
          {/*// ! Template */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Template *
              </Label>
              <Select
                isClearable={false}
                id="template"
                name="template"
                value={template}
                options={TemplateList}
                onChange={(value) => {
                  setTemplate(value);
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! Template Message */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="templateMessage">
                Template message
              </Label>
              <Input
                type="textarea"
                value={templateMessage}
                id="templateMessage"
                name="templateMessage"
                placeholder="Add Template Message"
                disabled
              />
            </FormGroup>
          </Col>

          {/*// ! Hostel */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="hostel">
                Hostels
              </Label>
              <Select
                onChange={handleChange}
                isClearable={false}
                theme={selectThemeColors}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList}
                className="react-select"
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/*// ! Guest Status */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="guestStatus">
                Guest status
              </Label>
              <Select
                isClearable={false}
                id="guestStatus"
                name="guestStatus"
                value={guestStatus}
                options={GuestStatusList}
                onChange={(value) => {
                  setGuestStatus(value);
                  clearFields();
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>

          {/* // ! fromDate */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="fromDate">
                Message start date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={fromDate}
                onChange={(date) => setFromDate(dateFormatter(new Date(date)))}
                id="fromDate"
                placeholder="Message start Date"
                required
              />
            </FormGroup>
          </Col>

          {/* // ! toDate */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="toDate">
                Message end date
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={toDate}
                onChange={(date) => setToDate(dateFormatter(new Date(date)))}
                id="toDate"
                placeholder="Add To Date"
                required
              />
            </FormGroup>
          </Col>

          {/*// ! Time to Send */}
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="timeToSend">
                Daily send time (24 hrs format)
              </Label>
              <Flatpickr
                className="form-control bg-white border-dark text-dark"
                value={timeToSend}
                onChange={(value) => setTimeToSend(value)}
                id="timeToSend"
                placeholder="Daily send time (24 hrs format)"
                required
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: "H:i",
                  time_24hr: true,
                }}
              />
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup className="w-100">
              <Label className="form-label" for="applicableDays">
                Days to send message
              </Label>
              <Select
                isClearable={false}
                classNamePrefix="select"
                isMulti
                isSearchable={true}
                id="applicableDays"
                name="applicableDays"
                options={daysList}
                value={daysToSend}
                closeMenuOnSelect={false}
                onChange={(value) => {
                  setDaysToSend(value);
                }}
              />
            </FormGroup>
          </Col>

          {guestStatus?.value == "All" ? (
            <Col style={{ backgroundColor: "#00000020" }}>
              {/* Gender  */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3>Customer Segmentation</h3>
                <Button
                  style={{
                    height: "50px",
                    width: "80px"
                  }}
                  onClick={() => {
                    handleGetCount()
                  }}
                >
                  Get count
                </Button>
                {
                  recieverCount?.getCountOfMessageReciever !== undefined && <span>
                    {`count ${recieverCount?.getCountOfMessageReciever}`}
                  </span>
                }

              </div>

              <Col sm="12" style={{ opacity: "100" }}>
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Gender
                  </Label>
                  <Select
                    onChange={(gender) => setGender(gender)}
                    isClearable={true}
                    theme={selectThemeColors}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    isMulti
                    id="gender"
                    name="gender"
                    value={gender}
                    options={genderList}
                    className="react-select"
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>
              {/* Group Size  */}
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Group Size
                  </Label>
                  <Select
                    onChange={handleGroupSize}
                    isClearable={true}
                    theme={selectThemeColors}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    id="groupSize"
                    name="groupSize"
                    value={groupSize}
                    options={groupSizeList}
                    className="react-select"
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>
              {/* Residing Country */}
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Residing country
                  </Label>
                  <Select
                    onChange={handleResidingCountry}
                    isClearable={true}
                    theme={selectThemeColors}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    id="residingCountry"
                    name="residingCountry"
                    value={residingCountry}
                    options={residingCountryList}
                    className="react-select"
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>
              {/* Residing State */}
              {residingCountry?.value == "India" && (
                <Col sm="12">
                  <FormGroup className="w-100">
                    <Label className="form-label" for="hostel">
                      Residing state
                    </Label>
                    <Select
                      onChange={handleResidingState}
                      isClearable={true}
                      theme={selectThemeColors}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      id="residingState"
                      name="residingState"
                      value={residingState}
                      options={indianStateList}
                      className="react-select"
                      classNamePrefix="select"
                    />
                  </FormGroup>
                </Col>
              )}
              {/* have you stayed with thehosteller */}
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Stayed with The Hosteller ?
                  </Label>
                  <Select
                    onChange={handleStayedBefore}
                    isClearable={true}
                    theme={selectThemeColors}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    id="stayedBefore"
                    name="stayedBefore"
                    value={stayedBefore}
                    options={stayedBeforeList}
                    className="react-select"
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>
              {/* what describe you */}
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Profession Type ?
                  </Label>
                  <Select
                    onChange={handleBestDescribed}
                    isClearable={true}
                    theme={selectThemeColors}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    id="bestDescribeyou"
                    name="bestDescribeyou"
                    value={bestDescribed}
                    options={bestDescribedList}
                    className="react-select"
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>
              {/* mode of transportation */}
              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Mode of transport
                  </Label>
                  <Select
                    onChange={handleModeOfTransport}
                    isClearable={true}
                    theme={selectThemeColors}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    id="bestDescribeyou"
                    name="bestDescribeyou"
                    value={modeOfTransport}
                    options={transportOptions}
                    className="react-select"
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Booking Source
                  </Label>
                  <Select
                    onChange={handleBookingSource}
                    isClearable={true}
                    theme={selectThemeColors}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    id="bestDescribeyou"
                    name="bestDescribeyou"
                    value={bookingSource}
                    options={bookingSourceOptions}
                    className="react-select"
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Age
                  </Label>
                  <Select
                    onChange={handleAge}
                    isClearable={true}
                    theme={selectThemeColors}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    id="bestDescribeyou"
                    name="bestDescribeyou"
                    value={age}
                    options={ageOptions}
                    className="react-select"
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="12" style={{ opacity: "100" }}>
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Stay Duration (days greater than or equal to)
                  </Label>
                  <Select
                    onChange={(stayDuration) => setStayDuration(stayDuration)}
                    isClearable={true}
                    theme={selectThemeColors}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    id="stayDuration"
                    name="stayDuration"
                    value={stayDuration}
                    options={oneToThirtyForSelect}
                    className="react-select"
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>

              <Col sm="12" style={{ opacity: "100" }}>
                <FormGroup className="w-100">
                  <Label className="form-label" for="hostel">
                    Membership
                  </Label>
                  <Select
                    onChange={(membership) => setMembershipStatus(membership)}
                    isClearable={true}
                    theme={selectThemeColors}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    id="membershipStatus"
                    name="membershipStatus"
                    value={membershipStatus}
                    options={membershipOptions}
                    className="react-select"
                    classNamePrefix="select"
                  />
                </FormGroup>
              </Col>
              <Col sm="12">
                <SwitchIcon
                  checked={showStayFrequency}
                  id="status"
                  name="status"
                  label="Show stay frequency"
                  handleChecked={(value) => setShowStayFrequency(value)}
                />
              </Col>
              {
                showStayFrequency && <Col>
                  <Col sm="12" style={{ opacity: "100" }}>
                    <FormGroup className="w-100">
                      <Label className="form-label" for="hostel">
                        Checkin range
                      </Label>
                      <Flatpickr
                        className="form-control bg-white border-dark text-dark"
                        value={frequencyStartDate}
                        options={{
                          mode: "range",
                        }}
                        onChange={(date) => {
                          setFrequencyStartDate(dateFormatter(date[0]));
                          setFrequencyEndDate(dateFormatter(date[1]));
                        }}
                        id="frequencyCheckinDate"
                        isClearable={true}
                        placeholder="Date range"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" style={{ opacity: "100" }}>
                    <FormGroup className="w-100">
                      <Label className="form-label" for="hostel">
                        Checkin frequency
                      </Label>
                      <Select
                        onChange={(frequency) => setStayFrequency(frequency)}
                        isClearable={true}
                        theme={selectThemeColors}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        id="CheckinFrequency"
                        name="CheckinFrequency"
                        value={stayFrequency}
                        options={stayFrequencyList}
                        className="react-select"
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>
                </Col>
              }

              {
                !showStayFrequency && <Col>
                  <Col sm="12" style={{ opacity: "100" }}>
                    <FormGroup className="w-100">
                      <Label className="form-label" for="hostel">
                        Last stayed start date
                      </Label>
                      <Flatpickr
                        className="form-control bg-white border-dark text-dark"
                        value={lastStayedStartDate}
                        options={{
                          mode: "range",
                        }}
                        onChange={(date) => {
                          setLastStayedStartDate(dateFormatter(date[0]));
                          setLastStayedEndDate(dateFormatter(date[1]));
                        }}
                        id="frequencyCheckinDate"
                        isClearable={true}
                        placeholder="Date range"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" style={{ opacity: "100" }}>
                    <FormGroup className="w-100">
                      <Label className="form-label" for="hostel">
                        Months stayed bofore
                      </Label>
                      <Select
                        onChange={(month) => setNotStayedAfterDateDate(month)}
                        isClearable={true}
                        theme={selectThemeColors}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        id="CheckinFrequency"
                        name="CheckinFrequency"
                        value={notStayedAfterMonth}
                        options={stayFrequencyList}
                        className="react-select"
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>
                </Col>
              }

              {/* <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="hostel">
                When did you last stayed with us ?
                </Label>
                <Select
                  onChange={handleLastStayed}
                  isClearable={true}
                  theme={selectThemeColors}
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  isMulti
                  id="bestDescribeyou"
                  name="bestDescribeyou"
                  value={lastStayed}
                  options={whenYouLastStayed}
                  className="react-select"
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col> */}

              {/* <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="hostel">
                  Minimum number of nights
                </Label>
                <Select
                  onChange={handleMinNoOfNights}
                  isClearable={false}
                  theme={selectThemeColors}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  id="minimumNumberOfNights"
                  name="minimumNumberOfNights"
                  value={minimumNoOfNights}
                  options={oneToFiftyCountForSelect}
                  className="react-select"
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col> */}

              {/* <Col sm="12">
              <FormGroup className="w-100">
                <Label className="form-label" for="hostel">
                  Booking frequency
                </Label>
                <Select
                  onChange={handleBookingFrequency}
                  isClearable={false}
                  theme={selectThemeColors}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  id="bookingFrequency"
                  name="bookingFrequency"
                  value={bookingFrequency}
                  options={oneToFiftyCountForSelect}
                  className="react-select"
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col> */}
            </Col>
          ) : guestStatus?.value === "Pre-Arrival" && (
            <Col sm="12">
              <FormGroup className="w-100">
                <p style={{ display: "flex", justifyContent: "space-between" }}>
                  <Label className="form-label" for="startDateOfJoining">
                    Checkin date range *
                  </Label>
                  <span>
                    {`count ${recieverCount?.getCountOfMessageReciever}`}
                  </span>
                </p>
                <Flatpickr
                  className="form-control bg-white border-dark text-dark"
                  id="doj"
                  placeholder="Select joining date range"
                  options={{
                    mode: "range",
                  }}
                  value={[fromDateOfCheckin, toDateOfCheckin]}
                  onChange={(date) => {
                    setFromDateOfCheckin(dateFormatter(date[0]));
                    setToDateOfCheckin(dateFormatter(date[1]));
                  }}
                />
              </FormGroup>
            </Col>
          )}

          {/* // ! Logs */}
          {editData?.length && editData[0]?.logs && (
            <Col sm="12">
              {/* <hr/> */}
              <FormGroup className="w-100 mt-2">
                <Label className="form-label" for="logs">
                  <strong>Logs:</strong>
                </Label>

                <br />
                {editData[0]?.logs
                  ?.map((log, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Label className="form-label" for="name">
                          <li>{log}.</li>
                        </Label>
                        <br />
                      </React.Fragment>
                    );
                  })
                  .reverse()}
              </FormGroup>
            </Col>
          )}
        </Row>
      </form>
    </SlideModal>
  );
};

export default AddEditModal;
