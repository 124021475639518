import React, { useEffect, useState } from "react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useGetHostelsNameandId } from "../../../api/dataSource";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import LaundryTrackerTable from './LaundryTrackerTable'
import { useGetLaundryMasterDataById } from "../../../api/LaundryMaster/Queries";
import { useGetLaundryTrackerByHostelId } from "../../../api/LaundryTracker/Queries";

const YearList = [
  { label: "2025", value: "2025" },
  { label: "2024", value: "2024" },
  { label: "2023", value: "2023" },
];
const MonthsList = [
  { label: "January", value: "1" },
  { label: "February ", value: "2" },
  { label: "March", value: "3" },
  { label: "April ", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

const LaundryTracker = () => {
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [year, setYear] = useState({ label: "2025", value: "2025" });
  const [month, setMonth] = useState({
    label: MonthsList.find((i) => Number(i.value) === new Date().getMonth() + 1)
      .label,
    value: new Date().getMonth() + 1,
  });
  const [selectedHostel, setSelectedHostel] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [tableHeaderData, setTableHeaderData] = useState([]);
  const { data: allHostelsData } = useGetHostelsNameandId();
  const [dateArray, setDateArray] = useState([]);
  const [id, setId] = useState(null);
  const [editData, setEditData] = useState(null);
  const { data: singleLaundryData, refetch: refetchSingleLaundryData } = useGetLaundryMasterDataById(id);
  const { data: laundryTrackerData, refetch: refetchLaundryTrackerData } = useGetLaundryTrackerByHostelId(id);

  useEffect(() => {
    if (id) {
      refetchSingleLaundryData();
      refetchLaundryTrackerData(); 
    }
  }, [id, refetchSingleLaundryData, refetchLaundryTrackerData]);
  
  useEffect(() => {
    if (singleLaundryData?.getLaundryMasterDataById) {
      setEditData(singleLaundryData.getLaundryMasterDataById);
    } else if (id) {
      setEditData(null);
    }
  }, [singleLaundryData, id]);
  
  useEffect(() => {
    if (laundryTrackerData?.getLaundryTrackerDataByHostelId) {
      setEditData(laundryTrackerData.getLaundryTrackerDataByHostelId);
    } else if (id) {
      setEditData(null);
    }
  }, [laundryTrackerData, id]);

  const getDatesOfMonth = (year, month) => {
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 0);
    const currentDateOfMonth = new Date(startDate);
    var datesArray = [];
    while (currentDateOfMonth <= endDate) {
      datesArray.push({
        label: currentDateOfMonth.toLocaleDateString("en-CA"),
        key: currentDateOfMonth.toLocaleDateString("en-CA"),
      });
      currentDateOfMonth.setDate(currentDateOfMonth.getDate() + 1);
    }

    setTableHeaderData([
      { label: "Item", key: "item" },
      { label: "Unit Rate", key: "unitRate" },
      ...datesArray, 
    ]);
    setDateArray(datesArray);
  };

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelsNameandId?.map((hostel) => ({
      label: hostel?.name,
      value: hostel?._id,
    }));
    if (HostelsList?.length) {
      const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
      const filteredHostel =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return HostelsList?.find((hostel) => hostel?.value === hostelID);
            })
          : HostelsList;
      setHostelsList(filteredHostel);
    }
  }, [allHostelsData]);

  const submitHandler = (year, month) => {
    getDatesOfMonth(year, month);
    setSelectedHostel(hostel?.value); 
    setSelectedMonth(month);
    setSelectedYear(year);
    setId(hostel?.value);
  };

  const handleReset = () => {
    setHostel(null); 
    setYear({ label: "2025", value: "2025" }); 
    setMonth({
      label: MonthsList.find((i) => Number(i.value) === new Date().getMonth() + 1)
        .label,
      value: new Date().getMonth() + 1,
    });
    setTableHeaderData([]); 
    setSelectedHostel(null); 
    setSelectedMonth(null); 
    setSelectedYear(null);
    setDateArray([]); 
    setId(null); 
  };
  
  return (
    <div>
      <Row>
        <Col sm="3">
          <FormGroup className="w-100">
            <Label className="form-label" for="product">
              Hostel
            </Label>
            <Select
              isClearable={true}
              isSearchable={true}
              theme={selectThemeColors}
              id="hostel"
              name="hostel"
              value={hostel}
              options={HostelsList}
              onChange={(value) => {
                setHostel(value);
              }}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="year">
              Year
            </Label>
            <Select
              isSearchable={true}
              id="year"
              name="year"
              options={YearList}
              required
              value={year}
              onChange={(value) => setYear(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="2">
          <FormGroup className="w-100">
            <Label className="form-label" for="month">
              Months
            </Label>
            <Select
              isSearchable={true}
              id="month"
              name="month"
              options={MonthsList}
              required
              value={month}
              onChange={(value) => setMonth(value)}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>
        <Col sm="2 p-2">
          <Button.Ripple
            type="submit"
            color={`${hostel ? `light` : `secondary`}`}
            appearance="success"
            onClick={() => {
              submitHandler(Number(year?.value), Number(month?.value));
            }}
          >
            Search
          </Button.Ripple>

          <Button.Ripple
            style= {{ marginLeft: "20px" }}
            color="danger"
            type="button"
            onClick={handleReset}
          >
            Reset
          </Button.Ripple>
        </Col>

      </Row>

        <LaundryTrackerTable
          tableHeaderData={tableHeaderData}
          dateArray={dateArray}
          allHostelsData={allHostelsData}
          setId={setId}
          masterData={singleLaundryData?.getLaundryMasterDataById}
          data={editData}
          refetch={refetchLaundryTrackerData}
        />
    </div>
  )
};

export default LaundryTracker;
