import React, { useState } from "react";
import { FileText, PlusCircle, MinusCircle } from "react-feather";
import { Card, CardHeader, Button, Table } from "reactstrap";
import { useGetAggregatedPLStatement } from "./../../api/P&LStatement/Queries"; // Import the hook

const sections = [
  {
    name: "Hostel Details",
    fields: ["Hostel Name", "Area Manager", "Op. Model"],
  },
  {
    name: "Revenue",
    fields: ["Occ%", "ADR", "Bed Revenue", "F&B Revenue", "Ancillary Revenue"],
  },
  {
    name: "Total Revenue",
    fields: ["Total Revenue"],
  },
  {
    name: "Expenses",
    fields: ["Total OPEX"],
  },
  {
    name: "Margin Analysis",
    fields: [
      "Overall Op. Margin (in INR)",
      "Analysis (Operational Margin %)",
      "Hostel margin (in INR)",
      "Hostel margin (in %)",
      "F&B margin (in INR)",
      "F&B margin (in %)",
      "Ancillary margin (in INR)",
      "Ancillary margin (in %)",
    ],
  },
];

const percentageFields = [
  "Occ%",
  "Hostel margin (in %)",
  "F&B margin (in %)",
  "Ancillary margin (in %)",
  "Analysis (Operational Margin %)",
];

const formatPercentage = (value) => {
  const numValue = parseFloat(value);
  if (!isNaN(numValue)) {
    return numValue >= 1
      ? `${numValue.toFixed(2)}%`
      : `${(numValue * 100).toFixed(2)}%`;
  }
  return "N/A";
};

const formatNumber = (value) => {
  const numValue = parseFloat(value);
  if (isNaN(numValue)) return "N/A";

  if (numValue > 100) {
    return Math.round(numValue).toLocaleString("en-IN");
  } else {
    return numValue.toFixed(2);
  }
};

const formatAggregatedValue = (value) => {
  const numValue = parseFloat(value);
  if (isNaN(numValue)) return "N/A";

  if (percentageFields.includes(value)) {
    return formatPercentage(numValue);
  }

  if (numValue > 100) {
    return Math.round(numValue).toLocaleString("en-IN");
  } else {
    return numValue.toFixed(2);
  }
};

// const formatAggregatedValue = (value) => {
//   const numValue = parseFloat(value);
//   if (isNaN(numValue)) return "N/A";

//   if (percentageFields.includes(value)) {
//     return formatPercentage(numValue);
//   }

//   return numValue > 100 ? Math.round(numValue) : numValue.toFixed(2);
// };

// const formatNumber = (value) => {
//   const numValue = parseFloat(value);
//   if (isNaN(numValue)) return "N/A";

//   if (numValue > 100) {
//     return Math.round(numValue);
//   } else {
//     return numValue.toFixed(2);
//   }
// };

const extractFieldValue = (fields, fieldName) => {
  if (!fields || !Array.isArray(fields)) return "N/A";
  const field = fields.find((item) => item.fieldName === fieldName);
  if (!field) return "N/A";

  let value =
    field.fieldValue !== undefined && field.fieldValue !== null
      ? field.fieldValue
      : "N/A";

  if (value !== "N/A") {
    if (percentageFields.includes(fieldName)) {
      return formatPercentage(value);
    } else {
      return formatNumber(value);
    }
  }

  return value;
};

const PLStatementTable = ({ data, openOverviewModal, filterFields }) => {
  const { data: aggregatedData } = useGetAggregatedPLStatement(filterFields);
  const [expandedSections, setExpandedSections] = useState({});

  const toggleSection = (sectionName, fieldsCount) => {
    if (fieldsCount > 1) {
      setExpandedSections((prev) => ({
        ...prev,
        [sectionName]: !prev[sectionName],
      }));
    }
  };

  const renderHeader = (section) => (
    <th
      colSpan={
        expandedSections[section.name] || section.fields.length === 1
          ? section.fields.length
          : 2
      }
      style={{ textAlign: "center", fontWeight: "bold" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 5px",
        }}
      >
        <span>{section.name}</span>
        {section.fields.length > 1 && (
          <Button
            color="link"
            size="sm"
            onClick={() => toggleSection(section.name, section.fields.length)}
            style={{ padding: 0, fontSize: "18px", cursor: "pointer" }}
          >
            {expandedSections[section.name] ? (
              <MinusCircle size={20} color="green" />
            ) : (
              <PlusCircle size={20} color="blue" />
            )}
          </Button>
        )}
      </div>
    </th>
  );

  const excludedFields = [
    "Hostel Name",
    "Area Manager",
    "Op. Model",
    "Month",
    "Year",
  ];

  const renderSubHeaders = (section) =>
    expandedSections?.[section.name] || section.fields.length === 1 ? (
      section.fields.map((subHeader, index) => (
        <th key={index}>
          {subHeader}
          {/* Display aggregated value */}

          {aggregatedData?.getAggregatedPLStatement &&
            !excludedFields.includes(subHeader) && (
              <div style={{ fontWeight: "bold", marginTop: "5px" }}>
                {formatAggregatedValue(
                  aggregatedData?.getAggregatedPLStatement?.calculatedValues?.[
                    subHeader
                  ] ??
                    aggregatedData?.getAggregatedPLStatement
                      ?.totalAggregatedValues?.[subHeader] ??
                    "N/A"
                )}
              </div>
            )}
        </th>
      ))
    ) : (
      <>
        <th>
          {section.fields[0]}
          {/* Aggregated value for first field */}
          {aggregatedData?.getAggregatedPLStatement &&
            !excludedFields.includes(section.fields[0]) && (
              <div style={{ fontWeight: "bold", marginTop: "5px" }}>
                {aggregatedData?.getAggregatedPLStatement?.calculatedValues?.[
                  section.fields[0]
                ] ??
                  aggregatedData?.getAggregatedPLStatement
                    ?.totalAggregatedValues?.[section.fields[0]] ??
                  "N/A"}
              </div>
            )}
        </th>
        <th>
          {section.fields[1]}
          {/* Aggregated value for second field */}
          {aggregatedData?.getAggregatedPLStatement &&
            !excludedFields.includes(section.fields[1]) && (
              <div style={{ fontWeight: "bold", marginTop: "5px" }}>
                {aggregatedData?.getAggregatedPLStatement?.calculatedValues?.[
                  section.fields[1]
                ] ??
                  aggregatedData?.getAggregatedPLStatement
                    ?.totalAggregatedValues?.[section.fields[1]] ??
                  "N/A"}
              </div>
            )}
        </th>
      </>
    );

  const renderRowData = (item, section) =>
    expandedSections[section.name] || section.fields.length === 1 ? (
      section.fields.map((fieldName, index) => (
        <td key={index}>
          {fieldName === "Hostel Name"
            ? item.hostelName
            : fieldName === "Area Manager"
            ? item.areaManager
            : fieldName === "Op. Model"
            ? item.operationalModel
            : extractFieldValue(item?.fields ?? [], fieldName)}
        </td>
      ))
    ) : (
      <>
        <td>
          {section.fields[0] === "Hostel Name"
            ? item.hostelName
            : section.fields[0] === "Area Manager"
            ? item.areaManager
            : section.fields[0] === "Op. Model"
            ? item.operationalModel
            : extractFieldValue(item.fields, section.fields[0])}
        </td>
        {section.fields.length > 1 && (
          <td>
            {section.fields[1] === "Hostel Name"
              ? item.hostelName
              : section.fields[1] === "Area Manager"
              ? item.areaManager
              : section.fields[1] === "Op. Model"
              ? item.operationalModel
              : extractFieldValue(item.fields, section.fields[1])}
          </td>
        )}
      </>
    );

  return (
    <div>
      <Card>
        <CardHeader>
          <Button.Ripple
            color="light"
            onClick={(e) => {
              e.preventDefault();
              openOverviewModal();
            }}
          >
            Create P&L Statement
          </Button.Ripple>
        </CardHeader>
      </Card>

      <Table bordered responsive>
        <thead>
          <tr>
            <th style={{ textAlign: "center", fontWeight: "bold" }}>Action</th>
            <th style={{ textAlign: "center", fontWeight: "bold" }}>
              Month/Year
            </th>
            {sections.map((section) => renderHeader(section))}
          </tr>
          <tr>
            <th></th>
            <th></th>
            {sections.map((section) => renderSubHeaders(section))}
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item._id}>
              <td>
                <Button
                  color="primary"
                  size="sm"
                  onClick={() => openOverviewModal(item._id)}
                >
                  <FileText size={14} />
                </Button>
              </td>
              <td>{`${item.month}/${item.year}`}</td>
              {sections.map((section) => renderRowData(item, section))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default PLStatementTable;
