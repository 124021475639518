import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
} from "reactstrap";
import Select from "react-select";
import { useUploadPLStatements } from "../../api/P&LStatement/Mutation";
import { useGetPaginatedPLStatements } from "../../api/P&LStatement/Queries";
import { useHostelList } from "../../api/dataSource";
import { selectThemeColors } from "@utils";
import toaster from "@components/common/Toaster";
import TablePagination from "../../@core/components/common/TablePagination";
import PLStatementTable from "./PLStatamentTable";
import PLStatementOverviewModal from "./PLStatementOverviewModal";
import { useExportPLStatement } from "../../api/P&LStatement/Mutation";

const months = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

const years = Array.from({ length: 10 }, (_, i) => {
  const year = new Date().getFullYear() - i;
  return { value: year, label: year.toString() };
});

const operationalModelDropdown = [
  { label: "FOFO", value: "FOFO" },
  { label: "FOCO", value: "FOCO" },
  { label: "COCO", value: "COCO" },
  { label: "OMC", value: "OMC" },
];

const opMarginOptions = [
  { label: "Positive", value: "positive" },
  { label: "Negative", value: "negative" },
  { label: "Zero", value: "zero" },
];

const PLStatementComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadPLStatementsHandler, { loading, error, data: uploadData }] =
    useUploadPLStatements();

  const [page, setPage] = useState(1);
  const limit = 10;
  const [id, setId] = useState("");
  const [editData, setEditData] = useState(null);
  const [openTableModal, setOpenTableModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [hostelIdArray, setHostelIdArray] = useState([]);
  const [HostelsList, setHostelsList] = useState("");
  const { data: allHostelsData } = useHostelList();
  const [monthFilter, setMonthFilter] = useState(null);
  const [yearFilter, setYearFilter] = useState(null);
  const [filterFields, setFilterFields] = useState({});
  const [operationalModel, setOperationalModel] = useState([]);
  const [overviewModalOpen, setOverviewModalOpen] = useState(false);
  const handleOverviewOpen = (id) => {
    const dataItem = tableData.find((item) => item._id === id);
    setEditData(dataItem || null);
    setOverviewModalOpen(true);
  };
  const [opMarginFilter, setOpMarginFilter] = useState(null);

  const [exportPLStatementHandler, { data: exportedPLData }] =
    useExportPLStatement();

  const exportInputFilter = {
    filterFields,
  };

  useEffect(() => {
    if (exportedPLData?.exportPLStatement?.data) {
      if (exportedPLData?.exportPLStatement?.data.length > 0) {
        const byteArray = new Uint8Array(
          exportedPLData?.exportPLStatement?.data
        );
        const blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `P&L_Export.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  }, [exportedPLData]);

  const [areaManager, setAreaManager] = useState("");

  const { data, refetch } = useGetPaginatedPLStatements(
    limit,
    page,
    filterFields
  );

  const handleSearch = () => {
    setPage(1);
    setFilterFields({
      ...filterFields,
      hostelIds: hostelIdArray?.map((hostel) => hostel.value),
      month: monthFilter?.map((month) => month.value),
      year: yearFilter?.map((year) => year.value),
      areaManager: areaManager?.trim(),
      operationalModel: operationalModel?.map((model) => model.value),
      opMarginFilter: opMarginFilter?.value,
    });
  };

  const handleReset = () => {
    setFilterFields({});
    setHostelIdArray(null);
    setMonthFilter([]);
    setYearFilter([]);
    setAreaManager("");
    setOperationalModel([]);
    // setOpenTableModal([]);
    setOpMarginFilter([]);
  };

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  // Set table data and calculate total pages from API response
  useEffect(() => {
    if (data?.getPaginatedPLStatement?.data) {
      setTableData(data?.getPaginatedPLStatement?.data);
    }
  }, [data]);

  const totalPages = Math.ceil(
    (data?.getPaginatedPLStatement?.count || 0) / limit
  );

  // Refetch data when page or filterFields change
  useEffect(() => {
    refetch();
  }, [page, filterFields, refetch]);

  const clearForm = () => {
    setSelectedMonth(null);
    setSelectedYear(null);
    setFile(null);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (modalOpen) clearForm();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (uploadData?.uploadPLStatements?.success) {
      toaster("success", `${uploadData.uploadPLStatements.message}`);
      refetch();
      clearForm();
      toggleModal();
    } else if (uploadData?.uploadPLStatements?.success === false) {
      toaster("error", "Upload failed: No success in response.");
    }
  }, [uploadData, refetch]);

  const handleUpload = async () => {
    if (!selectedMonth || !selectedYear || !file) {
      toaster("error", "All fields are required. Please fill them.");
      return;
    }

    const plInput = {
      month: selectedMonth.value,
      year: selectedYear.value,
      file,
    };

    try {
      await uploadPLStatementsHandler(plInput);
    } catch (err) {
      toaster("error", `Upload failed: ${err.message}`);
    }
  };

  return (
    <div>
      {/* Upload Button */}
      <Row>
        <Col sm="3">
          <FormGroup>
            <Label for="hostel">
              <strong>Hostel Name</strong>
            </Label>
            <Select
              isClearable
              isSearchable
              theme={selectThemeColors}
              isMulti
              id="hostel"
              value={hostelIdArray}
              options={HostelsList}
              onChange={setHostelIdArray}
              classNamePrefix="select"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="monthFilter">
              <strong>Month</strong>
            </Label>
            <Select
              id="monthFilter"
              isMulti
              theme={selectThemeColors}
              options={months}
              value={monthFilter}
              onChange={setMonthFilter}
              placeholder="Select Month"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="yearFilter">
              <strong>Year</strong>
            </Label>
            <Select
              id="yearFilter"
              isMulti
              theme={selectThemeColors}
              options={years}
              value={yearFilter}
              onChange={setYearFilter}
              placeholder="Select Year"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="operationalModel">
              <strong>Operational Model</strong>
            </Label>
            <Select
              id="operationalModel"
              isMulti
              theme={selectThemeColors}
              // value={operationalModelDropdown}
              value={operationalModel}
              options={operationalModelDropdown}
              onChange={setOperationalModel}
              placeholder="Operational Model"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="yearFilter">
              <strong>Area Manager</strong>
            </Label>
            <Input
              id="areaManager"
              type="text"
              value={areaManager}
              onChange={(e) => setAreaManager(e.target.value)}
              className="w-full border rounded px-2 py-1"
              placeholder="Enter Area Manager Name"
            />
          </FormGroup>
        </Col>

        <Col sm="3">
          <FormGroup>
            <Label for="operationalModel">
              <strong>Operational Margin</strong>
            </Label>
            <Select
              options={opMarginOptions}
              value={opMarginFilter}
              onChange={setOpMarginFilter}
              placeholder="Filter by Op. Margin"
              isClearable
            />
          </FormGroup>
        </Col>

        <Button
          style={{
            backgroundColor: "#ffe700",
            height: "40px",
            marginTop: "22px",
            borderRadius: "5px",
            padding: "10px 40px",
            marginLeft: "30px",
          }}
          color="white"
          onClick={() => {
            handleSearch();
          }}
        >
          Search
        </Button>

        <Button
          style={{
            backgroundColor: "#ff9999",
            height: "40px",
            marginTop: "22px",
            marginLeft: "30px",
            borderRadius: "5px",
            padding: "10px 40px",
          }}
          color="danger"
          onClick={() => {
            handleReset();
          }}
        >
          Reset
        </Button>

        <Button
          style={{
            backgroundColor: "#ff9999",
            height: "40px",
            marginTop: "22px",
            marginLeft: "40px",
            borderRadius: "5px",
            padding: "10px 40px",
            outline: "none",
            border: "0",
          }}
          color="success"
          appearance="success"
          type="button"
          onClick={() => exportPLStatementHandler(exportInputFilter)}
        >
          Export
        </Button>
      </Row>

      <Row>
        <Col className="mb-2">
          <Button
            color="info"
            style={{
              backgroundColor: "#ffe700",
              height: "40px",
              marginTop: "22px",
              borderRadius: "5px",
              padding: "10px 40px",
            }}
            onClick={toggleModal}
          >
            Upload P&L Statement
          </Button>
        </Col>
      </Row>

      {/* Card with Header */}
      <Card>
        <CardHeader>
          <h4>P&L Statement - Finance</h4>
        </CardHeader>
      </Card>

      {/* Modal */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Upload P&L Statement</ModalHeader>
        <ModalBody>
          <Form>
            {/* Month Dropdown */}
            <FormGroup>
              <Label for="month">Select Month</Label>
              <Select
                id="month"
                theme={selectThemeColors}
                options={months}
                value={selectedMonth}
                onChange={setSelectedMonth}
                placeholder="Choose a month"
              />
            </FormGroup>

            {/* Year Dropdown */}
            <FormGroup>
              <Label for="year">Select Year</Label>
              <Select
                id="year"
                theme={selectThemeColors}
                options={years}
                value={selectedYear}
                onChange={setSelectedYear}
                placeholder="Choose a year"
              />
            </FormGroup>

            {/* File Upload */}
            <FormGroup>
              <Label for="file">Upload File</Label>
              <Input type="file" id="file" onChange={handleFileChange} />
            </FormGroup>

            {/* Submit Button */}
            <FormGroup>
              <Button color="primary" onClick={handleUpload} disabled={loading}>
                {loading ? "Uploading..." : "Upload"}
              </Button>
            </FormGroup>

            {/* Error Handling */}
            {error && <div className="text-danger mt-2">{error.message}</div>}
          </Form>
        </ModalBody>
      </Modal>

      <PLStatementTable
        openTableModal={() => setOpenTableModal(!openTableModal)}
        setId={setId}
        data={tableData}
        openOverviewModal={handleOverviewOpen}
        editData={editData}
        refetch={refetch}
        filterFields={filterFields}
      />

      {tableData.length > 0 && (
        <TablePagination setPage={setPage} page={page} totalPage={totalPages} />
      )}

      <PLStatementOverviewModal
        isOpen={overviewModalOpen}
        toggle={() => setOverviewModalOpen(!overviewModalOpen)}
        editData={editData}
        refetch={refetch}
      />
    </div>
  );
};

export default PLStatementComponent;
