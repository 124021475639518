import { gql, useLazyQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";


export const GET_METER_TRACKER_FROM_HOSTEL_ID = gql`
   query getMeterTrackerFromHostelId($hostelId:ID,$fromDate:Date,$toDate:Date) {
    getMeterTrackerFromHostelId(hostelId:$hostelId,fromDate:$fromDate,toDate:$toDate){

        meterTrackerArray{
  _id
  hostel
  meterMasterId
  meter_CA_Number
  readingDate
  readingUnits
  logs
  createdAt
        }
        previousDayReadingArray{
            readingDates
            totalUnits
        }
     

    }
   }
`


export const useGetMeterTrackerFromHostelId = () => {
    const [fetchTracker, { loading, data, error, refetch }] = useLazyQuery(GET_METER_TRACKER_FROM_HOSTEL_ID)


    useLoadingHandler(loading)

    const fetchTrackerHandler = (hostelId, fromDate, toDate) => {
        fetchTracker({
            variables: {
                hostelId,
                fromDate,
                toDate
            }
            ,
            fetchPolicy: "network-only"
        })
    }

    return [fetchTrackerHandler, { data, error, refetch }]
}