import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useLoadingHandler } from "../../dataSource";

export const GET_EZEE_MEAL_REPORT = gql`
  query getEzeeMealReport($filter: filter) {
    getEzeeMealReport(filter: $filter) {
      data{
        _id
        Room
        ReservationNo
        GuestName
        RoomNo
        hostelName
        ArrivalDate
        DepartureDate
        mealAddedInEzee
        RatePlan
      }
      count
      page
      limit
    }
  }
`;


export const GET_BOOKING_FROM_RESERVATIONID = gql`
  query getEzeeBookingFromReservationId($reservationID:String){
     getEzeeBookingFromReservationId(reservationID:$reservationID){
       error
       message
       status
       _id
     }
  }
`


export const useGetBookingFromReservationID = () => {
  const [handleFetch, { loading, error, data, refetch }] = useLazyQuery(GET_BOOKING_FROM_RESERVATIONID)

  useLoadingHandler(loading)

  const fetchHandler = (reservationID
  ) => {
    handleFetch({
      variables: {
        reservationID
      }
    })
  }


  return [fetchHandler, { error, data, refetch }]
}