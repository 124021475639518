import React, { useEffect, useState } from "react";
import TablePagination from "../../../@core/components/common/TablePagination";
import AddEditModal from "./AddEditModal";
import MeterMasterTable from "./MeterMasterTable";
import { useGetPaginatedMeterMaster } from "../../../api/MeterMaster/Queries";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { selectThemeColors } from "@utils";
import Select from "react-select";
import { useHostelList } from "../../../api/dataSource";

const MeterMaster = () => {
    const [tableData, setTableData] = useState([]);
    const [editData, setEditData] = useState(null);
    const [id, setId] = useState("");
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [hostelList, setHostelList] = useState([])
    const [hostel, setHostel] = useState("")
    const [filterFeilds, setFilterFeilds] = useState({})



    const { data, refetch } = useGetPaginatedMeterMaster(limit, page, filterFeilds);

    const { data: allHostelsData } = useHostelList();


    useEffect(() => {

        let HostelsList = allHostelsData?.getHostelList?.map((hostel) => ({
            label: hostel?.name,
            value: hostel?._id,
        }));

        if (HostelsList?.length) {

            const allowedHostels = JSON.parse(localStorage.getItem("hostels"));


            const filteredHostel =
                allowedHostels !== null && allowedHostels?.length !== 0
                    ? allowedHostels?.map((hostelID) => {
                        return HostelsList?.find((hostel) => hostel?.value === hostelID);
                    })
                    : HostelsList;


            setHostelList(filteredHostel);
        }
    }, [allHostelsData]);

    const toggleModal = () => {
        setOpenModal(!openModal);
        setId(null);
        setEditData(null);
    };
    useEffect(() => {
        if (data?.getPaginatedMeterMaster?.data?.length > 0) {
            setTableData(data?.getPaginatedMeterMaster?.data);
        } else {
            setTableData([]);
        }
        setTotalPage(Math.ceil(data?.getPaginatedMeterMaster?.count / limit));
    }, [
        data?.getPaginatedMeterMaster?.count,
        data?.getPaginatedMeterMaster?.data,
        limit,
    ]);

    useEffect(() => {
        if (id && data?.getPaginatedMeterMaster?.data?.length > 0) {
            let findData = data?.getPaginatedMeterMaster?.data?.find(
                (item) => item?._id === id
            );
            setEditData(findData);
            setId(id);
        } else {
            setId(null);
            setEditData(null);
        }
    }, [data?.getPaginatedMeterMaster?.data, id]);

    const handleSearch = () => {
        const filterFeilds = {
            hostel: hostel?.map((el) => el?.value)
        };
        setPage(1);
        setFilterFeilds(filterFeilds);
    }



    const handleReset = () => {
        setFilterFeilds({});
        setHostel(null);
        setPage(1);
    };

    return (
        <div>
            <Row>
                <Col sm="3">
                    <FormGroup className="w-100">
                        <Label className="form-label" for="hostel">
                            <strong>Hostel</strong>
                        </Label>
                        <Select
                            isClearable={true}
                            isSearchable={true}
                            theme={selectThemeColors}
                            isMulti
                            id="hostel"
                            name="hostel"
                            value={hostel}
                            closeMenuOnSelect={false}
                            options={hostelList}
                            onChange={(value) => {
                                setHostel(value);
                            }}
                            classNamePrefix="select"
                        />
                    </FormGroup>
                </Col>
                <Col className="" sm="3" style={{ display: "flex", gap: "20px" }}>
                    <Button
                        style={{
                            backgroundColor: "#ffe700",
                            height: "40px",
                            marginTop: "22px",
                            borderRadius: "5px",
                            padding: "10px 30px",
                            outline: "none",
                            border: "0",
                        }}
                        color="white"
                        appearance="primary"
                        type="button"
                        onClick={handleSearch}
                    >
                        Search
                    </Button>

                    <Button
                        style={{
                            backgroundColor: "#ff9999",
                            height: "40px",
                            marginTop: "22px",
                            //marginLeft: "40px",
                            borderRadius: "5px",
                            padding: "10px 30px",
                            outline: "none",
                            border: "0",
                        }}
                        color="danger"
                        appearance="primary"
                        type="button"
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                </Col>
            </Row>
            <MeterMasterTable
                openModal={() => setOpenModal(!openModal)}
                data={tableData}
                setId={setId}
            />
            <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
            <AddEditModal
                setId={setId}
                open={openModal}
                editData={editData}
                handleToggle={toggleModal}
                refetch={refetch}
                allHostelList={allHostelsData}
            />
        </div>
    )
};

export default MeterMaster;
