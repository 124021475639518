import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { dateFormatterDDMMYYYY } from "../../../utility/Utils";

const LogModal = ({ meterTrackerArray, date, showModal, onClose }) => {
  const [filteredDate, setFilteredDate] = useState([]);
  const handleClose = () => {
    try {
      onClose(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (meterTrackerArray?.length > 0) {
      const filterData = meterTrackerArray
        ?.filter(
          (data) => dateFormatterDDMMYYYY(data?.readingDate) === date?.date
        )
        ?.map((el) => el?.logs)
        ?.flat();

      setFilteredDate(filterData);
    }
  }, []);
  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader
        className="bg-transparent"
        toggle={handleClose}
        style={{ boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)" }}
      >
        Logs
      </ModalHeader>

      <ModalBody style={{ padding: "20px 40px" }}>
        {filteredDate?.map((el) => {
          return <p>{el}</p>;
        })}
      </ModalBody>
    </Modal>
  );
};

export default LogModal;
