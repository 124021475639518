import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_LAUNDRY_MASTER_PAGGINATION = gql`
  query ($filter: LaundryFilter) {
    getPagginatedLaundryMaster(filter: $filter) {
      data {
        _id
        hostelId{
          _id ,name
        }
        laundryVendorName
        mobileNumber
        address
        billPaymentDueDay
        laundryCycle
        itemRates
        {
          itemName
          itemRate
        }
        laundryAmountPerBedNight
        logs
      }
      limit
      page
      count
    }
  }
`;

export const useGetLaundryMasterPaggintion = (
  limit,
  page,
  hostelIds
) => {
  const { loading, error, data, refetch } = useQuery(GET_LAUNDRY_MASTER_PAGGINATION, {
    variables: {
      filter: {
        limit,
        page,
        hostelIds
      },
    },
  });

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const GET_LAUNDRY_MASTER_DATA_BY_ID = gql`
  query ($id: ID) {
    getLaundryMasterDataById(id: $id) {
      _id
      hostelId{
        _id, name
      }
      laundryVendorName
      mobileNumber
      address
      billPaymentDueDay
      laundryCycle
      itemRates
        {
          itemName
          itemRate
        }
      
      laundryAmountPerBedNight
      logs
    }
  }
`;

export const useGetLaundryMasterDataById = (id) => {
  const { loading, error, data, refetch } = useQuery(
    GET_LAUNDRY_MASTER_DATA_BY_ID,
    {
      variables: {
        id,
      },
    }
  );
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};

export const GET_LAUNDRY_MASTER_BY_HOSTELID = gql`
  query ($hostelId: [ID!]) {
    getLaundryMasterDataByHostelId(hostelId: $hostelId) {
      _id
      hostelId{
        _id
        name
      }
      laundryVendorName
      mobileNumber
      address
      billPaymentDueDay
      laundryCycle
      laundryAmountPerBedNight
      logs
    }
  }
`;

export const useGetLaundryMasterByHostelId = (hostelId) => {
  const { loading, error, data, refetch } = useQuery(GET_LAUNDRY_MASTER_BY_HOSTELID, 
    {
      variables: { hostelId },
      skip: !hostelId,
    }
  );

  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};