import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  InputGroup,
  CustomInput,
} from "reactstrap";
import toaster from "@components/common/Toaster";
import {
  useCreatePLStatement,
  useUpdatePLStatement,
} from "../../api/P&LStatement/Mutation";
import ErrorBoundary from "./ErrorBoundary";
import { PlusCircle, MinusCircle, Edit, Plus, Trash2 } from "react-feather";
import { fieldConfig } from "./FieldConfig";
import { formulas } from "./formulas";
import { fieldDependencies } from "./fieldDependencies";
import { useHostelList } from "../../api/dataSource";
import Select from "react-select";

const PLStatementOverviewModal = ({ isOpen, toggle, editData, refetch }) => {
  const [formData, setFormData] = useState(editData || {});
  const [createPLStatementHandler] = useCreatePLStatement();
  const [updatePLStatementHandler] = useUpdatePLStatement();
  const [isMutationComplete, setIsMutationComplete] = useState(false);
  const [inventoryOpen, setInventoryOpen] = useState(false);
  const [revenueOpen, setRevenueOpen] = useState(false);
  const [operationalExpenseOpen, setOperationalExpenseOpen] = useState(false);
  const [marginAnalysisOpen, setMarginAnalysisOpen] = useState(false);
  const [reviewsFeedbackOpen, setReviewsFeedbackOpen] = useState(false);
  const [remarksActionOpen, setRemarksActionOpen] = useState(false);
  const [editFieldModal, setEditFieldModal] = useState(false);
  const [currentField, setCurrentField] = useState(null);
  const [hostelsList, setHostelsList] = useState([]);
  const { data: allHostelsData } = useHostelList();
  const [isLogsOpen, setIsLogsOpen] = useState(false);
  const toggleLogs = () => setIsLogsOpen((prev) => !prev);

  const toggleInventory = () => setInventoryOpen(!inventoryOpen);
  const toggleRevenue = () => setRevenueOpen(!revenueOpen);
  const toggleOperationalExpense = () =>
    setOperationalExpenseOpen(!operationalExpenseOpen);
  const toggleMarginAnalysis = () => setMarginAnalysisOpen(!marginAnalysisOpen);
  const toggleReviewsFeedback = () =>
    setReviewsFeedbackOpen(!reviewsFeedbackOpen);
  const toggleRemarksAction = () => setRemarksActionOpen(!remarksActionOpen);

  useEffect(() => {
    const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
    if (allHostelsData?.getHostelList?.length) {
      const filteredHostels =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return allHostelsData?.getHostelList?.find(
                (item) => item?._id === hostelID
              );
            })
          : allHostelsData?.getHostelList;
      let HostelsList = filteredHostels?.map((item) => {
        return { label: item?.name, value: item?._id };
      });
      setHostelsList(HostelsList);
    }
  }, [allHostelsData?.getHostelList]);

  const monthOptions = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const yearOptions = Array.from({ length: 10 }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return { label: year?.toString(), value: year };
  });

  useEffect(() => {
    if (!editData) {
      setFormData({
        hostelName: "",
        hostelCode: null,
        operationalModel: "",
        areaManager: "",
        month: null,
        year: null,
        overallRemarks: "",
        remarksOnOps: "",
        remarksOnCX: "",
        remarksOnPosSales: "",
        actionsFollowups: "",
        logStatements: [],
        fields: [
          ...fieldConfig.inventory.map((field) => ({
            fieldName: field.name,
            fieldValue: "",
            isCritical: false,
            isProvisional: false,
            remark: "",
            breakup: [],
          })),
          ...fieldConfig.revenue.map((field) => ({
            fieldName: field.name,
            fieldValue: "",
            isCritical: false,
            isProvisional: false,
            remark: "",
            breakup: [],
          })),
          ...fieldConfig.operationalExpense.map((field) => ({
            fieldName: field.name,
            fieldValue: "",
            isCritical: false,
            isProvisional: false,
            remark: "",
            breakup: [],
          })),
          ...fieldConfig.marginAnalysis.map((field) => ({
            fieldName: field.name,
            fieldValue: "",
            isCritical: false,
            isProvisional: false,
            remark: "",
            breakup: [],
          })),
          ...fieldConfig.reviewsFeedback.map((field) => ({
            fieldName: field.name,
            fieldValue: "",
            isCritical: false,
            isProvisional: false,
            remark: "",
            breakup: [],
          })),
        ],
      });
    } else {
      // setFormData(editData);

      setFormData({ ...editData, logStatements: editData.logStatements || [] });

      refetch();
    }

    setIsLogsOpen(false);

    return () => {
      setInventoryOpen(false);
      setRevenueOpen(false);
      setOperationalExpenseOpen(false);
      setMarginAnalysisOpen(false);
      setReviewsFeedbackOpen(false);
      setRemarksActionOpen(false);
    };
  }, [editData, isOpen]);

  useEffect(() => {
    if (isMutationComplete) {
      setIsMutationComplete(false);
    }
  }, [isMutationComplete]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { _id, createdAt, updatedAt, ...plInputData } = { ...formData };

    const sanitizedPlInputData = {
      ...plInputData,
      fields: plInputData.fields.map((field) => ({
        ...field,
        fieldValue: field.fieldValue === "" ? null : field.fieldValue,
      })),
    };

    try {
      let response;

      if (_id) {
        response = await updatePLStatementHandler({
          id: _id,
          plInput: sanitizedPlInputData,
        });

        if (response?.data?.updatePLStatement) {
          toaster("success", "P&L statement updated successfully!");
          setFormData(response.data.updatePLStatement);
        } else {
          throw new Error("Update failed: Response data is missing.");
        }
      } else {
        response = await createPLStatementHandler({
          plInput: sanitizedPlInputData,
        });

        if (response?.data?.createPLStatement) {
          toaster("success", "P&L statement created successfully!");
          setFormData(response.data.createPLStatement);
        } else {
          throw new Error("Create failed: Response data is missing.");
        }
      }

      setIsMutationComplete(true);
      toggle();
    } catch (error) {
      console.error("Error during mutation:", error);
      toaster("error", "Something went wrong! Please try again.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    let parsedValue = value;

    if (name === "month" || name === "year") {
      // For month and year, set the value directly
      parsedValue = parseInt(value, 10);
    } else if (type === "number") {
      parsedValue = value ? parseFloat(value) : null;
    } else if (value === "") {
      parsedValue = null;
    }

    setFormData((prev) => {
      let updatedFormData = { ...prev };

      if (name === "hostelName") {
        // Find the selected hostel object
        const selectedHostel = hostelsList.find(
          (hostel) => hostel.value === value
        );

        console.log(selectedHostel, "selectedHostel");
        updatedFormData.hostelName = selectedHostel ? selectedHostel.label : "";
        updatedFormData.hostelId = selectedHostel ? selectedHostel.value : null;
      } else if (prev.hasOwnProperty(name)) {
        updatedFormData[name] = parsedValue;
      } else {
        updatedFormData.fields = prev.fields.map((field) =>
          field.fieldName === name
            ? { ...field, fieldValue: parsedValue }
            : field
        );
      }

      if (prev.hasOwnProperty(name)) {
        updatedFormData[name] = parsedValue;
      } else {
        updatedFormData.fields = prev.fields.map((field) =>
          field.fieldName === name
            ? { ...field, fieldValue: parsedValue }
            : field
        );
      }

      // Function to recalculate all dependent fields
      const recalculateDependentFields = (updatedFormData) => {
        const fieldsToRecalculate = new Set();

        // Find all fields that need to be recalculated
        Object.keys(fieldDependencies).forEach((fieldName) => {
          if (fieldDependencies[fieldName]) {
            Object.keys(fieldDependencies[fieldName]).forEach((formula) => {
              if (fieldDependencies[fieldName][formula]) {
                fieldsToRecalculate.add(formula);
              }
            });
          }
        });

        // Recalculate each dependent field
        fieldsToRecalculate.forEach((formula) => {
          switch (formula) {
            case "calculateTotalInventory":
              {
                const noOfBeds = updatedFormData.fields.find(
                  (field) => field.fieldName === "No. of beds"
                )?.fieldValue;
                const month = updatedFormData.month;
                const year = updatedFormData.year;

                const totalInventory = formulas.calculateTotalInventory(
                  noOfBeds,
                  month,
                  year
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Total Inventory"
                    ? { ...field, fieldValue: totalInventory }
                    : field
                );
              }
              break;

            case "calculateTotalRevenue":
              {
                const bedRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Bed Revenue"
                )?.fieldValue;
                const fbRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "F&B Revenue"
                )?.fieldValue;
                const ancillaryRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Ancillary Revenue"
                )?.fieldValue;

                const totalRevenue = formulas.calculateTotalRevenue(
                  bedRevenue,
                  fbRevenue,
                  ancillaryRevenue
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Total Revenue"
                    ? { ...field, fieldValue: totalRevenue }
                    : field
                );
              }
              break;

            case "calculateOccupancyPercentage":
              {
                const tsbn = updatedFormData.fields.find(
                  (field) => field.fieldName === "T S BN"
                )?.fieldValue;
                const tabn = updatedFormData.fields.find(
                  (field) => field.fieldName === "T A BN"
                )?.fieldValue;

                const occupancyPercentage =
                  formulas.calculateOccupancyPercentage(tsbn, tabn);

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Occ%"
                    ? { ...field, fieldValue: occupancyPercentage }
                    : field
                );
              }
              break;

            case "calculateFBAOV":
              {
                const fbRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "F&B Revenue"
                )?.fieldValue;
                const fbOrders = updatedFormData.fields.find(
                  (field) => field.fieldName === "F&B Orders"
                )?.fieldValue;

                const fnbAOV = formulas.calculateFBAOV(fbRevenue, fbOrders);

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "F&B AOV (in INR)"
                    ? { ...field, fieldValue: fnbAOV }
                    : field
                );
              }
              break;

            case "calculateFBOrderPerBedNightSold":
              {
                const fbOrders = updatedFormData.fields.find(
                  (field) => field.fieldName === "F&B Orders"
                )?.fieldValue;
                const tsbn = updatedFormData.fields.find(
                  (field) => field.fieldName === "T S BN"
                )?.fieldValue;

                const fbOrderPerBedNightSold =
                  formulas.calculateFBOrderPerBedNightSold(fbOrders, tsbn);

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "F&B Order Per Bed Night Sold"
                    ? { ...field, fieldValue: fbOrderPerBedNightSold }
                    : field
                );
              }
              break;

            case "calculateTotalOPEX":
              {
                const rent = updatedFormData.fields.find(
                  (field) => field.fieldName === "Rent"
                )?.fieldValue;
                const u1 = updatedFormData.fields.find(
                  (field) =>
                    field.fieldName === "U1: Electricity + Fuel + Genset"
                )?.fieldValue;
                const u2 = updatedFormData.fields.find(
                  (field) => field.fieldName === "U2: Water + Tanker"
                )?.fieldValue;
                const u3 = updatedFormData.fields.find(
                  (field) =>
                    field.fieldName ===
                    "U3: TV & Internet + Telephone + SW subscriptions"
                )?.fieldValue;
                const u4 = updatedFormData.fields.find(
                  (field) => field.fieldName === "U4: Toiletry"
                )?.fieldValue;
                const u5 = updatedFormData.fields.find(
                  (field) => field.fieldName === "U5: Laundry"
                )?.fieldValue;
                const rAndM = updatedFormData.fields.find(
                  (field) => field.fieldName === "R&M"
                )?.fieldValue;
                const misc = updatedFormData.fields.find(
                  (field) =>
                    field.fieldName ===
                    "Misc. (local travel General liasing etc.)(Courier charges + Freight Charges + General Exp +Print & Stationery +Staff Travel Exp)"
                )?.fieldValue;
                const ota = updatedFormData.fields.find(
                  (field) => field.fieldName === "OTA"
                )?.fieldValue;
                const totalSalary = updatedFormData.fields.find(
                  (field) => field.fieldName === "Total salary"
                )?.fieldValue;
                const fbSalary = updatedFormData.fields.find(
                  (field) => field.fieldName === "F&B salary + F&B welfare"
                )?.fieldValue;
                const fbMaterial = updatedFormData.fields.find(
                  (field) =>
                    field.fieldName ===
                    "F&B material cost (= OS + P - CS - HSW - CSW)"
                )?.fieldValue;
                const ancillaryCost = updatedFormData.fields.find(
                  (field) => field.fieldName === "Ancillary cost"
                )?.fieldValue;
                const franchisePayout = updatedFormData.fields.find(
                  (field) => field.fieldName === "Franchise payout"
                )?.fieldValue;

                const totalOPEX = formulas.calculateTotalOPEX(
                  rent,
                  u1,
                  u2,
                  u3,
                  u4,
                  u5,
                  rAndM,
                  misc,
                  ota,
                  totalSalary,
                  fbSalary,
                  fbMaterial,
                  ancillaryCost,
                  franchisePayout
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Total OPEX"
                    ? { ...field, fieldValue: totalOPEX }
                    : field
                );
              }
              break;

            case "calculateRentalCostPerBed":
              {
                const rent = updatedFormData.fields.find(
                  (field) => field.fieldName === "Rent"
                )?.fieldValue;
                const noOfBeds = updatedFormData.fields.find(
                  (field) => field.fieldName === "No. of beds"
                )?.fieldValue;

                const rentalCostPerBed = formulas.calculateRentalCostPerBed(
                  rent,
                  noOfBeds
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Rental Cost Per Bed"
                    ? { ...field, fieldValue: rentalCostPerBed }
                    : field
                );
              }
              break;

            case "calculateTotalSalary": {
              const hostelSalaryOwn = updatedFormData.fields.find(
                (field) => field.fieldName === "Hostel salary (Own)"
              )?.fieldValue;
              const hostelSalaryAgency = updatedFormData.fields.find(
                (field) => field.fieldName === "Hostel salary (Agency)"
              )?.fieldValue;
              const hostelWelfare = updatedFormData.fields.find(
                (field) =>
                  field.fieldName ===
                  "Hostel welfare (Staff food room rent & other)"
              )?.fieldValue;

              const totalSalary = formulas.calculateTotalSalary(
                hostelSalaryOwn,
                hostelSalaryAgency,
                hostelWelfare
              );

              updatedFormData.fields = updatedFormData.fields.map((field) =>
                field.fieldName === "Total salary"
                  ? { ...field, fieldValue: totalSalary }
                  : field
              );
              break;
            }

            case "calculateNoOfHostelStaff":
              {
                const hostelMandays = updatedFormData.fields.find(
                  (field) => field.fieldName === "Hostel Mandays"
                )?.fieldValue;
                const days = updatedFormData.fields.find(
                  (field) => field.fieldName === "Total Days"
                )?.fieldValue;

                const noOfHostelStaff = formulas.calculateNoOfHostelStaff(
                  hostelMandays,
                  days
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "No. of Hostel Staff"
                    ? { ...field, fieldValue: noOfHostelStaff }
                    : field
                );
              }
              break;

            case "calculateHostelSalaryPerManday":
              {
                const hostelSalaryOwn = updatedFormData.fields.find(
                  (field) => field.fieldName === "Hostel salary (Own)"
                )?.fieldValue;
                const hostelSalaryAgency = updatedFormData.fields.find(
                  (field) => field.fieldName === "Hostel salary (Agency)"
                )?.fieldValue;
                const hostelWelfare = updatedFormData.fields.find(
                  (field) =>
                    field.fieldName ===
                    "Hostel welfare (Staff food room rent & other)"
                )?.fieldValue;
                const hostelMandays = updatedFormData.fields.find(
                  (field) => field.fieldName === "Hostel Mandays"
                )?.fieldValue;

                const hostelSalaryPerManday =
                  formulas.calculateHostelSalaryPerManday(
                    hostelSalaryOwn,
                    hostelSalaryAgency,
                    hostelWelfare,
                    hostelMandays
                  );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Hostel Salary Per Manday"
                    ? { ...field, fieldValue: hostelSalaryPerManday }
                    : field
                );
              }
              break;

            case "calculateNumberOfCafeStaff":
              {
                const cafeMandays = updatedFormData.fields.find(
                  (field) => field.fieldName === "Cafe Mandays"
                )?.fieldValue;
                const days = updatedFormData.days;

                const numberOfCafeStaff = formulas.calculateNumberOfCafeStaff(
                  cafeMandays,
                  days
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Number of Cafe Staff"
                    ? { ...field, fieldValue: numberOfCafeStaff }
                    : field
                );
              }
              break;

            case "calculateCafeSalaryPerManday":
              {
                const fbSalaryAndWelfare = updatedFormData.fields.find(
                  (field) => field.fieldName === "F&B salary + F&B welfare"
                )?.fieldValue;
                const cafeMandays = updatedFormData.fields.find(
                  (field) => field.fieldName === "Cafe Mandays"
                )?.fieldValue;

                const cafeSalaryPerManday =
                  formulas.calculateCafeSalaryPerManday(
                    fbSalaryAndWelfare,
                    cafeMandays
                  );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Cafe Salary Per Manday"
                    ? { ...field, fieldValue: cafeSalaryPerManday }
                    : field
                );
              }
              break;

            case "calculateOtherPayouts":
              {
                const ipPayout = updatedFormData.fields.find(
                  (field) => field.fieldName === "I&P payout"
                )?.fieldValue;
                const franchisePayout = updatedFormData.fields.find(
                  (field) => field.fieldName === "Franchise payout"
                )?.fieldValue;

                const otherPayouts = formulas?.calculateOtherPayouts(
                  ipPayout,
                  franchisePayout
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Other Payouts"
                    ? { ...field, fieldValue: otherPayouts }
                    : field
                );
              }
              break;

            case "calculateOverallOpMargin":
              {
                const totalRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Total Revenue"
                )?.fieldValue;

                const totalOpex = updatedFormData.fields.find(
                  (field) => field.fieldName === "Total OPEX"
                )?.fieldValue;

                const otherPayout = updatedFormData.fields.find(
                  (field) => field.fieldName === "Other Payouts"
                )?.fieldValue;

                const overallOpMargin = formulas.calculateOverallOpMargin(
                  totalRevenue,
                  totalOpex,
                  otherPayout
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Overall Op. Margin (in INR)"
                    ? { ...field, fieldValue: overallOpMargin }
                    : field
                );
              }
              break;

            // case "calculateOTA":
            //   {
            //     const bedRevenue = updatedFormData.fields.find(
            //       (field) => field.fieldName === "Bed Revenue"
            //     )?.fieldValue;

            //     const otaPercentage = formulas.calculateOTA(bedRevenue);

            //     updatedFormData.fields = updatedFormData.fields.map((field) =>
            //       field.fieldName === "OTA"
            //         ? { ...field, fieldValue: otaPercentage }
            //         : field
            //     );
            //   }
            //   break;

            case "calculateHostelMargin":
              {
                // Get values for all the dependent fields
                const bedRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Bed Revenue"
                )?.fieldValue;

                const rent = updatedFormData.fields.find(
                  (field) => field.fieldName === "Rent"
                )?.fieldValue;

                const u1 = updatedFormData.fields.find(
                  (field) =>
                    field.fieldName === "U1: Electricity + Fuel + Genset"
                )?.fieldValue;

                const u2 = updatedFormData.fields.find(
                  (field) => field.fieldName === "U2: Water + Tanker"
                )?.fieldValue;

                const u3 = updatedFormData.fields.find(
                  (field) =>
                    field.fieldName ===
                    "U3: TV & Internet + Telephone + SW subscriptions"
                )?.fieldValue;

                const u4 = updatedFormData.fields.find(
                  (field) => field.fieldName === "U4: Toiletry"
                )?.fieldValue;

                const u5 = updatedFormData.fields.find(
                  (field) => field.fieldName === "U5: Laundry"
                )?.fieldValue;

                const rAndM = updatedFormData.fields.find(
                  (field) => field.fieldName === "R&M"
                )?.fieldValue;

                const misc = updatedFormData.fields.find(
                  (field) =>
                    field.fieldName ===
                    "Misc. (local travel General liasing etc.)(Courier charges + Freight Charges + General Exp +Print & Stationery +Staff Travel Exp)"
                )?.fieldValue;

                const ota = updatedFormData.fields.find(
                  (field) => field.fieldName === "OTA"
                )?.fieldValue;

                const totalSalary = updatedFormData.fields.find(
                  (field) => field.fieldName === "Total salary"
                )?.fieldValue;

                const otherPayouts = updatedFormData.fields.find(
                  (field) => field.fieldName === "Other Payouts"
                )?.fieldValue;

                // Calculate the Hostel Margin (in INR)
                const hostelMargin = formulas.calculateHostelMargin(
                  bedRevenue,
                  rent,
                  u1,
                  u2,
                  u3,
                  u4,
                  u5,
                  rAndM,
                  misc,
                  ota,
                  totalSalary,
                  otherPayouts
                );

                // Update the value for Hostel Margin (in INR) and ensure it's treated as a Float
                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Hostel margin (in INR)"
                    ? { ...field, fieldValue: parseFloat(hostelMargin) }
                    : field
                );
              }
              break;

            case "calculateHostelMarginPercentage":
              {
                // Get values for all the dependent fields
                const hostelMarginInINR = updatedFormData.fields.find(
                  (field) => field.fieldName === "Hostel margin (in INR)"
                )?.fieldValue;

                const bedRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Bed Revenue"
                )?.fieldValue;

                // Calculate the Hostel Margin (in %)
                const hostelMarginPercentage =
                  formulas.calculateHostelMarginPercentage(
                    hostelMarginInINR,
                    bedRevenue
                  );

                // Update the value for Hostel Margin (in %)
                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Hostel margin (in %)"
                    ? { ...field, fieldValue: hostelMarginPercentage }
                    : field
                );
              }
              break;

            case "calculateFBMarginInINR":
              {
                // Get the values for all the dependent fields
                const fbRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "F&B Revenue"
                )?.fieldValue;

                const fbSalaryWelfare = updatedFormData.fields.find(
                  (field) => field.fieldName === "F&B salary + F&B welfare"
                )?.fieldValue;

                const cafeSalaryPerManday = updatedFormData.fields.find(
                  (field) => field.fieldName === "Cafe Salary Per Manday"
                )?.fieldValue;

                const fbMaterialCost = updatedFormData.fields.find(
                  (field) =>
                    field.fieldName ===
                    "F&B material cost (= OS + P - CS - HSW - CSW)"
                )?.fieldValue;

                // Calculate the F&B margin (in INR)
                const calculatedFBMarginInINR = formulas.calculateFBMarginInINR(
                  fbRevenue,
                  fbSalaryWelfare,
                  cafeSalaryPerManday,
                  fbMaterialCost
                );

                // Update the value for F&B margin (in INR)
                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "F&B margin (in INR)"
                    ? { ...field, fieldValue: calculatedFBMarginInINR }
                    : field
                );
              }
              break;

            case "calculateFBMarginPercentage":
              {
                const fbMarginInInr = updatedFormData.fields.find(
                  (field) => field.fieldName === "F&B margin (in INR)"
                )?.fieldValue;
                const fbRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "F&B Revenue"
                )?.fieldValue;

                const fbMarginPercentage = formulas.calculateFBMarginPercentage(
                  fbMarginInInr,
                  fbRevenue
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "F&B margin (in %)"
                    ? { ...field, fieldValue: fbMarginPercentage }
                    : field
                );
              }
              break;

            case "calculateAncillaryMarginInInr":
              {
                const ancillaryRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Ancillary Revenue"
                )?.fieldValue;
                const ancillaryCost = updatedFormData.fields.find(
                  (field) => field.fieldName === "Ancillary cost"
                )?.fieldValue;

                const ancillaryMarginInInr =
                  formulas.calculateAncillaryMarginInInr(
                    ancillaryRevenue,
                    ancillaryCost
                  );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Ancillary margin (in INR)"
                    ? { ...field, fieldValue: ancillaryMarginInInr }
                    : field
                );
              }
              break;

            case "calculateAncillaryMarginInPercentage":
              {
                const ancillaryMarginInInr = updatedFormData.fields.find(
                  (field) => field.fieldName === "Ancillary margin (in INR)"
                )?.fieldValue;
                const ancillaryRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Ancillary Revenue"
                )?.fieldValue;

                const ancillaryMarginInPercentage =
                  formulas.calculateAncillaryMarginInPercentage(
                    ancillaryMarginInInr,
                    ancillaryRevenue
                  );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Ancillary margin (in %)"
                    ? { ...field, fieldValue: ancillaryMarginInPercentage }
                    : field
                );
              }
              break;

            case "calculateAnalysisOperationalMarginPercentage":
              {
                const overallOpMarginInInr = updatedFormData.fields.find(
                  (field) => field.fieldName === "Overall Op. Margin (in INR)"
                )?.fieldValue;
                const totalRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Total Revenue"
                )?.fieldValue;

                const analysisOperationalMarginPercentage =
                  formulas.calculateAnalysisOperationalMarginPercentage(
                    overallOpMarginInInr,
                    totalRevenue
                  );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Analysis (Operational Margin %)"
                    ? {
                        ...field,
                        fieldValue: analysisOperationalMarginPercentage,
                      }
                    : field
                );
              }
              break;

            case "calculateRevRentalMultiple":
              {
                const bedRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Bed Revenue"
                )?.fieldValue;
                const rent = updatedFormData.fields.find(
                  (field) => field.fieldName === "Rent"
                )?.fieldValue;

                const revRentalMultiple = formulas.calculateRevRentalMultiple(
                  bedRevenue,
                  rent
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Rev/Rental multiple"
                    ? { ...field, fieldValue: revRentalMultiple }
                    : field
                );
              }
              break;

            case "calculateOTAPercentage":
              {
                const ota = updatedFormData.fields.find(
                  (field) => field.fieldName === "OTA"
                )?.fieldValue;
                const bedRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Bed Revenue"
                )?.fieldValue;

                const otaPercentage = formulas.calculateOTAPercentage(
                  ota,
                  bedRevenue
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "OTA %"
                    ? { ...field, fieldValue: otaPercentage }
                    : field
                );
              }
              break;

            case "calculateUtilitiesTotalRev":
              {
                const u1 = updatedFormData.fields.find(
                  (field) =>
                    field.fieldName === "U1: Electricity + Fuel + Genset"
                )?.fieldValue;
                const u2 = updatedFormData.fields.find(
                  (field) => field.fieldName === "U2: Water + Tanker"
                )?.fieldValue;
                const u3 = updatedFormData.fields.find(
                  (field) =>
                    field.fieldName ===
                    "U3: TV & Internet + Telephone + SW subscriptions"
                )?.fieldValue;
                const bedRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Bed Revenue"
                )?.fieldValue;

                const utilitiesTotalRev = formulas.calculateUtilitiesTotalRev(
                  u1,
                  u2,
                  u3,
                  bedRevenue
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Utilities/Total Rev"
                    ? { ...field, fieldValue: utilitiesTotalRev }
                    : field
                );
              }
              break;

            case "calculateRMTotalRev":
              {
                const rAndM = updatedFormData.fields.find(
                  (field) => field.fieldName === "R&M"
                )?.fieldValue;
                const bedRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Bed Revenue"
                )?.fieldValue;

                const rmTotalRev = formulas.calculateRMTotalRev(
                  rAndM,
                  bedRevenue
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "R&M/Total Rev"
                    ? { ...field, fieldValue: rmTotalRev }
                    : field
                );
              }
              break;

            case "calculateMiscTotalRev":
              {
                const misc = updatedFormData.fields.find(
                  (field) =>
                    field.fieldName ===
                    "Misc. (local travel General liasing etc.)(Courier charges + Freight Charges + General Exp +Print & Stationery +Staff Travel Exp)"
                )?.fieldValue;
                const bedRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Bed Revenue"
                )?.fieldValue;

                const miscTotalRev = formulas.calculateMiscTotalRev(
                  misc,
                  bedRevenue
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Misc/Total Rev"
                    ? { ...field, fieldValue: miscTotalRev }
                    : field
                );
              }
              break;

            case "calculateHostelStaffSalaryPerBedSales":
              {
                const totalSalary = updatedFormData.fields.find(
                  (field) => field.fieldName === "Total salary"
                )?.fieldValue;
                const bedRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "Bed Revenue"
                )?.fieldValue;

                const hostelStaffSalaryPerBedSales =
                  formulas.calculateHostelStaffSalaryPerBedSales(
                    totalSalary,
                    bedRevenue
                  );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "Hostel staff salary/(bed sales)"
                    ? { ...field, fieldValue: hostelStaffSalaryPerBedSales }
                    : field
                );
              }
              break;

            case "calculateFNBStaffSalaryPerFNBSales":
              {
                const fnbSalaryWelfare = updatedFormData.fields.find(
                  (field) => field.fieldName === "F&B salary + F&B welfare"
                )?.fieldValue;
                const fnbRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "F&B Revenue"
                )?.fieldValue;

                const fnbStaffSalaryPerFNBSales =
                  formulas.calculateFNBStaffSalaryPerFNBSales(
                    fnbSalaryWelfare,
                    fnbRevenue
                  );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "F&B staff salary/(F&B sales)"
                    ? { ...field, fieldValue: fnbStaffSalaryPerFNBSales }
                    : field
                );
              }
              break;

            case "calculateFNBFoodCostPerFNBRevenue":
              {
                const fnbMaterialCost = updatedFormData.fields.find(
                  (field) =>
                    field.fieldName ===
                    "F&B material cost (= OS + P - CS - HSW - CSW)"
                )?.fieldValue;
                const fnbRevenue = updatedFormData.fields.find(
                  (field) => field.fieldName === "F&B Revenue"
                )?.fieldValue;

                const fnbFoodCostPerFNBRevenue =
                  formulas.calculateFNBFoodCostPerFNBRevenue(
                    fnbMaterialCost,
                    fnbRevenue
                  );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "F&B food cost/(F&B sales)"
                    ? { ...field, fieldValue: fnbFoodCostPerFNBRevenue }
                    : field
                );
              }
              break;

            case "calculateHostelStaffCount":
              {
                const hostelMandays = updatedFormData.fields.find(
                  (field) => field.fieldName === "Hostel Mandays"
                )?.fieldValue;

                const month = updatedFormData.month;
                const year = updatedFormData.year;

                const hostelStaffCount = formulas.calculateHostelStaffCount(
                  hostelMandays,
                  month,
                  year
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "# of Hostel Staff"
                    ? { ...field, fieldValue: hostelStaffCount }
                    : field
                );
              }
              break;

            case "calculateCafeStaffCount":
              {
                const cafeMandays = updatedFormData.fields.find(
                  (field) => field.fieldName === "Cafe Mandays"
                )?.fieldValue;

                const month = updatedFormData.month;
                const year = updatedFormData.year;

                const cafeStaffCount = formulas.calculateCafeStaffCount(
                  cafeMandays,
                  month,
                  year
                );

                updatedFormData.fields = updatedFormData.fields.map((field) =>
                  field.fieldName === "# of Cafe Staff"
                    ? { ...field, fieldValue: cafeStaffCount }
                    : field
                );
              }
              break;

            default:
              break;
          }
        });
      };

      recalculateDependentFields(updatedFormData);

      return updatedFormData;
    });
  };

  const handleEditFieldSave = (updatedField) => {
    if (updatedField.isCritical && updatedField.isProvisional) {
      toaster("error", "A field cannot be both critical and provisional.");
      return;
    }

    setFormData((prev) => ({
      ...prev,
      fields: prev.fields.map((field) =>
        field.fieldName === updatedField.fieldName ? updatedField : field
      ),
    }));
  };

  // const renderField = (label, name, type = "number", readOnly = false) => {
  // const renderField = (label, name, type, readOnly, sectionKey) => {
  //   const isFieldInArray = formData?.fields?.find(
  //     (item) => item.fieldName === name
  //   );
  //   let fieldValue = isFieldInArray
  //     ? isFieldInArray.fieldValue
  //     : formData?.[name] || "";

  //   // Handle dropdowns for month, year, operationalModel, and hostelName
  //   if (
  //     name === "month" ||
  //     name === "year" ||
  //     name === "operationalModel" ||
  //     name === "hostelName"
  //   ) {
  //     let options = [];

  //     if (name === "month") {
  //       options = monthOptions;
  //     } else if (name === "year") {
  //       options = yearOptions;
  //     } else if (name === "operationalModel") {
  //       options = [
  //         { value: "COCO", label: "COCO" },
  //         { value: "FOFO", label: "FOFO" },
  //         { value: "FOCO", label: "FOCO" },
  //         { value: "OMC", label: "OMC" },
  //       ];
  //     } else if (name === "hostelName") {
  //       options = hostelsList;
  //     }

  //     return (
  //       <Col md={6} key={name}>
  //         <FormGroup>
  //           <Label>{label}</Label>
  //           <Input
  //             type="select"
  //             name={name}
  //             value={fieldValue || ""}
  //             onChange={handleInputChange}
  //             readOnly={readOnly}
  //           >
  //             <option value="">Select {label}</option>
  //             {options.map((option) => (
  //               <option
  //                 key={option.value}
  //                 value={name === "hostelName" ? option.label : option.value}
  //               >
  //                 {option.label}
  //               </option>
  //             ))}
  //           </Input>
  //         </FormGroup>
  //       </Col>
  //     );
  //   }

  //   // Format number values if needed
  //   if (type === "number" && fieldValue !== "") {
  //     if (!isNaN(fieldValue)) {
  //       const decimalPlaces = (fieldValue?.toString()?.split(".")[1] || "")
  //         .length;
  //       if (decimalPlaces > 3) {
  //         fieldValue = parseFloat(fieldValue)?.toFixed(3);
  //       } else {
  //         fieldValue = fieldValue?.toString();
  //       }
  //     }
  //   }

  //   const field = isFieldInArray || { fieldName: name, fieldValue };

  //   const inputStyle = {
  //     backgroundColor: field.isCritical
  //       ? "#ffcccc"
  //       : field.isProvisional
  //       ? "#ffffcc"
  //       : "white",
  //   };

  //   return (
  //     <Col md={6} key={name}>
  //       <FormGroup>
  //         <Label>{label}</Label>
  //         <div style={{ display: "flex", alignItems: "center" }}>
  //           <Input
  //             type={type}
  //             name={name}
  //             value={fieldValue}
  //             onChange={handleInputChange}
  //             style={inputStyle}
  //             readOnly={readOnly}
  //           />
  //           {/* Edit Button Added Back */}

  //           {!["hostelInfo", "remarksAction"].includes(sectionKey) && (
  //             <Button
  //               color="link"
  //               onClick={() => {
  //                 setCurrentField(field);
  //                 setEditFieldModal(true);
  //               }}
  //             >
  //               <Edit size={16} />
  //             </Button>
  //           )}
  //         </div>
  //       </FormGroup>
  //     </Col>
  //   );
  // };

  const renderField = (
    label,
    name,
    type = "number",
    readOnly = false,
    sectionKey
  ) => {
    const isFieldInArray = formData?.fields?.find(
      (item) => item.fieldName === name
    );

    let fieldValue = isFieldInArray
      ? isFieldInArray.fieldValue
      : formData?.[name] || "";

    let options = [];

    if (name === "month") {
      options = monthOptions;
    } else if (name === "year") {
      options = yearOptions;
    } else if (name === "operationalModel") {
      options = [
        { value: "COCO", label: "COCO" },
        { value: "FOFO", label: "FOFO" },
        { value: "FOCO", label: "FOCO" },
        { value: "OMC", label: "OMC" },
      ];
    } else if (name === "hostelName") {
      // **Keeping your hostel logic unchanged**
      options = hostelsList;
    }

    // Use React-Select for search functionality in all dropdowns
    if (options.length > 0) {
      return (
        <Col md={6} key={name}>
          <FormGroup>
            <Label>{label}</Label>
            <Select
              name={name}
              options={options}
              value={options.find((option) =>
                name === "hostelName"
                  ? option.label === fieldValue
                  : option.value === fieldValue
              )}
              onChange={(selectedOption) =>
                handleInputChange({
                  target: {
                    name,
                    value:
                      name === "hostelName"
                        ? selectedOption?.label
                        : selectedOption?.value,
                  },
                })
              }
              isDisabled={readOnly}
              isSearchable
              placeholder={`Select ${label}`}
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: readOnly ? "#e9ecef" : "white",
                  cursor: readOnly ? "not-allowed" : "pointer",
                }),
              }}
            />
          </FormGroup>
        </Col>
      );
    }

    // Format number values if needed
    if (type === "number" && fieldValue !== "") {
      if (!isNaN(fieldValue)) {
        const decimalPlaces = (fieldValue?.toString()?.split(".")[1] || "")
          .length;
        fieldValue =
          decimalPlaces > 3
            ? parseFloat(fieldValue)?.toFixed(3)
            : fieldValue?.toString();
      }
    }

    const field = isFieldInArray || { fieldName: name, fieldValue };

    const inputStyle = {
      backgroundColor: field.isCritical
        ? "#ffcccc"
        : field.isProvisional
        ? "#ffffcc"
        : "white",
    };

    return (
      <Col md={6} key={name}>
        <FormGroup>
          <Label>{label}</Label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              type={type}
              name={name}
              value={fieldValue}
              onChange={handleInputChange}
              style={inputStyle}
              readOnly={readOnly}
              onWheel={(e) => e.target.blur()}
            />
            {!["hostelInfo", "remarksAction"].includes(sectionKey) && (
              <Button
                color="link"
                onClick={() => {
                  setCurrentField(field);
                  setEditFieldModal(true);
                }}
              >
                <Edit size={16} />
              </Button>
            )}
          </div>
        </FormGroup>
      </Col>
    );
  };

  const renderSection = (title, sectionKey, toggle, isOpen) => {
    // Get the first field from the section config
    const firstField = fieldConfig[sectionKey]?.[0];

    // Find the corresponding value from formData
    const fieldData = formData?.fields?.find(
      (item) => item.fieldName === firstField?.name
    );
    const fieldValue = fieldData
      ? fieldData.fieldValue
      : formData?.[firstField?.name] || "";

    return (
      <Card className="mt-2">
        <CardHeader>
          <strong>{title}</strong>
          {/* Display the first field label and value next to the title */}
          {firstField && (
            <span className="ml-1 text-muted">
              {firstField?.label}: <strong>{fieldValue || "N/A"}</strong>
            </span>
          )}
          <Button
            color="link"
            onClick={toggle}
            className="ml-auto"
            style={{ float: "right" }}
          >
            {isOpen ? <MinusCircle size={20} /> : <PlusCircle size={20} />}
          </Button>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody>
            <Row>
              {fieldConfig[sectionKey]?.map((field) =>
                renderField(
                  field.label,
                  field.name,
                  field.type,
                  field.readOnly,
                  sectionKey
                )
              )}
            </Row>
          </CardBody>
        </Collapse>
      </Card>
    );
  };

  // const EditFieldModal = ({ isOpen, toggle, field, onSave }) => {
  //   const [isCritical, setIsCritical] = useState(field?.isCritical || false);
  //   const [isProvisional, setIsProvisional] = useState(
  //     field?.isProvisional || false
  //   );
  //   const [remark, setRemark] = useState(field?.remark || "");
  //   const [breakup, setBreakup] = useState(field?.breakup || []);

  //   const handleAddBreakup = () => {
  //     setBreakup([...breakup, { key: "", value: "" }]);
  //   };

  //   const handleBreakupChange = (index, key, value) => {
  //     const updatedBreakup = [...breakup];
  //     updatedBreakup[index] = { key, value };
  //     setBreakup(updatedBreakup);
  //   };

  //   const handleDeleteBreakup = (index) => {
  //     const updatedBreakup = breakup.filter((_, i) => i !== index);
  //     setBreakup(updatedBreakup);
  //   };

  //   const handleSave = () => {
  //     onSave({
  //       ...field,
  //       isCritical,
  //       isProvisional,
  //       remark,
  //       breakup,
  //     });
  //     toggle();
  //   };

  //   return (
  //     <Modal isOpen={isOpen} toggle={toggle} centered>
  //       <ModalHeader toggle={toggle}>Edit Field</ModalHeader>
  //       <ModalBody>
  //         <Form>
  //           {/* Is Critical Switch */}
  //           <FormGroup>
  //             <div className="d-flex align-items-center">
  //               <Label className="mb-0 mr-2">Is Critical</Label>
  //               <CustomInput
  //                 type="switch"
  //                 id="isCriticalSwitch"
  //                 checked={isCritical}
  //                 onChange={(e) => setIsCritical(e.target.checked)}
  //               />
  //             </div>
  //           </FormGroup>

  //           {/* Is Provisional Switch */}
  //           <FormGroup>
  //             <div className="d-flex align-items-center">
  //               <Label className="mb-0 mr-2">Is Provisional</Label>
  //               <CustomInput
  //                 type="switch"
  //                 id="isProvisionalSwitch"
  //                 checked={isProvisional}
  //                 onChange={(e) => setIsProvisional(e.target.checked)}
  //               />
  //             </div>
  //           </FormGroup>

  //           {/* Remark Field */}
  //           <FormGroup>
  //             <Label>Remark</Label>
  //             <Input
  //               type="text"
  //               value={remark}
  //               onChange={(e) => setRemark(e.target.value)}
  //               className="mb-1"
  //             />
  //           </FormGroup>

  //           {/* Breakup Section */}
  //           <FormGroup>
  //             <Label>Breakup</Label>
  //             {breakup.length > 0 ? (
  //               breakup.map((item, index) => (
  //                 <InputGroup key={index} className="mb-2">
  //                   <Input
  //                     type="text"
  //                     placeholder="Key"
  //                     value={item.key}
  //                     onChange={(e) =>
  //                       handleBreakupChange(index, e.target.value, item.value)
  //                     }
  //                     className="mr-2"
  //                   />
  //                   <Input
  //                     type="text"
  //                     placeholder="Value"
  //                     value={item.value}
  //                     onChange={(e) =>
  //                       handleBreakupChange(index, item.key, e.target.value)
  //                     }
  //                     className="mr-2"
  //                   />
  //                   <div className="d-flex align-items-center">
  //                     <Button
  //                       color="primary"
  //                       size="sm"
  //                       onClick={handleAddBreakup}
  //                       className="p-0 rounded-circle d-flex align-items-center justify-content-center"
  //                       style={{ width: "24px", height: "24px" }}
  //                     >
  //                       <Plus size={14} />
  //                     </Button>

  //                     <Button
  //                       color="link"
  //                       size="sm"
  //                       onClick={() => handleDeleteBreakup(index)}
  //                       className="p-0 ml-1"
  //                     >
  //                       <Trash2 size={20} className="text-danger" />
  //                     </Button>
  //                   </div>
  //                 </InputGroup>
  //               ))
  //             ) : (
  //               // Render "Add" button when there's no breakup data
  //               <div className="d-flex align-items-center mt-2">
  //                 <Button
  //                   color="primary"
  //                   size="sm"
  //                   onClick={handleAddBreakup}
  //                   className="p-0 rounded-circle d-flex align-items-center justify-content-center"
  //                   style={{ width: "24px", height: "24px" }}
  //                 >
  //                   <Plus size={14} />
  //                 </Button>
  //                 <span className="ml-2 text-muted">Add a breakup</span>
  //               </div>
  //             )}
  //             <div className="d-flex justify-content-between align-items-center mt-2">
  //               <Button color="success" size="sm" onClick={handleSave}>
  //                 Submit
  //               </Button>
  //             </div>
  //           </FormGroup>
  //         </Form>
  //       </ModalBody>
  //     </Modal>
  //   );
  // };

  const EditFieldModal = ({ isOpen, toggle, field, onSave }) => {
    const [isCritical, setIsCritical] = useState(field?.isCritical || false);
    const [isProvisional, setIsProvisional] = useState(
      field?.isProvisional || false
    );
    const [remark, setRemark] = useState(field?.remark || "");
    const [breakup, setBreakup] = useState(field?.breakup || []);
    const [totalValue, setTotalValue] = useState(0);

    const handleAddBreakup = () => {
      setBreakup([...breakup, { key: "", value: "" }]);
      setTotalValue(totalValue); // No change in total when adding a new empty breakup
    };

    const handleBreakupChange = (index, key, value) => {
      const updatedBreakup = [...breakup];
      updatedBreakup[index] = {
        key: key,
        value: isNaN(value) ? "" : Number(value),
      };
      setBreakup(updatedBreakup);

      // Calculate the total value
      const newTotal = updatedBreakup.reduce(
        (sum, item) => sum + (item.value ? Number(item.value) : 0),
        0
      );
      setTotalValue(newTotal);
    };

    const handleDeleteBreakup = (index) => {
      const updatedBreakup = breakup.filter((_, i) => i !== index);
      setBreakup(updatedBreakup);

      // Calculate the total value
      const newTotal = updatedBreakup.reduce(
        (sum, item) => sum + (item.value ? Number(item.value) : 0),
        0
      );
      setTotalValue(newTotal);
    };

    const handleSave = () => {
      onSave({
        ...field,
        isCritical,
        isProvisional,
        remark,
        breakup,
      });
      toggle();
    };

    return (
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Edit Field</ModalHeader>
        <ModalBody>
          <Form>
            {/* Is Critical Switch */}
            <FormGroup>
              <div className="d-flex align-items-center">
                <Label className="mb-0 mr-2">Is Critical</Label>
                <CustomInput
                  type="switch"
                  id="isCriticalSwitch"
                  checked={isCritical}
                  onChange={(e) => setIsCritical(e.target.checked)}
                />
              </div>
            </FormGroup>

            {/* Is Provisional Switch */}
            <FormGroup>
              <div className="d-flex align-items-center">
                <Label className="mb-0 mr-2">Is Provisional</Label>
                <CustomInput
                  type="switch"
                  id="isProvisionalSwitch"
                  checked={isProvisional}
                  onChange={(e) => setIsProvisional(e.target.checked)}
                />
              </div>
            </FormGroup>

            {/* Remark Field */}
            <FormGroup>
              <Label>Remark</Label>
              <Input
                type="text"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                className="mb-1"
              />
            </FormGroup>

            {/* Breakup Section */}
            <FormGroup>
              <Label>Breakup</Label>
              {breakup.length > 0 ? (
                breakup.map((item, index) => (
                  <InputGroup key={index} className="mb-2">
                    <Input
                      type="text"
                      placeholder="Key"
                      value={item.key}
                      onChange={(e) =>
                        handleBreakupChange(index, e.target.value, item.value)
                      }
                      className="mr-2"
                    />
                    <Input
                      type="number"
                      placeholder="Value"
                      value={item.value}
                      onChange={(e) =>
                        handleBreakupChange(index, item.key, e.target.value)
                      }
                      className="mr-2"
                    />
                    <div className="d-flex align-items-center">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={handleAddBreakup}
                        className="p-0 rounded-circle d-flex align-items-center justify-content-center"
                        style={{ width: "24px", height: "24px" }}
                      >
                        <Plus size={14} />
                      </Button>

                      <Button
                        color="link"
                        size="sm"
                        onClick={() => handleDeleteBreakup(index)}
                        className="p-0 ml-1"
                      >
                        <Trash2 size={20} className="text-danger" />
                      </Button>
                    </div>
                  </InputGroup>
                ))
              ) : (
                // Render "Add" button when there's no breakup data
                <div className="d-flex align-items-center mt-2">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={handleAddBreakup}
                    className="p-0 rounded-circle d-flex align-items-center justify-content-center"
                    style={{ width: "24px", height: "24px" }}
                  >
                    <Plus size={14} />
                  </Button>
                  <span className="ml-2 text-muted">Add a breakup</span>
                </div>
              )}
              <div className="d-flex justify-content-between align-items-center mt-2">
                <Label>Total Value: {totalValue}</Label>
                <Button color="success" size="sm" onClick={handleSave}>
                  Submit
                </Button>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <ErrorBoundary>
      <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
        <ModalHeader toggle={toggle}>Overview</ModalHeader>
        <ModalBody>
          {formData ? (
            <Form>
              {/* Hostel Information Section */}
              {renderSection(
                "Hostel Information",
                "hostelInfo",
                () => {},
                true
              )}

              {/* Inventory Section */}
              {renderSection(
                "Inventory",
                "inventory",
                toggleInventory,
                inventoryOpen
              )}

              {/* Revenue Section */}
              {renderSection("Revenue", "revenue", toggleRevenue, revenueOpen)}

              {/* Operational Expense Section */}
              {renderSection(
                "Operational Expense",
                "operationalExpense",
                toggleOperationalExpense,
                operationalExpenseOpen
              )}

              {/* Margin Analysis Section */}
              {renderSection(
                "Margin Analysis",
                "marginAnalysis",
                toggleMarginAnalysis,
                marginAnalysisOpen
              )}

              {/* Reviews Feedback Section */}
              {renderSection(
                "Reviews Feedback",
                "reviewsFeedback",
                toggleReviewsFeedback,
                reviewsFeedbackOpen
              )}

              {/* Remarks Action Section */}
              {renderSection(
                "Remarks Action",
                "remarksAction",
                toggleRemarksAction,
                remarksActionOpen
              )}

              {/* Save Button */}
              <Row>
                <Col>
                  <Button
                    color="primary"
                    className="mt-1"
                    onClick={handleSubmit}
                    style={{ padding: "8px 12px", fontSize: "14px" }}
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>

              <>
                {formData.logStatements &&
                  formData.logStatements.length > 0 && (
                    <Col sm="12">
                      <hr />
                      <FormGroup className="w-100">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Label className="form-label" for="logs">
                            <strong>Logs:</strong>
                          </Label>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={toggleLogs}
                          >
                            {isLogsOpen ? "Hide Logs" : "Show Logs"}
                          </Button>
                        </div>
                        <Collapse isOpen={isLogsOpen}>
                          <ul
                            style={{
                              listStylePosition: "inside",
                              paddingLeft: "20px",
                            }}
                          >
                            {formData.logStatements.map((log, index) => (
                              <li key={index} style={{ marginBottom: "5px" }}>
                                {log}{" "}
                              </li>
                            ))}
                          </ul>
                        </Collapse>
                      </FormGroup>
                    </Col>
                  )}
              </>
            </Form>
          ) : (
            <p>No data available.</p>
          )}
        </ModalBody>
      </Modal>

      {/* Edit Field Modal */}
      <EditFieldModal
        isOpen={editFieldModal}
        toggle={() => setEditFieldModal(false)}
        field={currentField}
        onSave={handleEditFieldSave}
      />
    </ErrorBoundary>
  );
};

export default PLStatementOverviewModal;
