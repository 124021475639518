//! Hostel Prefix List
export const HostelPrefixList = [
  { label: "TH", value: "TH" },
  { label: "TH Bam", value: "TH Bam" },
];

//! Hostel Region List
export const HostelRegionList = [
  { label: "North", value: "North" },
  { label: "West", value: "West" },
  { label: "East", value: "East" },
  { label: "South", value: "South" },
];

//! Deal Stage List
export const DealStageList = [
  { label: "Greenfield", value: "Greenfield" },
  { label: "Brownfield", value: "Brownfield" },
];

//! Furnishing Type List
export const FurnishingTypeList = [
  { label: "Un-furnished", value: "Un-furnished" },
  { label: "Semi-furnished", value: "Semi-furnished" },
  { label: "Fully-furnished", value: "Fully-furnished" },
];

//! Cafe List
export const CafeList = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

//! Cafe Scale List
export const CafeScaleList = [
  { label: "Large", value: "Large" },
  { label: "Small", value: "Small" },
];

//! Electrical Connection Type List
export const ElectricalConnectionTypeList = [
  { label: "Residential", value: "Residential" },
  { label: "Commercial", value: "Commercial" },
];

//! Water Connection Type List
export const WaterConnectionTypeList = [
  { label: "Residential", value: "Residential" },
  { label: "Commercial", value: "Commercial" },
];

//! Property Type List
export const PropertyTypeList = [
  { label: "Agricultural", value: "Agricultural" },
  { label: "Residential", value: "Residential" },
  { label: "Commercial", value: "Commercial" },
  { label: "Mix-use", value: "Mix-use" },
];

//! Deal Source Type List
export const DealSourceTypeList = [
  { label: "Organic", value: "Organic" },
  { label: "Broker", value: "Broker" },
];

//! Operational Model Type List
export const OperationalModelTypeList = [
  { label: "COCO", value: "COCO" },
  { label: "FOCO", value: "FOCO" },
  { label: "OMC", value: "OMC" },
];

//! Lessor Entity Type List
export const LessorEntityTypeList = [
  { label: "Individual", value: "Individual" },
  { label: "Multiple individuals", value: "Multiple individuals" },
  { label: "LLP", value: "LLP" },
  { label: "Partnership", value: "Partnership" },
  { label: "Pvt. ltd.", value: "Pvt. ltd." },
];

//! Bank Account Type List
export const BankAccountTypeList = [
  { label: "Saving", value: "Saving" },
  { label: "Current", value: "Current" },
];

//! Lease Deed Type List
export const LeaseDeedTypeList = [
  { label: "Registered lease deed", value: "Registered lease deed" },
  { label: "Notarised lease deed", value: "Notarised lease deed" },
  { label: "Registered LL", value: "Registered LL" },
  { label: "Notarised LL", value: "Notarised LL" },
  { label: "Registered OMC", value: "Registered OMC" },
  { label: "Notarised OMC", value: "Notarised OMC" },
];

//! Licenses Obtainance or Renewal Responsibility Type List
export const LicensesObtainanceOrRenewalResponsibilityTypeList = [
  { label: "Lessor", value: "Lessor" },
  { label: "Lessee", value: "Lessee" },
];

//! Licenses Official Fees Responsibility Type List
export const LicensesOfficialFeesResponsibilityTypeList = [
  { label: "Lessor", value: "Lessor" },
  { label: "Lessee", value: "Lessee" },
];

//! Valid Type List
export const ValidTypeList = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

//! Subletting Rights Type List
export const SublettingRightsTypeList = [
  { label: "No", value: "No" },
  {
    label: "Yes with written permission",
    value: "Yes with written permission",
  },
  {
    label: "Yes without written permission",
    value: "Yes without written permission",
  },
  {
    label: "Yes with written intimation",
    value: "Yes with written intimation",
  },
  {
    label: "Yes without written intimation",
    value: "Yes without written intimation",
  },
];

//! Rental increment frequency
export const RentalIncrementFrequencyTypeList = [
  { label: "Annually", value: "Annually" },
  { label: "Biennial", value: "Biennial" },
  { label: "Triennial", value: "Triennial" },
];

//! Rental payment frequency
export const RentalPaymentFrequencyTypeList = [
  { label: "Monthly", value: "Monthly" },
  { label: "Quarterly", value: "Quarterly" },
  { label: "Quadrimonthly", value: "Quadrimonthly" },
  { label: "Semi - annually", value: "Semi - annually" },
  { label: "Annually", value: "Annually" },
];

//! Monthly rent type
export const MonthlyRentTypeList = [
  { label: "Fixed", value: "Fixed" },
  { label: "Rev. share", value: "Rev. share" },
  { label: "MG + Rev. share", value: "MG + Rev. share" },
];
