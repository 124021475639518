import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";

const SlideModal = ({
  open,
  handleToggle,
  submitDataHandler,
  children,
  large,
  userRole,
}) => {
  return (
    <StyledModalWrap
      unmountOnClose={true}
      isOpen={open}
      toggle={handleToggle}
      size="xl"
      // className={large ? "ml-auto m-0 modal-lg" : "ml-auto m-0"}
    >
      <ModalHeader toggle={handleToggle}>Details</ModalHeader>
      <ModalBody style={{ maxHeight: "85vh", overflowY: "auto" }}>
        {children}
      </ModalBody>
      <ModalFooter>
        {userRole !== "Area Manager" && (
          <Button color="light" onClick={submitDataHandler}>
            Save
          </Button>
        )}
      </ModalFooter>
    </StyledModalWrap>
  );
};

const StyledModalWrap = styled(Modal)`
  .modal-content {
    min-height: 100vh;
    maxHeight: "85vh", overflowY: "auto"
  }
  .modal-header {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    .close {
      margin: 0;
    }
  }
  .modal-footer {
    position: sticky;
    bottom: 0;
    background-color: #f8f8f8;
  }
`;

export default SlideModal;
