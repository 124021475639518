import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
// import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import Select from "react-select";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import AttachmentComponent from "../AttachmentComponent";
// import { tableDataHandler } from "@store/Cms/cms.action";
// import { dateFormatter } from "@utils";
import Flatpickr from "react-flatpickr";

import { indianStateList } from "../../../../utility/Utils";
import {
  HostelPrefixList,
  HostelRegionList,
  DealStageList,
  FurnishingTypeList,
  CafeList,
  CafeScaleList,
  ElectricalConnectionTypeList,
  WaterConnectionTypeList,
  PropertyTypeList,
  DealSourceTypeList,
  OperationalModelTypeList,
  LicensesOfficialFeesResponsibilityTypeList,
  MonthlyRentTypeList,
  RentalIncrementFrequencyTypeList,
  RentalPaymentFrequencyTypeList,
  SublettingRightsTypeList,
} from "../selectOptionsList";
import { dateFormatter } from "@utils";

const DealBasics = () => {
  //   const { id } = useParams();
  //   const dispatch = useDispatch();
  const [hostelPrefix, setHostelPrefix] = useState("");
  const [localityName, setLocalityName] = useState("");
  const [hostelName, setHostelName] = useState("");
  const [hostelCity, setHostelCity] = useState("");
  const [hostelState, setHostelState] = useState("");
  const [hostelRegion, setHostelRegion] = useState("");
  const [hostelCompleteAddress, setHostelCompleteAddress] = useState("");
  const [googleMapsPin, setGoogleMapsPin] = useState("");
  const [numOfDormRooms, setNumOfDormRooms] = useState("");
  const [numOfPrivateRooms, setNumOfPrivateRooms] = useState("");
  const [dormBeds, setDormBeds] = useState("");
  const [numOfTotalRooms, setNumOfTotalRooms] = useState("");
  const [numOfTotalBeds, setNumOfTotalBeds] = useState("");
  const [numOfStaffRooms, setNumOfStaffRooms] = useState("");
  const [numOfInventoryRooms, setNumOfInventoryRooms] = useState("");
  const [dealStage, setDealStage] = useState("");
  const [furnishingType, setFurnishingType] = useState("");
  const [hostelTotalArea, setHostelTotalArea] = useState("");
  const [constructionAge, setConstructionAge] = useState("");
  const [cafe, setCafe] = useState("");
  const [cafeScale, setCafeScale] = useState("");
  const [electricalConnectionType, setElectricalConnectionType] = useState("");
  const [sanctionedElectricalLoad, setSanctionedElectricalLoad] = useState("");
  const [waterConnectionType, setWaterConnectionType] = useState("");
  const [sanctionedWaterSupply, setSanctionedWaterSupply] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [floorPlans, setFloorPlans] = useState([]);
  const [CADDrawings, setCADDrawings] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [videos, setVideos] = useState([]);
  const [titleDocuments, setTitleDocuments] = useState([]);
  const [OCCCCertificate, setOCCCCertificate] = useState([]);
  const [agreementExecutionDate, setAgreementExecutionDate] = useState(
    new Date()
  );
  const [lessorHandoverDate, setLessorHandoverDate] = useState(new Date());
  const [goLiveDate, setGoLiveDate] = useState(new Date());
  const [rentStartDate, setRentStartDate] = useState(new Date());
  const [rentEndDate, setRentEndDate] = useState(new Date());

  const [fitOutPeriod, setFitOutPeriod] = useState("");
  const [lessorLockInPeriod, setLessorLockInPeriod] = useState("");
  const [lesseeLockInPeriod, setLesseeLockInPeriod] = useState("");
  const [zeroPenaltyGracePeriod, setZeroPenaltyGracePeriod] = useState("");
  const [penaltyPerDay, setPenaltyPerDay] = useState("");
  const [terminationNotice, setTerminationNotice] = useState("");
  const [exitingDuration, setExitingDuration] = useState("");
  const [ageOfHostel, setAgeOfHostel] = useState("");
  const [advanceRent, setAdvanceRent] = useState("");
  const [advanceRentRepaymentTimeline, setAdvanceRentRepaymentTimeline] =
    useState("");
  const [
    transformationPreLiquidatedAmount,
    setTransformationPreLiquidatedAmount,
  ] = useState("");
  const [sublettingRights, setSublettingRights] = useState("");

  const [lessorAMCPeriod, setLessorAMCPeriod] = useState("");
  const [majorRMResponsibility, setMajorRMResponsibility] = useState("");
  const [minorRMResponsibility, setMinorRMResponsibility] = useState("");
  const [majorRMCurePeriod, setMajorRMCurePeriod] = useState("");
  const [forceMajeure1to3, setForceMajeure1to3] = useState("");
  const [forceMajeure4to6, setForceMajeure4to6] = useState("");
  const [forceMajeure6plus, setForceMajeure6plus] = useState("");
  const [lesseeEODPeriod, setLesseeEODPeriod] = useState("");
  const [lessorEODPeriod, setLessorEODPeriod] = useState("");
  const [lesseeEODCure, setLesseeEODCure] = useState("");
  const [lessorEODCure, setLessorEODCure] = useState("");
  const [monthlyRentType, setMonthlyRentType] = useState("");
  const [monthlyFixedRent, setMonthlyFixedRent] = useState("");
  const [monthlyRevenueShare, setMonthlyRevenueShare] = useState("");
  const [rentPayableDate, setRentPayableDate] = useState("");
  const [rentalIncrementFreq, setRentalIncrementFreq] = useState("");
  const [rentalPaymentFreq, setRentalPaymentFreq] = useState("");
  const [rentalIncrementPercent, setRentalIncrementPercent] = useState("");
  const [rentPerRoom, setRentPerRoom] = useState("");
  const [rentPerBed, setRentPerBed] = useState("");

  const [dealSource, setDealSource] = useState("");
  const [brokerName, setBrokerName] = useState("");
  const [brokerageAmount, setBrokerageAmount] = useState("");
  const [operationalModel, setOperationalModel] = useState("");
  const [leaseDuration, setLeaseDuration] = useState("");
  const [securityDepositToken, setSecurityDepositToken] = useState("");
  const [securityDepositRegistration, setSecurityDepositRegistration] =
    useState("");
  const [securityDepositHandover, setSecurityDepositHandover] = useState("");
  const [securityDepositRentStart, setSecurityDepositRentStart] = useState("");
  const [totalSecurityDeposit, setTotalSecurityDeposit] = useState("");

  //!Images state
  const [floorPlansImageDummyState] = useState(null);
  const [CADDrawingsImageDummyState] = useState(null);
  const [picturesImageDummyState] = useState(null);
  const [videosImageDummyState] = useState(null);
  const [titleDocumentsImageDummyState] = useState(null);
  const [OCCCCertificateImageDummyState] = useState(null);

  const uploadFloorPlansImageType = [
    { label: "imageURL", value: "floorPlansImage" },
  ];
  const uploadCADDrawingsImageType = [
    { label: "imageURL", value: "CADDrawingsImage" },
  ];
  const uploadPicturesImageType = [
    { label: "imageURL", value: "picturesImage" },
  ];
  const uploadVideosImageType = [{ label: "imageURL", value: "videosImage" }];
  const uploadTitleDocumentsImageType = [
    { label: "imageURL", value: "titleDocumentsImage" },
  ];
  const uploadOCCCCertificateImageType = [
    { label: "imageURL", value: "OCCCCertificateImage" },
  ];

  useEffect(() => {
    if (hostelPrefix?.value && localityName && hostelCity) {
      const hostelName =
        hostelPrefix?.value + " " + localityName + " " + hostelCity;

      console.log(hostelName, "HOSTEL NAME");
      setHostelName(hostelName);
    }
  }, [hostelPrefix?.value, localityName, hostelCity]);

  return (
    <form className="mt-2">
      <Row>
        {/* //! Hostel prefix */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelPrefix">
                Hostel prefix*
              </Label>
            </strong>
            <Select
              isClearable={false}
              isSearchable={true}
              id="hostelPrefix"
              name="hostelPrefix"
              options={HostelPrefixList}
              value={hostelPrefix}
              onChange={(value) => setHostelPrefix(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Locality name */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="localityName">
                Locality name*
              </Label>
            </strong>
            <Input
              type="text"
              value={localityName}
              id="localityName"
              name="localityName"
              //placeholder="Add locality name"
              onChange={(e) => setLocalityName(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Hostel city */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelCity">
                Hostel city*
              </Label>
            </strong>
            <Input
              type="text"
              value={hostelCity}
              id="hostelCity"
              name="hostelCity"
              //placeholder="Add hostel city"
              onChange={(e) => setHostelCity(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Hostel name */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelName">
                Hostel name*
              </Label>
            </strong>
            <Input
              type="text"
              value={hostelName}
              id="hostelName"
              name="hostelName"
              //placeholder="Add hostel name"
              disabled={true}
              // onChange={(e) => handleHostelName()}
            />
          </FormGroup>
        </Col>
        {/* //! Hostel state */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelState">
                Hostel state*
              </Label>
            </strong>
            <Select
              isClearable={false}
              isSearchable={true}
              id="hostelState"
              name="hostelState"
              options={indianStateList}
              value={hostelState}
              onChange={(value) => setHostelState(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Hostel region */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelRegion">
                Hostel region*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="hostelRegion"
              name="hostelRegion"
              options={HostelRegionList}
              value={hostelRegion}
              onChange={(value) => setHostelRegion(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Hostel complete address */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelCompleteAddress">
                Hostel complete address*
              </Label>
            </strong>
            <Input
              type="text"
              value={hostelCompleteAddress}
              id="hostelCompleteAddress"
              name="hostelCompleteAddress"
              //placeholder="Add hostel complete address"
              onChange={(e) => setHostelCompleteAddress(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Google Maps pin */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="googleMapsPin">
                Google Maps pin*
              </Label>
            </strong>
            <Input
              type="text"
              value={googleMapsPin}
              id="googleMapsPin"
              name="googleMapsPin"
              //placeholder="Add Google Maps pin"
              onChange={(e) => setGoogleMapsPin(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! No. of dormitory rooms */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="numOfDormRooms">
                No. of dormitory rooms*
              </Label>
            </strong>
            <Input
              type="text"
              value={numOfDormRooms}
              id="numOfDormRooms"
              name="numOfDormRooms"
              //placeholder="Add No. of dormitory rooms"
              onChange={(e) => setNumOfDormRooms(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! No. of private rooms */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="numOfPrivateRooms">
                No. of private rooms*
              </Label>
            </strong>
            <Input
              type="text"
              value={numOfPrivateRooms}
              id="numOfPrivateRooms"
              name="numOfPrivateRooms"
              //placeholder="Add No. of private rooms"
              onChange={(e) => setNumOfPrivateRooms(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! No. of dorm beds */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="dormBeds">
                No. of dorm beds*
              </Label>
            </strong>
            <Input
              type="text"
              value={dormBeds}
              id="dormBeds"
              name="dormBeds"
              //placeholder="No. of dorm beds"
              onChange={(e) => setDormBeds(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! No. of total rooms */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="numOfTotalRooms">
                No. of total rooms*
              </Label>
            </strong>
            <Input
              type="text"
              value={numOfTotalRooms}
              id="numOfTotalRooms"
              name="numOfTotalRooms"
              //placeholder="Add No. of total rooms"
              onChange={(e) => setNumOfTotalRooms(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! No. of total beds */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="numOfTotalBeds">
                No. of total beds*
              </Label>
            </strong>
            <Input
              type="text"
              value={numOfTotalBeds}
              id="numOfTotalBeds"
              name="numOfTotalBeds"
              //placeholder="Add No. of total beds"
              onChange={(e) => setNumOfTotalBeds(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! No. of staff rooms */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="numOfStaffRooms">
                No. of staff rooms*
              </Label>
            </strong>
            <Input
              type="text"
              value={numOfStaffRooms}
              id="numOfStaffRooms"
              name="numOfStaffRooms"
              //placeholder="Add No. of staff rooms"
              onChange={(e) => setNumOfStaffRooms(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! No. of inventory rooms */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="numOfInventoryRooms">
                No. of inventory rooms*
              </Label>
            </strong>
            <Input
              type="text"
              value={numOfInventoryRooms}
              id="numOfInventoryRooms"
              name="numOfInventoryRooms"
              //placeholder="Add No. of inventory rooms"
              onChange={(e) => setNumOfInventoryRooms(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Deal stage */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="dealStage">
                Deal stage*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="dealStage"
              name="dealStage"
              options={DealStageList}
              value={dealStage}
              onChange={(value) => setDealStage(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Furnishing type */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="furnishingType">
                Furnishing type*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="furnishingType"
              name="furnishingType"
              options={FurnishingTypeList}
              value={furnishingType}
              onChange={(value) => setFurnishingType(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Property type */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="propertyType">
                Property type*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="propertyType"
              name="propertyType"
              options={PropertyTypeList}
              value={propertyType}
              onChange={(value) => setPropertyType(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Hostel total area (in sq.ft.) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="hostelTotalArea">
                Hostel total area (in sq.ft.)*
              </Label>
            </strong>
            <Input
              type="text"
              value={hostelTotalArea}
              id="hostelTotalArea"
              name="hostelTotalArea"
              //placeholder="Add hostel total area (in sq.ft.)"
              onChange={(e) => setHostelTotalArea(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Construction age (in years) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="constructionAge">
                Construction age (in years)*
              </Label>
            </strong>
            <Input
              type="text"
              value={constructionAge}
              id="constructionAge"
              name="constructionAge"
              //placeholder="Add Construction age (in years)"
              onChange={(e) => setConstructionAge(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Cafe */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="cafe">
                Cafe*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="cafe"
              name="cafe"
              options={CafeList}
              value={cafe}
              onChange={(value) => setCafe(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Cafe scale */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="cafeScale">
                Cafe scale*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="cafeScale"
              name="cafeScale"
              options={CafeScaleList}
              value={cafeScale}
              onChange={(value) => setCafeScale(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Electrical connection type */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="electricalConnectionType">
                Electrical connection type*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="electricalConnectionType"
              name="electricalConnectionType"
              options={ElectricalConnectionTypeList}
              value={electricalConnectionType}
              onChange={(value) => setElectricalConnectionType(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Sanctioned electrical load (in KWH) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="sanctionedElectricalLoad">
                Sanctioned electrical load (in KWH)*
              </Label>
            </strong>
            <Input
              type="text"
              value={sanctionedElectricalLoad}
              id="sanctionedElectricalLoad"
              name="sanctionedElectricalLoad"
              //placeholder="Add sanctioned electrical load (in KWH)"
              onChange={(e) => setSanctionedElectricalLoad(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Water connection type */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="waterConnectionType">
                Water connection type*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="waterConnectionType"
              name="waterConnectionType"
              options={WaterConnectionTypeList}
              value={waterConnectionType}
              onChange={(value) => setWaterConnectionType(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Sanctioned water supply/day (in ltr) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="sanctionedWaterSupply">
                Sanctioned water supply/day (in ltr)*
              </Label>
            </strong>
            <Input
              type="text"
              value={sanctionedWaterSupply}
              id="sanctionedWaterSupply"
              name="sanctionedWaterSupply"
              //placeholder="Add Sanctioned water supply/day (in ltr)"
              onChange={(e) => setSanctionedWaterSupply(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Deal source */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="dealSource">
                Deal source*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="dealSource"
              name="dealSource"
              options={DealSourceTypeList}
              value={dealSource}
              onChange={(value) => setDealSource(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Broker name */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="brokerName">
                Broker name*
              </Label>
            </strong>
            <Input
              type="text"
              value={brokerName}
              id="brokerName"
              name="brokerName"
              //placeholder="Add broker name"
              onChange={(e) => setBrokerName(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Brokerage amount (exc. GST) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="brokerageAmount">
                Brokerage amount (exc. GST)*
              </Label>
            </strong>
            <Input
              type="text"
              value={brokerageAmount}
              id="brokerageAmount"
              name="brokerageAmount"
              //placeholder="Add brokerage amount"
              onChange={(e) => setBrokerageAmount(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Operational model */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="operationalModel">
                Operational model*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="operationalModel"
              name="operationalModel"
              options={OperationalModelTypeList}
              value={operationalModel}
              onChange={(value) => setOperationalModel(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/*//! Floor plans */}
        <Col sm="3">
          <strong>
            <Label className="form-label" for="floorPlans">
              Floor plans*
            </Label>
          </strong>
          <AttachmentComponent
            imageList={floorPlans}
            setImageList={setFloorPlans}
            editData={floorPlansImageDummyState}
            uploadType={uploadFloorPlansImageType}
          />
        </Col>
        {/*//! CAD Drawings */}
        <Col sm="3">
          <strong>
            <Label className="form-label" for="CADDrawings">
              CAD Drawings*
            </Label>
          </strong>
          <AttachmentComponent
            imageList={CADDrawings}
            setImageList={setCADDrawings}
            editData={CADDrawingsImageDummyState}
            uploadType={uploadCADDrawingsImageType}
          />
        </Col>
        {/*//! Pictures */}
        <Col sm="3">
          <strong>
            <Label className="form-label" for="pictures">
              Pictures*
            </Label>
          </strong>
          <AttachmentComponent
            imageList={pictures}
            setImageList={setPictures}
            editData={picturesImageDummyState}
            uploadType={uploadPicturesImageType}
          />
        </Col>
        {/*//! Videos */}
        <Col sm="3">
          <strong>
            <Label className="form-label" for="videos">
              Videos*
            </Label>
          </strong>
          <AttachmentComponent
            imageList={videos}
            setImageList={setVideos}
            editData={videosImageDummyState}
            uploadType={uploadVideosImageType}
          />
        </Col>
        {/*//! Title documents */}
        <Col sm="3">
          <strong>
            <Label className="form-label" for="titleDocuments">
              Title documents*
            </Label>
          </strong>
          <AttachmentComponent
            imageList={titleDocuments}
            setImageList={setTitleDocuments}
            editData={titleDocumentsImageDummyState}
            uploadType={uploadTitleDocumentsImageType}
          />
        </Col>
        {/*//! OC/CC certificate */}
        <Col sm="3">
          <strong>
            <Label className="form-label" for="OCCCCertificate">
              OC/CC certificate*
            </Label>
          </strong>
          <AttachmentComponent
            imageList={OCCCCertificate}
            setImageList={setOCCCCertificate}
            editData={OCCCCertificateImageDummyState}
            uploadType={uploadOCCCCertificateImageType}
          />
        </Col>
        {/* //! Agreement execution date */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="agreementExecutionDate">
                Agreement execution date*
              </Label>
            </strong>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={agreementExecutionDate}
              onChange={(date) =>
                setAgreementExecutionDate(
                  new Date(dateFormatter(new Date(date)))
                )
              }
              id="agreementExecutionDate"
              // placeholder="Add agreement execution date"
              required
            />
          </FormGroup>
        </Col>
        {/* //! Lessor's handover date */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="lessorHandoverDate">
                Lessor's handover date*
              </Label>
            </strong>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={lessorHandoverDate}
              onChange={(date) =>
                setLessorHandoverDate(new Date(dateFormatter(new Date(date))))
              }
              id="lessorHandoverDate"
              placeholder="Add lessor's handover date"
              required
            />
          </FormGroup>
        </Col>
        {/* //! Go live date */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="goLiveDate">
                Go live date*
              </Label>
            </strong>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={goLiveDate}
              onChange={(date) =>
                setGoLiveDate(new Date(dateFormatter(new Date(date))))
              }
              id="goLiveDate"
              // placeholder="Add go live date"
              required
            />
          </FormGroup>
        </Col>
        {/*//! Rent start date */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="rentStartDate">
                Rent start date*
              </Label>
            </strong>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={rentStartDate}
              onChange={(date) =>
                setRentStartDate(new Date(dateFormatter(new Date(date))))
              }
              id="rentStartDate"
              // placeholder="Add rent start date"
              required
            />
          </FormGroup>
        </Col>
        {/*//! Rent end date */}
        <Col sm="3">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="rentEndDate">
                Rent end date*
              </Label>
            </strong>
            <Flatpickr
              className="form-control bg-white border-dark text-dark"
              value={rentEndDate}
              onChange={(date) =>
                setRentEndDate(new Date(dateFormatter(new Date(date))))
              }
              id="rentEndDate"
              // placeholder="Add rent end date"
              required
            />
          </FormGroup>
        </Col>
        {/* //! Fit out period (in days) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Fit out period (in days)*</Label>
            </strong>
            <Input
              type="text"
              value={fitOutPeriod}
              id="fitOutPeriod"
              name="fitOutPeriod"
              //placeholder="Add fit out period"
              onChange={(e) => setFitOutPeriod(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Lessor's lock-in period (in years) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Lessor's lock-in period (in years)*
              </Label>
            </strong>
            <Input
              type="text"
              value={lessorLockInPeriod}
              id="lessorLockInPeriod"
              name="lessorLockInPeriod"
              //placeholder="Add lessor's lock-in period"
              onChange={(e) => setLessorLockInPeriod(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Lessee's lock-in period (in years) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Lessee's lock-in period (in years)*
              </Label>
            </strong>
            <Input
              type="text"
              value={lesseeLockInPeriod}
              id="lesseeLockInPeriod"
              name="lesseeLockInPeriod"
              //placeholder="Add lessee's lock-in period"
              onChange={(e) => setLesseeLockInPeriod(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! "Zero penalty grace period for Lessor's handover (in days)" */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Zero penalty grace period for Lessor's handover (in days)*
              </Label>
            </strong>
            <Input
              type="text"
              value={zeroPenaltyGracePeriod}
              id="zeroPenaltyGracePeriod"
              name="zeroPenaltyGracePeriod"
              //placeholder="Add zero penalty grace period for Lessor's handover"
              onChange={(e) => setZeroPenaltyGracePeriod(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Penalty/day on Lessor for handover delay */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Penalty/day on Lessor for handover delay*
              </Label>
            </strong>
            <Input
              type="text"
              value={penaltyPerDay}
              id="penaltyPerDay"
              name="penaltyPerDay"
              //placeholder="Add penalty/day on Lessor for handover delay"
              onChange={(e) => setPenaltyPerDay(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Termination notice period (in days) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Termination notice period (in days)*
              </Label>
            </strong>
            <Input
              type="text"
              value={terminationNotice}
              id="terminationNotice"
              name="terminationNotice"
              //placeholder="Add termination notice period"
              onChange={(e) => setTerminationNotice(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* // ! States NA */}
        {/* //! Exiting duration (in days) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Exiting duration (in days)*</Label>
            </strong>
            <Input
              type="text"
              value={exitingDuration}
              id="exitingDuration"
              name="exitingDuration"
              //placeholder="Add exiting duration"
              onChange={(e) => setExitingDuration(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Age of the hostel (in months)  */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Age of the hostel (in months) *
              </Label>
            </strong>
            <Input
              type="text"
              value={ageOfHostel}
              id="ageOfHostel"
              name="ageOfHostel"
              //placeholder="Age of the hostel"
              onChange={(e) => setAgeOfHostel(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Advance rent  */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Advance rent *</Label>
            </strong>
            <Input
              type="text"
              value={advanceRent}
              id="advanceRent"
              name="advanceRent"
              //placeholder="Advance rent"
              onChange={(e) => setAdvanceRent(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Advance rent repayment timeline (in months)  */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Advance rent repayment timeline (in months) *
              </Label>
            </strong>
            <Input
              type="text"
              value={advanceRentRepaymentTimeline}
              id="advanceRentRepaymentTimeline"
              name="advanceRentRepaymentTimeline"
              //placeholder="Advance rent"
              onChange={(e) => setAdvanceRentRepaymentTimeline(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Transformation/pre-liquidated amount  */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Transformation/pre-liquidated amount *
              </Label>
            </strong>
            <Input
              type="text"
              value={transformationPreLiquidatedAmount}
              id="transformationPreLiquidatedAmount"
              name="transformationPreLiquidatedAmount"
              //placeholder="Advance rent"
              onChange={(e) =>
                setTransformationPreLiquidatedAmount(e.target.value)
              }
            />
          </FormGroup>
        </Col>
        {/* //! Subletting rights? */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Subletting rights?*</Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="sublettingRights"
              name="sublettingRights"
              options={SublettingRightsTypeList}
              value={sublettingRights}
              onChange={(value) => setSublettingRights(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Lessor's AMC period (in months)  */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Lessor's AMC period (in months) *
              </Label>
            </strong>
            <Input
              type="text"
              value={lessorAMCPeriod}
              id="lessorAMCPeriod"
              name="lessorAMCPeriod"
              //placeholder="Advance rent"
              onChange={(e) => setLessorAMCPeriod(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Major R&M responsibility */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Major R&M responsibility*</Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="majorRMResponsibility"
              name="majorRMResponsibility"
              options={LicensesOfficialFeesResponsibilityTypeList}
              value={majorRMResponsibility}
              onChange={(value) => setMajorRMResponsibility(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Minor R&M responsibility */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Minor R&M responsibility*</Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="minorRMResponsibility"
              name="minorRMResponsibility"
              options={LicensesOfficialFeesResponsibilityTypeList}
              value={minorRMResponsibility}
              onChange={(value) => setMinorRMResponsibility(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Major R&M cure period (in days)  */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Major R&M cure period (in days) *
              </Label>
            </strong>
            <Input
              type="text"
              value={majorRMCurePeriod}
              id="majorRMCurePeriod"
              name="majorRMCurePeriod"
              //placeholder="Major R&M cure period"
              onChange={(e) => setMajorRMCurePeriod(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Force Majeure rent (1st - 3rd month)  */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Force Majeure rent (1st - 3rd month) *
              </Label>
            </strong>
            <Input
              type="text"
              value={forceMajeure1to3}
              id="forceMajeure1to3"
              name="forceMajeure1to3"
              //placeholder="Force Majeure rent (1st - 3rd month)"
              onChange={(e) => setForceMajeure1to3(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Force Majeure rent (4th - 6th month)  */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Force Majeure rent (4th - 6th month) *
              </Label>
            </strong>
            <Input
              type="text"
              value={forceMajeure4to6}
              id="forceMajeure4to6"
              name="forceMajeure4to6"
              //placeholder="Force Majeure rent (4th - 6th month)"
              onChange={(e) => setForceMajeure4to6(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Force Majeure rent (6+ months)  */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Force Majeure rent (6+ months) *
              </Label>
            </strong>

            <Input
              type="text"
              value={forceMajeure6plus}
              id="forceMajeure6plus"
              name="forceMajeure6plus"
              //placeholder="Force Majeure rent (6+ months)"
              onChange={(e) => setForceMajeure6plus(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Lessee's event of default period (in days)  */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Lessee's event of default period (in days) *
              </Label>
            </strong>
            <Input
              type="text"
              value={lesseeEODPeriod}
              id="lesseeEODPeriod"
              name="lesseeEODPeriod"
              //placeholder="Lessee's event of default period"
              onChange={(e) => setLesseeEODPeriod(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Lessor's event of default period (in days)  */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Lessor's event of default period (in days) *
              </Label>
            </strong>
            <Input
              type="text"
              value={lessorEODPeriod}
              id="lessorEODPeriod"
              name="lessorEODPeriod"
              //placeholder="Lessor's event of default period"
              onChange={(e) => setLessorEODPeriod(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Lessee's EOD cure period (in days)  */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Lessee's EOD cure period (in days) *
              </Label>
            </strong>

            <Input
              type="text"
              value={lesseeEODCure}
              id="lesseeEODCure"
              name="lesseeEODCure"
              // placeholder="Lessee's EOD cure period"
              onChange={(e) => setLesseeEODCure(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Lessor's EOD cure period (in days) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Lessor's EOD cure period (in days)*
              </Label>
            </strong>

            <Input
              type="text"
              value={lessorEODCure}
              id="lessorEODCure"
              name="lessorEODCure"
              // placeholder="Lessor's EOD cure period"
              onChange={(e) => setLessorEODCure(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Monthly rent type */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Monthly rent type*</Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="monthlyRentType"
              name="monthlyRentType"
              options={MonthlyRentTypeList}
              value={monthlyRentType}
              onChange={(value) => setMonthlyRentType(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Monthly fixed rent  */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Monthly fixed rent *</Label>
            </strong>

            <Input
              type="text"
              value={monthlyFixedRent}
              id="monthlyFixedRent"
              name="monthlyFixedRent"
              // placeholder="Monthly fixed rent "
              onChange={(e) => setMonthlyFixedRent(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Monthly revenue share   */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Monthly revenue share *</Label>
            </strong>

            <Input
              type="text"
              value={monthlyRevenueShare}
              id="monthlyRevenueShare"
              name="monthlyRevenueShare"
              // placeholder="Monthly fixed rent "
              onChange={(e) => setMonthlyRevenueShare(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Rent payable date */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Rent payable date*</Label>
            </strong>

            <Input
              type="text"
              value={rentPayableDate}
              id="rentPayableDate"
              name="rentPayableDate"
              // placeholder="Monthly fixed rent "
              onChange={(e) => setRentPayableDate(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Rental increment frequency */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Rental increment frequency*</Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="rentalIncrementFreq"
              name="rentalIncrementFreq"
              options={RentalIncrementFrequencyTypeList}
              value={rentalIncrementFreq}
              onChange={(value) => setRentalIncrementFreq(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Rental payment frequency */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Rental payment frequency*</Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="rentalPaymentFreq"
              name="rentalPaymentFreq"
              options={RentalPaymentFrequencyTypeList}
              value={rentalPaymentFreq}
              onChange={(value) => setRentalPaymentFreq(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>
        {/* //! Rental increment % */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Rental increment %*</Label>
            </strong>
            <Input
              type="text"
              value={rentalIncrementPercent}
              id="rentalIncrementPercent"
              name="rentalIncrementPercent"
              // placeholder="Rental increment %"
              onChange={(e) => setRentalIncrementPercent(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Rent per room */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Rent per room*</Label>
            </strong>

            <Input
              type="text"
              value={rentPerRoom}
              id="rentPerRoom"
              name="rentPerRoom"
              // placeholder="Rent per room"
              onChange={(e) => setRentPerRoom(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //! Rent per bed */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Rent per bed*</Label>
            </strong>

            <Input
              type="text"
              value={rentPerBed}
              id="rentPerBed"
              name="rentPerBed"
              // placeholder="Rent per bed"
              onChange={(e) => setRentPerBed(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //!  Lease duration (in years) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="leaseDuration">
                Lease duration (in years)*
              </Label>
            </strong>
            <Input
              type="text"
              value={leaseDuration}
              id="leaseDuration"
              name="leaseDuration"
              //placeholder="Add lease duration (in years)"
              onChange={(e) => setLeaseDuration(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //!  Security deposit payable (during token) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="securityDepositToken">
                Security deposit payable (during token)*
              </Label>
            </strong>
            <Input
              type="text"
              value={securityDepositToken}
              id="securityDepositToken"
              name="securityDepositToken"
              //placeholder="Add security deposit payable (during token)"
              onChange={(e) => setSecurityDepositToken(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //!  Security deposit payable (during registration) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="securityDepositRegistration">
                Security deposit payable (during registration)*
              </Label>
            </strong>
            <Input
              type="text"
              value={securityDepositRegistration}
              id="securityDepositRegistration"
              name="securityDepositRegistration"
              //placeholder="Add security deposit payable (during registration)"
              onChange={(e) => setSecurityDepositRegistration(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //!  Security deposit payable (during handover) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="securityDepositHandover">
                Security deposit payable (during handover)*
              </Label>
            </strong>
            <Input
              type="text"
              value={securityDepositHandover}
              id="securityDepositHandover"
              name="securityDepositHandover"
              //placeholder="Add Security deposit payable (during handover)"
              onChange={(e) => setSecurityDepositHandover(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //!  Security deposit payable (at rent start date) */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="securityDepositRentStart">
                Security deposit payable (at rent start date)*
              </Label>
            </strong>
            <Input
              type="text"
              value={securityDepositRentStart}
              id="securityDepositRentStart"
              name="securityDepositRentStart"
              //placeholder="Add Security deposit payable (at rent start date)"
              onChange={(e) => setSecurityDepositRentStart(e.target.value)}
            />
          </FormGroup>
        </Col>
        {/* //!  Total security deposit */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="totalSecurityDeposit">
                Total security deposit*
              </Label>
            </strong>
            <Input
              type="text"
              value={totalSecurityDeposit}
              id="totalSecurityDeposit"
              name="totalSecurityDeposit"
              //placeholder="Add total security deposit"
              onChange={(e) => setTotalSecurityDeposit(e.target.value)}
            />
          </FormGroup>
        </Col>{" "}
      </Row>

      <SaveDiscardBtnGroup />
    </form>
  );
};

export default DealBasics;
