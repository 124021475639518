import React, { useEffect, useState } from "react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import { useGetHostelsNameandId } from "../../../api/dataSource";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { Col, Row, FormGroup, Label, Button } from "reactstrap";
import AddEditModalLaundry from "./AddEditModalLaundry";
import LaundryMasterTable from "./LaundryMasterTable";
import TablePagination from "@components/common/TablePagination";
import { useGetLaundryMasterPaggintion, useGetLaundryMasterByHostelId } from "../../../api/LaundryMaster/Queries";

const LaundryMaster = () => {
  const [openModal, setOpenModal] = useState(false);
  const [HostelsList, setHostelsList] = useState([]);
  const [hostel, setHostel] = useState(null);
  const [selectedHostel, setSelectedHostel] = useState(null);
  const [id, setId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const { data: allHostelsData } = useGetHostelsNameandId();
  const { data, refetch } = useGetLaundryMasterPaggintion(
    limit,
    page,
    filter?.hostelIds,
    filter?.laundryVendorName,
    filter?.mobileNumber,
    filter?.address,
    filter?.billPaymentDueDay,
    filter?.laundryCycle,
    filter?.laundryAmountPerBedNight
  )

  const { data: laundryByHostelData, refetch: refetchLaundryByHostel } = useGetLaundryMasterByHostelId(selectedHostel);

  useEffect(() => {
    if (laundryByHostelData?.getLaundryMasterDataByHostelId) {
      setTableData(laundryByHostelData.getLaundryMasterDataByHostelId);
    } else {
      setTableData([]); 
    }
  }, [laundryByHostelData]);

  useEffect(() => {
      refetch();
    }, [page]);
  
    // useEffect(() => {
    //   setPage(1);
    // }, [hostel]);

  useEffect(() => {
    let HostelsList = allHostelsData?.getHostelsNameandId?.map((hostel) => ({
      label: hostel?.name,
      value: hostel?._id,
    }));
    if (HostelsList?.length) {
      const allowedHostels = JSON.parse(localStorage.getItem("hostels"));
      const filteredHostel =
        allowedHostels !== null && allowedHostels?.length !== 0
          ? allowedHostels?.map((hostelID) => {
              return HostelsList?.find((hostel) => hostel?.value === hostelID);
            })
          : HostelsList;
      setHostelsList(filteredHostel);
    }
  }, [allHostelsData]);

  useEffect(() => {
    if (data?.getPagginatedLaundryMaster?.data) {
      setTableData(data?.getPagginatedLaundryMaster?.data);
    } else {
      setTableData([]);
    }
  
    setTotalPage(Math.ceil(data?.getPagginatedLaundryMaster?.count / limit));
  }, [data]);

  useEffect(() => {
    if (id && data?.getPagginatedLaundryMaster?.data.length) {
      let editData = data?.getPagginatedLaundryMaster?.data?.find(
        (item) => item._id === id
      );

      setEditData(editData);
    } else {
      setId(null);
      setEditData(null);
    }
  }, [data?.getPagginatedLaundryMaster?.data, id]);

  const submitHandler = () => {
    if (Array.isArray(hostel) && hostel.length > 0) {
      const selectedHostelIds = hostel.map((h) => h.value);
      setSelectedHostel(selectedHostelIds);
      refetch({
        filter: {
          limit,
          page,
          hostelIds: selectedHostelIds,
        },
      });
    }
  };
  
  const handleToggle = () => {
    setOpenModal(!openModal);
  };

  const handleReset = () => {
    setHostel(null); 
    setSelectedHostel(null); 
    setFilter({}); 
    setId(null);
    setEditData(null);
    setPage(1);
    refetch({
      filter: {
        limit,
        page: 1, 
        hostelIds: null, 
      },
    });
  };
  
  return (
    <>
      <div>
        <Row>
          <Col sm="3">
            <FormGroup className="w-100">
              <Label className="form-label" for="product">
                Hostel
              </Label>
              <Select
                isClearable={true}
                isSearchable={true}
                theme={selectThemeColors}
                isMulti={true} 
                id="hostel"
                name="hostel"
                value={hostel}
                options={HostelsList} 
                onChange={(value) => {
                  setHostel(value); 
                }}
                classNamePrefix="select"
              />
            </FormGroup>
          </Col>
          <Col sm="2 p-2">
            <Button.Ripple
              type="submit"
              color={`${hostel ? `light` : `secondary`}`}
              appearance="success"
              onClick={submitHandler}>
              Search
            </Button.Ripple>

            <Button.Ripple
              style= {{ marginLeft: "20px" }}
              color="danger"
              type="button"
              onClick={handleReset}
            >
              Reset
            </Button.Ripple>
          </Col>
        </Row>
      </div>

    <LaundryMasterTable 
      openModal={handleToggle} 
      selectedHostel={selectedHostel}
      allHostelsData={allHostelsData} 
      data={tableData}
      setId={setId}
      id={id}
      count={data?.getPagginatedLaundryMaster?.count}
    />
    <TablePagination setPage={setPage} page={page} totalPage={totalPage} />
    <AddEditModalLaundry
      open={openModal}
      editData={editData}
      handleToggle={handleToggle}
      refetch={refetch}
      setId={setId}
    />
    </>
    
  );
};

export default LaundryMaster;
