import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_SUPPLY = gql`
  mutation ($supplyInput: SupplyInput) {
    createSupply(supplyInput: $supplyInput) {
      status
    }
  }
`;

export const useCreateSupply = () => {
  const [createSupply, { loading, error, data, reset }] =
    useMutation(CREATE_SUPPLY);

  useLoadingHandler(loading);

  const createSupplyHandler = (supplyInput) => {
    createSupply({
      variables: {
        supplyInput,
      },
    });
  };

  return [createSupplyHandler, { loading, error, data, reset }];
};

export const UPDATE_SUPPLY = gql`
  mutation ($supplyUpdate: SupplyInput) {
    updateSupply(supplyUpdate: $supplyUpdate) {
      status
    }
  }
`;

export const useUpdateSupply = () => {
  const [updateSupply, { loading, error, data, reset }] =
    useMutation(UPDATE_SUPPLY);

  useLoadingHandler(loading);

  const updateSupplyHandler = (supplyUpdate) => {
    updateSupply({
      variables: {
        supplyUpdate,
      },
    });
  };

  return [updateSupplyHandler, { loading, error, data, reset }];
};
