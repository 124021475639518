import React, { useState } from 'react';
import { Row, Col, Card, Button, CardHeader, CardTitle, Table } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useCreateLaundryTrackerData, useUpdateLaundryTracker } from '../../../api/LaundryTracker/Mutation';
import LogsModal from "./LogsModal";

const LaundryTrackerTable = (props) => {
  const { masterData, tableHeaderData, setId, data, dateArray, refetch } = props;
  const [openLogs, setOpenLogs] = useState(false);
  const [inputData, setInputData] = useState({});
  const [selectedLogs, setSelectedLogs] = useState(null);
  const [createLaundryTrackerDataHandler] = useCreateLaundryTrackerData();
  const [updateLaundryTrackerHandler] = useUpdateLaundryTracker();

  const headerStyle = {
    fontSize: '12px',
    whiteSpace: 'nowrap',
    textAlign: 'center',
  };

  const tableStartFrom = 2;

  const handleSave = async (itemName, date) => {
    const quantity = inputData[`${itemName}-${date}`];
    if (!quantity) return;
  
    const existingQuantity = getInitialValue(itemName, date);
  
    const laundryData = {
      hostelId: masterData?.hostelId?._id,
      laundryVendorId: masterData?._id,
      itemName,
      date,
      quantity: Number(quantity),
      logs: [],
    };
  
    try {
      if (existingQuantity) {
        const matchedData = data.find(
          (item) =>
            item.itemName === itemName &&
            formatTimestampToDate(item.date) === date
        );
        if (matchedData && matchedData._id) {
          await updateLaundryTrackerHandler(matchedData._id, laundryData);
        }
      } else {
        await createLaundryTrackerDataHandler(laundryData);
      }
      refetch()
    } catch (error) {
      throw error
    }
  };

  const formatTimestampToDate = (timestampString) => {
    const timestamp = Number(timestampString);
    const date = new Date(timestamp);
    return date.toISOString().split('T')[0];
  };
  
  const getInitialValue = (itemName, date) => {
    if (!Array.isArray(data)) return '';
  
    const matchedData = data.find((item) => {
      const isItemNameMatched = item.itemName === itemName;
  
      const formattedDate = formatTimestampToDate(item.date);
      const isDateMatched = formattedDate === date;
  
      return (
        item.hostelId?._id === masterData?.hostelId?._id &&
        item.laundryVendorId === masterData?._id &&
        isItemNameMatched &&
        isDateMatched
      );
    });
  
    return matchedData ? matchedData?.quantity : '';
  };

  const getDataOfLogs = (date) => {
    if (!Array.isArray(data)) return [];
  
    const matchedDataLogs = data.filter((item) => {
      const formattedDate = formatTimestampToDate(item.date);
      return formattedDate === date;
    });
  
    return matchedDataLogs;
  };
  
  const calculateMonthlyTotal = (itemName) => {
    return dateArray.reduce((total, date) => {
      const key = `${itemName}-${date.label}`;
      const inputValue = Number(inputData[key] || getInitialValue(itemName, date.label) || 0);
      return total + inputValue;
    }, 0);
  };
  
  return (
    <Row>
      {setId && (
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>Laundry Tracker Data</CardTitle>
            </CardHeader>

            <div>
              <Table size="sm" responsive hover>
                <thead>
                  <tr>
                    {tableHeaderData?.map((item, index) => (
                      <th key={uuidv4()} style={headerStyle}>
                        {index < tableStartFrom ? (
                          item.label
                        ) : (
                          <>
                            {new Date(item.label)?.toLocaleDateString('en-IN')}
                            <br />
                            {new Date(item.label).toLocaleString('en-US', {
                              weekday: 'short',
                            })}
                          </>
                        )}
                      </th>
                    ))}
                    {tableHeaderData?.length > 0 && (
                      <>
                        <th key={uuidv4()} style={headerStyle}>
                          Monthly qty. total
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          Actual total (as per bill)
                        </th>
                        <th key={uuidv4()} style={headerStyle}>
                          Difference
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {masterData?.itemRates?.map((rate) => (
                    <tr>
                      <td style={headerStyle}>{rate.itemName}</td>
                      <td style={headerStyle}>{rate.itemRate}</td>
                      {dateArray.map((date) => (
                        <td style={headerStyle}>
                          <input
                            type="number"
                            style={{
                              width: '60px',
                              fontSize: '12px',
                              textAlign: 'center',
                              padding: '2px',
                            }}
                            value={
                              inputData[`${rate.itemName}-${date.label}`] ??
                              getInitialValue(rate.itemName, date.label)
                            }
                            onChange={(e) =>
                              setInputData((prevData) => ({
                                ...prevData,
                                [`${rate.itemName}-${date.label}`]: e.target.value,
                              }))
                            }
                          />
                        </td>
                      ))}
                      <td style={headerStyle}>{calculateMonthlyTotal(rate.itemName)}</td>
                      <td style={headerStyle}>
                        {/* Actual total (as per bill) */}
                      </td>
                      <td style={headerStyle}>
                        {/* Difference */}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td></td>
                    {dateArray.map((date) => (
                      <td key={uuidv4()} style={{ textAlign: 'center' }}>
                        <Button.Ripple
                          type="button"
                          color="success"
                          onClick={() => {
                            if (masterData?.itemRates?.length) {
                              masterData.itemRates.forEach((rate) => {
                                handleSave(rate.itemName, date.label);
                              });
                            } else {
                              console.error('No item rates available to save.');
                            }
                          }}
                        >
                          Save
                        </Button.Ripple>
                      </td>
                    ))}
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td></td>
                    <td></td>
                    {dateArray.map((date) => (
                      <td key={uuidv4()} style={{ textAlign: 'center' }}>
                        <Button.Ripple
                          type="button"
                          color="warning"
                          onClick={() => {
                            const logs = getDataOfLogs(date.label); 
                            setSelectedLogs({logs, date: date.label}); 
                            setOpenLogs(true); 
                          }}
                        >
                          Logs
                        </Button.Ripple>
                      </td>
                    ))}
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </Card>
        </Col>
      )}

      <LogsModal
        showModal={openLogs}
        data={selectedLogs?.logs}
        date={selectedLogs?.date}
        onClose={setOpenLogs}
      />
    </Row>
  );
};

export default LaundryTrackerTable;
