import { Edit, FileText } from "react-feather";
import { Col, Card, Row, CardHeader, CardTitle, Button } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { Table } from "reactstrap";
import { reactFeatherIcon } from "../../../styles/reactFeatherIcon";

const tableHeaderData = [
  "Action",
  "Hostel Name",
  // "Employee Name",
  // "Designation",
  // "Employee Type",
  // "Employee Traning",
];

const SupplyTable = ({
  openModal,
  setId,
  data,
  count,
  handleCreateUpdateSupply,
}) => {
  const userRole = localStorage.getItem("role");

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader style={{ display: "flex" }}>
            <CardTitle>
              <div>
                <h2> Supply List</h2>
              </div>
            </CardTitle>

            <Button.Ripple
              color="light"
              onClick={(e) => {
                e.preventDefault();
                openModal();
              }}
            >
              Add Supply info.
            </Button.Ripple>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr>
                {tableHeaderData.map((item) => {
                  return <th key={uuidv4()}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {/* {data?.map((item) => ( */}
              <tr key={uuidv4()}>
                {/* <td style={{ width: "10px" }}>
                  <Button
                    className="rounded-circle btn-icon"
                    color="none"
                    onClick={(e) => {
                      e.preventDefault();
                      // setId(item._id);
                      setId("test");
                      openModal();
                    }}
                  >
                    <Edit
                      color={reactFeatherIcon.iconColor}
                      size={reactFeatherIcon.iconSize}
                    />
                  </Button>
                </td> */}
                <td>
                  <Button
                    className="rounded-circle btn-icon"
                    color="none"
                    onClick={(e) => {
                      e.preventDefault();
                      // handleCreateUpdateEmployeeProfile(item?._id);
                      handleCreateUpdateSupply("test");
                    }}
                  >
                    <FileText
                      color={reactFeatherIcon.iconColor}
                      size={reactFeatherIcon.iconSize}
                    />
                  </Button>
                </td>
                <td>{"Hostel Property"}</td>
              </tr>
              {/* ))} */}
            </tbody>
          </Table>
        </Card>
      </Col>
    </Row>
  );
};

export default SupplyTable;
