export const fieldDependencies = {
  "No. of beds": {
    // calculateTotalInventory: true,
    calculateRentalCostPerBed: true,
  },
  month: {
    calculateTotalInventory: true,
    calculateHostelStaffCount: true,
    calculateCafeStaffCount: true,
  },
  year: {
    calculateTotalInventory: true,
    calculateHostelStaffCount: true,
    calculateCafeStaffCount: true,
  },
  "No. of rooms": {
    calculateTotalInventory: true,
  },
  "Bed Revenue": {
    calculateTotalRevenue: true,
    // calculateOTA: true,
    calculateHostelMargin: true,
    calculateHostelMarginPercentage: true,
    calculateRevRentalMultiple: true,
    calculateOTAPercentage: true,
    calculateUtilitiesTotalRev: true,
    calculateRMTotalRev: true,
    calculateMiscTotalRev: true,
    calculateHostelStaffSalaryPerBedSales: true,
  },
  "F&B Revenue": {
    calculateTotalRevenue: true,
    calculateFBAOV: true,
    calculateFBMarginInINR: true,
    calculateFBMarginPercentage: true,
    calculateFNBStaffSalaryPerFNBSales: true,
    calculateFNBFoodCostPerFNBRevenue: true,
  },
  "Ancillary Revenue": {
    calculateTotalRevenue: true,
    calculateAncillaryMarginInInr: true,
    calculateAncillaryMarginInPercentage: true,
  },
  "T S BN": {
    calculateOccupancyPercentage: true,
    calculateFBOrderPerBedNightSold: true,
  },
  "T A BN": {
    calculateOccupancyPercentage: true,
  },
  "Total Revenue": {
    calculateOverallOpMargin: true,
    calculateAnalysisOperationalMarginPercentage: true,
  },
  "F&B Orders": {
    calculateFBAOV: true,
    calculateFBOrderPerBedNightSold: true,
  },
  "F&B AOV (in INR)": {},
  "F&B Order Per Bed Night Sold": {},
  "Occ%": {},
  "Total Inventory": {},

  Rent: {
    calculateTotalOPEX: true,
    calculateRentalCostPerBed: true,
    calculateHostelMargin: true,
    calculateRevRentalMultiple: true,
  },
  "U1: Electricity + Fuel + Genset": {
    calculateTotalOPEX: true,
    calculateHostelMargin: true,
    calculateUtilitiesTotalRev: true,
  },
  "U2: Water + Tanker": {
    calculateTotalOPEX: true,
    calculateHostelMargin: true,
    calculateUtilitiesTotalRev: true,
  },
  "U3: TV & Internet + Telephone + SW subscriptions": {
    calculateTotalOPEX: true,
    calculateHostelMargin: true,
    calculateUtilitiesTotalRev: true,
  },
  "U4: Toiletry": {
    calculateTotalOPEX: true,
    calculateHostelMargin: true,
  },
  "U5: Laundry": {
    calculateTotalOPEX: true,
    calculateHostelMargin: true,
  },
  "R&M": {
    calculateTotalOPEX: true,
    calculateHostelMargin: true,
    calculateRMTotalRev: true,
  },
  "Misc. (local travel General liasing etc.)(Courier charges + Freight Charges + General Exp +Print & Stationery +Staff Travel Exp)":
    {
      calculateTotalOPEX: true,
      calculateHostelMargin: true,
      calculateMiscTotalRev: true,
    },
  OTA: {
    calculateTotalOPEX: true,
    calculateHostelMargin: true,
    calculateOTAPercentage: true,
  },
  "Total salary": {
    calculateTotalOPEX: true,
    calculateHostelMargin: true,
    calculateHostelStaffSalaryPerBedSales: true,
  },
  "F&B salary + F&B welfare": {
    calculateTotalOPEX: true,
    calculateCafeSalaryPerManday: true,
    calculateFBMarginInINR: true,
    calculateFNBStaffSalaryPerFNBSales: true,
  },
  "F&B material cost (= OS + P - CS - HSW - CSW)": {
    calculateTotalOPEX: true,
    calculateFBMarginInINR: true,
    calculateFNBFoodCostPerFNBRevenue: true,
  },
  "Ancillary cost": {
    calculateTotalOPEX: true,
    calculateAncillaryMarginInInr: true,
  },
  "Total OPEX": {
    calculateAnalysisOperationalMarginPercentage: true,
    calculateOverallOpMargin: true,
  },
  "Rental Cost Per Bed": {},

  "Hostel salary (Own)": {
    calculateTotalSalary: true,
    calculateHostelSalaryPerManday: true,
  },
  "Hostel salary (Agency)": {
    calculateTotalSalary: true,
    calculateHostelSalaryPerManday: true,
  },
  "Hostel welfare (Staff food room rent & other)": {
    calculateTotalSalary: true,
    calculateHostelSalaryPerManday: true,
  },
  "Hostel Mandays": {
    calculateNoOfHostelStaff: true,
    calculateCafeSalaryPerManday: true,
    calculateHostelStaffCount: true,
  },
  "Total Days": {
    calculateNoOfHostelStaff: true,
  },
  "No. of Hostel Staff": {},
  "Hostel Salary Per Manday": {},

  "Cafe Mandays": {
    calculateNumberOfCafeStaff: true,
    calculateCafeSalaryPerManday: true,
    calculateCafeStaffCount: true,
  },
  days: {
    calculateNumberOfCafeStaff: true,
  },
  "Number of Cafe Staff": {},

  "Cafe Salary Per Manday": { calculateFBMarginInINR: true },

  "I&P payout": {
    calculateOtherPayouts: true,
  },
  "Franchise payout": {
    calculateOtherPayouts: true,
    calculateTotalOPEX: true,
  },
  "Other Payouts": {
    calculateHostelMargin: true,
    calculateAnalysisOperationalMarginPercentage: true,
    calculateOverallOpMargin: true,
  },

  "Overall Op. Margin (in INR)": {
    calculateAnalysisOperationalMarginPercentage: true,
  },

  "Hostel margin (in INR)": { calculateHostelMarginPercentage: true },

  "Hostel margin (in %)": {},

  "F&B margin (in INR)": { calculateFBMarginPercentage: true },

  "F&B margin (in %)": {},

  "Ancillary margin (in INR)": {
    calculateAncillaryMarginInPercentage: true,
  },

  "Ancillary margin (in %)": {},

  "Analysis (Operational Margin %)": {},

  "Rev/Rental multiple": {},

  "OTA %": {},

  "Utilities/Total Rev": {},

  "R&M/Total Rev": {},

  "Misc/Total Rev": {},

  "Hostel staff salary/(bed sales)": {},

  "F&B staff salary/(F&B sales)": {},

  "F&B food cost/(F&B sales)": {},

  "# of Hostel Staff": {},

  "# of Cafe Staff": {},
};
