import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const CREATE_LAUNDRY_TRACKER_DATA = gql`
    mutation createLaundryTrackerData($LaundryTrackerDataInput:LaundryTrackerDataInput) {
        createLaundryTrackerData(LaundryTrackerDataInput:$LaundryTrackerDataInput) {
            hostelId {
            _id
            name
        }
            laundryVendorId
            itemName
            date
            quantity
            logs
        }
    }
`;

export const useCreateLaundryTrackerData = () => {
    const [createLaundryTrackerData, { loading, error, data, refetch }] =
        useMutation(CREATE_LAUNDRY_TRACKER_DATA);

    useLoadingHandler(loading);

    const createLaundryTrackerDataHandler = (LaundryTrackerDataInput) => {
        createLaundryTrackerData({
            variables: { LaundryTrackerDataInput },
        });
    };

    return [createLaundryTrackerDataHandler, { loading, error, data, refetch }];
};

export const UPDATE_LAUNDRY_TRACKER_MASTER = gql`
  mutation updateLaundryTrackerData($id: ID! $LaundryTrackerDataUpdate: LaundryTrackerDataInput) {
    updateLaundryTrackerData(id: $id LaundryTrackerDataUpdate: $LaundryTrackerDataUpdate) {
        hostelId {
            _id
            name
        }
            laundryVendorId
            itemName
            date
            quantity
            logs
        }
  }
`;

export const useUpdateLaundryTracker = () => {
  const [updateLaundryTrackerData, { loading, error, data, refetch }] = useMutation(
    UPDATE_LAUNDRY_TRACKER_MASTER
  );

  useLoadingHandler(loading);

  const updateLaundryTrackerHandler = async (id, LaundryTrackerDataUpdate) => {
    return await updateLaundryTrackerData({
      variables: { id, LaundryTrackerDataUpdate },
    });
  };

  return [updateLaundryTrackerHandler, { loading, error, data, refetch }];
};