import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_LAUNDRY_TRACKER_BY_HOSTELID = gql`
  query ($hostelId: ID) {
    getLaundryTrackerDataByHostelId(hostelId: $hostelId) {
      _id
      hostelId{
        _id
        name
      }
      laundryVendorId
      itemName
      date
      quantity
      logs
    }
  }
`;

export const useGetLaundryTrackerByHostelId = (hostelId) => {
  const { loading, error, data, refetch } = useQuery(GET_LAUNDRY_TRACKER_BY_HOSTELID, 
    {
        variables: { hostelId },
        skip: !hostelId,
    }
  );
  useLoadingHandler(loading);
  return { loading, error, data, refetch };
};