import { gql, useQuery } from "@apollo/client";
import { useLoadingHandler } from "../dataSource";

export const GET_PAGINATED_PL_STATEMENTS = gql`
  query getPaginatedPLStatement($filter: PLStatementFilter!) {
    getPaginatedPLStatement(filter: $filter) {
      data {
        _id
        month
        year
        hostelId
        hostelName
        hostelCode
        operationalModel
        areaManager
        overallRemarks
        remarksOnOps
        remarksOnCX
        remarksOnPosSales
        actionsFollowups
        logStatements
        fields {
          fieldName
          fieldValue
          isCritical
          isProvisional
          remark
          breakup
        }
        createdAt
        updatedAt
      }
      page
      limit
      count
    }
  }
`;

export const useGetPaginatedPLStatements = (limit, page, filterFields) => {
  const shouldSkip = !limit || !page || !filterFields;

  const { loading, error, data, refetch } = useQuery(
    GET_PAGINATED_PL_STATEMENTS,
    {
      variables: {
        filter: {
          limit,
          page,
          filterFields,
        },
      },
      skip: shouldSkip,
    }
  );

  useLoadingHandler(loading);

  return {
    loading,
    error,
    data,
    refetch,
  };
};

export const useGetAggregatedPLStatement = (filterFields) => {
  const shouldSkip = !filterFields;

  const { loading, error, data, refetch } = useQuery(
    GET_AGGREGATED_PL_STATEMENT,
    {
      variables: {
        filter: { filterFields },
      },
      skip: shouldSkip,
    }
  );

  useLoadingHandler(loading);

  return {
    loading,
    error,
    data,
    refetch,
  };
};

export const GET_AGGREGATED_PL_STATEMENT = gql`
  query getAggregatedPLStatement($filter: PLStatementFilter!) {
    getAggregatedPLStatement(filter: $filter) {
      calculatedValues
      totalAggregatedValues
    }
  }
`;

export const GET_INVESTOR_DATA = gql`
  query {
    getInvestorData {
      _id
      month
      year
      noOfHostels
      noOfCocoHotels
      noOfFocoOmcHostels
      noOfFofoHostels
      noOfBeds
      noOfCocoHotelBeds
      noOfFocoOmcHostelBeds
      noOfFofoHostelBeds
      totalAvailableBedNights
      cocoBedNights
      focoOmcBedNights
      fofoBedNights
      totalOccupancy
      cocoOccupancy
      focoOmcOccupancy
      fofoOccupancy
      totalSoldBedNights
      cocoSoldBedNights
      focoOmcSoldBedNights
      fofoSoldBedNights
      adrOverall
      adrCoco
      adrFocoOmc
      adrFofo
      totalPxOverall
      noOfPxCoco
      noOfPxFocoOmc
      noOfPxFofo
      cocoBedRevenue
      cocoCafeRevenue
      cocoAncillaryRevenue
      cocoTravelRevenue
      cocoGrossRevenue
      cocoRentals
      cocoHostelSalary
      cocoUtilities
      cocoCommissions
      cocoMiscellaneous
      cocoCafe
      cocoAncillary
      cocoTravel
      cocoGrossExpense
      cocoOperationalMargin
      cocoOperationalMarginPercentage
      focoOmcBedRevenue
      focoOmcCafeRevenue
      focoOmcAncillaryRevenue
      focoOmcTravelRevenue
      focoOmcGrossRevenue
      focoOmcRentals
      focoOmcHostelSalary
      focoOmcUtilities
      focoOmcCommissions
      focoOmcMiscellaneous
      focoOmcCafe
      focoOmcAncillary
      focoOmcPartnerPayout
      focoOmcGrossExpense
      focoOmcOperationalMargin
      focoOmcOperationalMarginPercentage
      fofoBedRevenue
      fofoCafeRevenue
      fofoAncillaryRevenue
      fofoTravelRevenue
      fofoGrossRevenue
      fofoRoyalty
      fofoPlatformFees
      fofoPartnerPayout
      fofoAncillary
      fofoGrossExpense
      fofoOperationalMargin
      fofoOperationalMarginPercentage
      hoRevenue
      hoMembership
      hoOtherIncome
      hoGrossRevenue
      hoEmployeeBenefit
      hoMarketing
      hoTravelling
      hoTechSubscriptions
      hoOfficeRentals
      hoUtilities
      hoLegal
      hoMiscellaneous
      hoGrossExpense
      totalGmv
      grossRevenue
      operationalExpenses
      ebitda
      ebitdaPercentage
      financeCost
      oneTimeExpenses
      depreciation
      assetRental
      notableOneTimeExp
      pbt
      pbtPercentage
      cashBankBalance
      totalArpc
      nonBedArpc
      experiencesArpc
      otherBedRevRatio
      customerAcquisitionCost
      cacPercentage
      rentalsToRevenueRatio
      staffWelfareToRevenueRatio
      utilitiesToRevenueRatio
      hoExpenseToRevenueRatio
      bedMarginsPercentage
      cafeMarginsPercentage
      centralTravelMarginsPercentage
      commissionableBookingsPercentage
      nonCommissionableBookingsPercentage
      brandScore
      totalReviews
      bookingSourceBookingCom
      bookingSourceGoMmt
      bookingSourceHostelworld
      bookingSourceAgoda
      bookingSourceGoogle
      bookingSourceWebsite
      bookingSourceWalkin
      bookingSourceExtension
      bookingSourceThHo
      bookingSourceB2B
      bookingSourceTravelDesk
      bookingSourceOthers
    }
  }
`;

export const useGetInvestorData = () => {
  const { loading, error, data, refetch } = useQuery(GET_INVESTOR_DATA);

  useLoadingHandler(loading);

  return { loading, error, data, refetch };
};
