import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
// import toaster from "@components/common/Toaster";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import Select from "react-select";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import AttachmentComponent from "../AttachmentComponent";
// import { tableDataHandler } from "@store/Cms/cms.action";
// import { dateFormatter } from "@utils";
// import Flatpickr from "react-flatpickr";

import { indianStateList } from "../../../../utility/Utils";
import {
  LessorEntityTypeList,
  BankAccountTypeList,
} from "../selectOptionsList";

const DealBasics = () => {
  //   const { id } = useParams();
  //   const dispatch = useDispatch();
  const [lessorEntityType, setLessorEntityType] = useState("");
  const [lessorEntityFullName, setLessorEntityFullName] = useState("");
  const [lessorAge, setLessorAge] = useState("");
  const [lessorFatherName, setLessorFatherName] = useState("");
  const [lessorCompleteAddress, setLessorCompleteAddress] = useState("");
  const [lessorAadharNumber, setLessorAadharNumber] = useState("");
  const [lessorAadharCard, setLessorAadharCard] = useState([]);
  const [lessorPANNumber, setLessorPANNumber] = useState("");
  const [lessorPANCard, setLessorPANCard] = useState([]);
  const [lessorPartnershipPercent, setLessorPartnershipPercent] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccountType, setBankAccountType] = useState("");
  const [bankBeneficiaryName, setBankBeneficiaryName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankIfscCode, setBankIfscCode] = useState("");
  const [cancelledCheque, setCancelledCheque] = useState([]);
  const [llpRegistrationNumber, setLlpRegistrationNumber] = useState("");
  const [llpRegistrationCertificate, setLlpRegistrationCertificate] = useState(
    []
  );
  const [llpRegisteredAddress, setLlpRegisteredAddress] = useState("");
  const [llpRepresentativeName, setLlpRepresentativeName] = useState("");
  const [llpRepresentativeAddress, setLlpRepresentativeAddress] = useState("");
  const [llpRepresentativeAadharNumber, setLlpRepresentativeAadharNumber] =
    useState("");
  const [llpRepresentativeAadharCard, setLlpRepresentativeAadharCard] =
    useState([]);
  const [llpRepresentativeDIN, setLlpRepresentativeDIN] = useState("");

  const [pocFullName, setPOCFullName] = useState("");
  const [pocMobileNumber, setPOCMobileNumber] = useState("");
  const [pocEmail, setPOCEmail] = useState("");
  const [pocAadharNumber, setPOCAadharNumber] = useState("");
  const [pocAadharCard, setPOCAadharCard] = useState([]);

  //!Images state
  const [lessorAadharCardImageDummyState] = useState(null);
  const [lessorPANCardImageDummyState] = useState(null);
  const [cancelledChequeImageDummyState] = useState(null);
  const [llpRegistrationCertificateImageDummyState] = useState(null);
  const [llpRepresentativeAadharCardImageDummyState] = useState(null);
  const [POCAadharCardImageDummyState] = useState(null);

  const uploadLessorAadharCardImageType = [
    { label: "imageURL", value: "lessorAadharCardImage" },
  ];
  const uploadLessorPANCardImageType = [
    { label: "imageURL", value: "lessorPANCardImage" },
  ];
  const uploadCancelledChequeImageType = [
    { label: "imageURL", value: "cancelledChequeImage" },
  ];
  const uploadLlpRegistrationCertificateImageType = [
    { label: "imageURL", value: "llpRegistrationCertificateImage" },
  ];
  const uploadLlpRepresentativeAadharCardImageType = [
    { label: "imageURL", value: "LlpRepresentativeAadharCardImage" },
  ];
  const uploadPOCAadharCardImageType = [
    { label: "imageURL", value: "pocAadharCardImage" },
  ];

  return (
    <form className="mt-2">
      <Row>
        {/* //! Lessor's entity type */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="lessorEntityType">
                Lessor's entity type*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="lessorEntityType"
              name="lessorEntityType"
              options={LessorEntityTypeList}
              value={lessorEntityType}
              onChange={(value) => setLessorEntityType(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>

        {/* //! Lessor's entity full name */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="lessorEntityFullName">
                Lessor's entity full name*
              </Label>
            </strong>
            <Input
              type="text"
              value={lessorEntityFullName}
              id="lessorEntityFullName"
              name="lessorEntityFullName"
              //placeholder="Add lessor's entity full name"
              onChange={(e) => setLessorEntityFullName(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //! Lessor's age */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="lessorAge">
                Lessor's age*
              </Label>
            </strong>
            <Input
              type="text"
              value={lessorAge}
              id="lessorAge"
              name="lessorAge"
              //placeholder="Add lessor's age"
              onChange={(e) => setLessorAge(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //! Lessor's father name */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="lessorFatherName">
                Lessor's father name*
              </Label>
            </strong>
            <Input
              type="text"
              value={lessorFatherName}
              id="lessorFatherName"
              name="lessorFatherName"
              //placeholder="Add lessor's father name"
              onChange={(e) => setLessorFatherName(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //! Lessor's complete address */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="lessorCompleteAddress">
                Lessor's complete address*
              </Label>
            </strong>
            <Input
              type="text"
              value={lessorCompleteAddress}
              id="lessorCompleteAddress"
              name="lessorCompleteAddress"
              //placeholder="Add lessor's complete address"
              onChange={(e) => setLessorCompleteAddress(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //! Lessor's aadhar number */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="lessorAadharNumber">
                Lessor's aadhar number*
              </Label>
            </strong>
            <Input
              type="text"
              value={lessorAadharNumber}
              id="lessorAadharNumber"
              name="lessorAadharNumber"
              //placeholder="Add lessor's aadhar number"
              onChange={(e) => setLessorAadharNumber(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*//! Lessor's aadhar card */}
        <Col sm="3">
          <strong>
            <Label className="form-label" for="lessorAadharCard">
              Lessor's aadhar card*
            </Label>
          </strong>
          <AttachmentComponent
            imageList={lessorAadharCard}
            setImageList={setLessorAadharCard}
            editData={lessorAadharCardImageDummyState}
            uploadType={uploadLessorAadharCardImageType}
          />
        </Col>

        {/* //! Lessor's PAN number */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="lessorPANNumber">
                Lessor's PAN number*
              </Label>
            </strong>
            <Input
              type="text"
              value={lessorPANNumber}
              id="lessorPANNumber"
              name="lessorPANNumber"
              //placeholder="Add lessor's PAN number"
              onChange={(e) => setLessorPANNumber(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*//! Lessor's PAN card */}
        <Col sm="3">
          <strong>
            <Label className="form-label" for="lessorPANCard">
              Lessor's PAN card*
            </Label>
          </strong>
          <AttachmentComponent
            imageList={lessorPANCard}
            setImageList={setLessorPANCard}
            editData={lessorPANCardImageDummyState}
            uploadType={uploadLessorPANCardImageType}
          />
        </Col>

        {/* //! Lessor's partnership % */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="lessorPartnershipPercent">
                Lessor's partnership %*
              </Label>
            </strong>
            <Input
              type="text"
              value={lessorPartnershipPercent}
              id="lessorPartnershipPercent"
              name="lessorPartnershipPercent"
              //placeholder="Add lessor's partnership %"
              onChange={(e) => setLessorPartnershipPercent(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //! Bank name */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="bankName">
                Bank name*
              </Label>
            </strong>
            <Input
              type="text"
              value={bankName}
              id="bankName"
              name="bankName"
              //placeholder="Add bank name"
              onChange={(e) => setBankName(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //! Bank account type */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="bankAccountType">
                Bank account type*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="bankAccountType"
              name="bankAccountType"
              options={BankAccountTypeList}
              value={bankAccountType}
              onChange={(value) => setBankAccountType(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>

        {/* //! Bank account beneficiary name */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="bankBeneficiaryName">
                Bank account beneficiary name*
              </Label>
            </strong>

            <Select
              isClearable={false}
              isSearchable={true}
              id="bankBeneficiaryName"
              name="bankBeneficiaryName"
              options={BankAccountTypeList}
              value={bankBeneficiaryName}
              onChange={(value) => setBankBeneficiaryName(value)}
              classNamePrefix="select"
              placeholder=""
            />
          </FormGroup>
        </Col>

        {/* //!  Bank account number */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="bankAccountNumber">
                Bank account number*
              </Label>
            </strong>
            <Input
              type="text"
              value={bankAccountNumber}
              id="bankAccountNumber"
              name="bankAccountNumber"
              //placeholder="Add bank account number"
              onChange={(e) => setBankAccountNumber(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //!  Bank IFSC code */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="bankIfscCode">
                Bank IFSC code*
              </Label>
            </strong>
            <Input
              type="text"
              value={bankIfscCode}
              id="bankIfscCode"
              name="bankIfscCode"
              //placeholder="Add bank IFSC code"
              onChange={(e) => setBankIfscCode(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*//! Cancelled cheque */}
        <Col sm="3">
          <strong>
            <Label className="form-label" for="cancelledCheque">
              Cancelled cheque*
            </Label>
          </strong>
          <AttachmentComponent
            imageList={cancelledCheque}
            setImageList={setCancelledCheque}
            editData={cancelledChequeImageDummyState}
            uploadType={uploadCancelledChequeImageType}
          />
        </Col>

        {/* //!  Lessor's LLP registration number */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="llpRegistrationNumber">
                Lessor's LLP registration number*
              </Label>
            </strong>
            <Input
              type="text"
              value={llpRegistrationNumber}
              id="llpRegistrationNumber"
              name="llpRegistrationNumber"
              //placeholder="Add lessor's LLP registration number"
              onChange={(e) => setLlpRegistrationNumber(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*//! Lessor's LLP registration certificate */}
        <Col sm="3">
          <strong>
            <Label className="form-label" for="llpRegistrationCertificate">
              Lessor's LLP registration certificate*
            </Label>
          </strong>
          <AttachmentComponent
            imageList={llpRegistrationCertificate}
            setImageList={setLlpRegistrationCertificate}
            editData={llpRegistrationCertificateImageDummyState}
            uploadType={uploadLlpRegistrationCertificateImageType}
          />
        </Col>

        {/* //!  Lessor's LLP registered address */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="llpRegisteredAddress">
                Lessor's LLP registered address*
              </Label>
            </strong>
            <Input
              type="text"
              value={llpRegisteredAddress}
              id="llpRegisteredAddress"
              name="llpRegisteredAddress"
              //placeholder="Add lessor's LLP registered address"
              onChange={(e) => setLlpRegisteredAddress(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //!  Lessor's LLP authorised representative's full name */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="llpRepresentativeName">
                Lessor's LLP authorised representative's full name*
              </Label>
            </strong>
            <Input
              type="text"
              value={llpRepresentativeName}
              id="llpRepresentativeName"
              name="llpRepresentativeName"
              //placeholder="Add lessor's LLP authorised representative's full name"
              onChange={(e) => setLlpRepresentativeName(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //!  Lessor's LLP authorised representative's complete address */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="llpRepresentativeAddress">
                Lessor's LLP authorised representative's complete address*
              </Label>
            </strong>
            <Input
              type="text"
              value={llpRepresentativeAddress}
              id="llpRepresentativeAddress"
              name="llpRepresentativeAddress"
              //placeholder="Add lessor's LLP authorised representative's complete address"
              onChange={(e) => setLlpRepresentativeAddress(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //!  Lessor's LLP authorised representative's aadhar number */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="llpRepresentativeAadharNumber">
                Lessor's LLP authorised representative's aadhar number*
              </Label>
            </strong>
            <Input
              type="text"
              value={llpRepresentativeAadharNumber}
              id="llpRepresentativeAadharNumber"
              name="llpRepresentativeAadharNumber"
              //placeholder="Add lessor's LLP authorised representative's aadhar number"
              onChange={(e) => setLlpRepresentativeAadharNumber(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*//! Lessor's LLP authorised representative's aadhar card */}
        <Col sm="3">
          <strong>
            <Label className="form-label" for="llpRepresentativeAadharCard">
              Lessor's LLP authorised representative's aadhar card*
            </Label>
          </strong>
          <AttachmentComponent
            imageList={llpRepresentativeAadharCard}
            setImageList={setLlpRepresentativeAadharCard}
            editData={llpRepresentativeAadharCardImageDummyState}
            uploadType={uploadLlpRepresentativeAadharCardImageType}
          />
        </Col>

        {/* //!  Lessor's LLP authorised representative's DIN number */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label" for="llpRepresentativeDIN">
                Lessor's LLP authorised representative's DIN number*
              </Label>
            </strong>
            <Input
              type="text"
              value={llpRepresentativeDIN}
              id="llpRepresentativeDIN"
              name="llpRepresentativeDIN"
              //placeholder="Add lessor's LLP authorised representative's DIN number"
              onChange={(e) => setLlpRepresentativeDIN(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //! Lessor's POC's full name */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Lessor's POC's full name*</Label>
            </strong>
            <Input
              type="text"
              value={pocFullName}
              id="pocFullName"
              name="pocFullName"
              //placeholder="Add lessor's LLP authorised representative's DIN number"
              onChange={(e) => setPOCFullName(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //! Lessor's POC's mobile number */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Lessor's POC's mobile number*
              </Label>
            </strong>
            <Input
              type="text"
              value={pocMobileNumber}
              id="pocMobileNumber"
              name="pocMobileNumber"
              //placeholder="Add lessor's POC's mobile number"
              onChange={(e) => setPOCMobileNumber(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //! Lessor's POC's email */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">Lessor's POC's email*</Label>
            </strong>
            <Input
              type="text"
              value={pocEmail}
              id="pocEmail"
              name="pocEmail"
              //placeholder="Add lessor's POC's email"
              onChange={(e) => setPOCEmail(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/* //! Lessor's POC's aadhar number */}
        <Col sm="3    ">
          <FormGroup className="w-100">
            <strong>
              <Label className="form-label">
                Lessor's POC's aadhar number*
              </Label>
            </strong>
            <Input
              type="text"
              value={pocAadharNumber}
              id="pocAadharNumber"
              name="pocAadharNumber"
              //placeholder="Add lessor's POC's aadhar number"
              onChange={(e) => setPOCAadharNumber(e.target.value)}
            />
          </FormGroup>
        </Col>

        {/*//! Lessor's POC's aadhar card */}
        <Col sm="3">
          <strong>
            <Label className="form-label" for="llpRepresentativeAadharCard">
              Lessor's POC's aadhar card*
            </Label>
          </strong>
          <AttachmentComponent
            imageList={pocAadharCard}
            setImageList={setPOCAadharCard}
            editData={POCAadharCardImageDummyState}
            uploadType={uploadPOCAadharCardImageType}
          />
        </Col>
      </Row>
      <SaveDiscardBtnGroup />
    </form>
  );
};

export default DealBasics;
